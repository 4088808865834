import client from "@/api/client";

export function createQuest(data) {
	return client({
		url: "api/Quest/CreateQuest",
		method: "post",
		data,
	});
}

export function getQuestList(data) {
	return client({
		url: "api/Quest/GetQuestList",
		method: "post",
		data,
	});
}

export function getQuest(data) {
	return client({
		url: "api/Quest/GetQuest",
		method: "post",
		data,
	});
}

export function getQuestProfileList(data) {
	return client({
		url: "api/Quest/GetQuestProfileList",
		method: "post",
		data,
	});
}

export function getQuestProfile(data) {
	return client({
		url: "api/Quest/GetQuestProfile",
		method: "post",
		data,
	});
}

export function setQuestProfile(data) {
	return client({
		url: "api/Quest/SetQuestProfile",
		method: "post",
		data,
	});
}

export function setNewQuestProfile(data) {
	return client({
		url: "api/Quest/SetNewQuestProfile",
		method: "post",
		data,
	});
}

export function getQuestStatusList(data) {
	return client({
		url: "api/Quest/GetQuestStatusList",
		method: "post",
		data,
	});
}

export function getQuestStatus(data) {
	return client({
		url: "api/Quest/GetQuestStatus",
		method: "post",
		data,
	});
}
