<template>
	<div>
		<div
			class="mainbg"
			:style="{
				filter: 'blur(' + baseBlur + 'px) saturate(' + baseSaturate + ')',
				transform: 'scale(' + baseZoom + ', ' + baseZoom + ')',
				transition: baseTrans + 's',
				transitionTimingFunction: baseTiming,
			}"
		></div>
		<div class="maindim" :class="{ show: isDim }"></div>
	</div>
</template>
  
<script>
import store from "@/store";
export default {
	name: "MainBG",
	props: {},
	data() {
		return {
			baseZoom: 1.05,
			baseBlur: 0,
			baseSaturate: 1,
			baseTrans: 0.5,
			baseTiming: "",
		};
	},
	computed: {
		isBlur: {
			get() {
				return store.state.isBGBlur;
			},
		},
		isZoom: {
			get() {
				return store.state.isBGZoom;
			},
		},
		isDim: {
			get() {
				return store.state.isBGDim;
			},
		},
		isZoomQuake: {
			get() {
				return store.state.isBGZoomQuake;
			},
		},
		zoomQuakeTime: {
			get() {
				return store.state.bgZoomQuakeTime;
			},
		},
	},
	watch: {
		isBlur() {
			this.setBlur();
		},
		isZoom() {
			this.setZoom();
		},
		isZoomQuake() {
			this.setZoomQuake();
		},
	},
	methods: {
		setBlur() {
			if (this.isBlur) {
				this.baseBlur = 36;
				this.baseSaturate = 2.0;
				this.baseTrans = 0.45;
				this.baseTiming = "ease-in";
				setTimeout(() => {
					this.baseBlur = 24;
					this.baseSaturate = 1.5;
					this.baseTrans = 0.35;
					this.baseTiming = "ease-out";
				}, 450);
			} else {
				this.baseBlur = 0;
				this.baseSaturate = 1;
				this.baseTrans = 0.45;
				this.baseTiming = "ease-in";
			}
		},
		setZoom() {
			if (this.isZoom) {
				this.baseZoom = 1.35;
				this.baseTrans = 0.45;
				this.baseTiming = "ease-in";
				setTimeout(() => {
					this.baseZoom = 1.2;
					this.baseTrans = 0.35;
					this.baseTiming = "ease-out";
				}, 450);
			} else {
				this.baseZoom = 1.0;
				this.baseTrans = 0.45;
				this.baseTiming = "ease-in";
				setTimeout(() => {
					this.baseZoom = 1.05;
					this.baseTrans = 0.35;
					this.baseTiming = "ease-out";
				}, 450);
			}
		},
		setZoomQuake() {
			let time = this.zoomQuakeTime;
			if (!this.isZoomQuake) return;
			if (this.isZoom) {
				this.baseZoom = 1.35;
				this.baseTrans = time / 1000;
				this.baseTiming = "ease-in";
				setTimeout(() => {
					this.baseZoom = 1.2;
					this.baseTrans = 0.45;
					this.baseTiming = "ease-out";
				}, time);
			} else {
				this.baseZoom = 1.0;
				this.baseTrans = time / 1000;
				this.baseTiming = "ease-in";
				setTimeout(() => {
					this.baseZoom = 1.05;
					this.baseTrans = 0.45;
					this.baseTiming = "ease-out";
				}, time);
			}
		},
	},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.mainbg {
	background-image: url("../assets/mainBg.webp");
	background-repeat: no-repeat;
	background-size: cover;
	background-position-x: 100%;
	background-position-y: center;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	transition: 0.75s;
	transition-timing-function: ease-in-out;
	overflow: hidden;
	z-index: -3;
}

.mainbg.blur {
	filter: blur(24px) saturate(1.5);
	transition: 1s;
}

.mainbg.zoom {
	transform: scale(1.2, 1.2);
	transition: 1s;
	animation: zoomIn 1s ease-in-out;
}
.mainbg.nozoom {
	transform: scale(1.05, 1.05);
	transition: 0.75s;
	animation: zoomOut 0.75s ease-in-out;
}

@keyframes zoomIn {
	0% {
		transform: scale(1.05, 1.05);
	}
	50% {
		transform: scale(1.35, 1.35);
	}
	100% {
		transform: scale(1.2, 1.2);
	}
}
@keyframes zoomOut {
	0% {
		transform: scale(1.2, 1.2);
	}
	50% {
		transform: scale(1, 1);
	}
	100% {
		transform: scale(1.05, 1.05);
	}
}

.maindim {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	background-color: rgba(255, 255, 255, 0.45);
	z-index: -2;
	transition: 0.75s;
	transition-timing-function: ease-in-out;
}

.maindim.show {
	transition: 0.75s;
	opacity: 1;
}

@media (max-width: 1600px) {
	.mainbg {
		background-size: cover;
		background-position-x: center;
	}
}

@media (max-width: 1200px) {
	.mainbg {
		background-size: cover;
		background-position-x: center;
	}
}

@media (max-width: 992px) {
	.mainbg {
		background-size: cover;
		background-position-x: center;
	}
}

@media (max-width: 768px) {
	.mainbg {
		background-size: cover;
		background-position-x: center;
	}
}

@media (max-width: 576px) {
	.mainbg {
		background-size: cover;
		background-position-x: center;
	}
}
</style>