<template>
	<div class="windowcontent" :class="[status, padding]">
		<slot></slot>
	</div>
</template>
  
<script>
export default {
	name: "WindowContent",
	props: ["padding"],
	data() {
		return {
			status: "hide",
		};
	},
	computed: {},
	methods: {
		showContent() {
			this.status = "show";
		},
		hideContent() {
			this.status = "hide";
		},
	},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.windowcontent {
	position: absolute;
	padding: 16px;
	opacity: 0;
	border-radius: 16px;
	overflow: hidden;
	pointer-events: none;
}
.windowcontent.nopadding {
	padding: 1px;
}
.windowcontent.hide {
	opacity: 0;
	pointer-events: none;
}
.windowcontent.show {
	opacity: 1;
	pointer-events: all;
}
</style>