<template>
	<div
		class="msgwrapper"
		v-bind:class="[wrapperstatus]"
		:style="{
			opacity: rootOpacity,
			transition: rootTrans + 's',
			transitionTimingFunction: rootTiming,
		}"
	>
		<div
			class="msgwrapperbg"
			:style="{
				backdropFilter: 'blur(' + wrapperBlur + 'px)',
				transition: wrapperTrans + 's',
				transitionTimingFunction: wrapperTiming,
			}"
		></div>
		<div class="contentwrapper">
			<div class="contentwrapperbg" @click="onWrapperClick"></div>
			<div
				class="contentframe"
				:style="{
					opacity: msgOpacity,
					transform: 'scale(' + msgZoom + ', ' + msgZoom + ')',
					pointerEvents: msgPointEvent,
					transition: msgTrans + 's',
					transitionTimingFunction: msgTiming,
				}"
			>
				<div v-if="msgData != null" class="text-center">
					<div class="row">
						<div
							v-show="msgData.icon.type.toUpperCase() == 'OK'.toUpperCase()"
							class="col-12 text-success"
						>
							<i class="fas fa-check-circle fa-5x"></i>
						</div>
						<div
							v-show="msgData.icon.type.toUpperCase() == 'Error'.toUpperCase()"
							class="col-12 text-danger"
						>
							<i class="fas fa-times-circle fa-5x"></i>
						</div>
						<div
							v-show="msgData.icon.type.toUpperCase() == 'Alert'.toUpperCase()"
							class="col-12 text-warning"
						>
							<i class="fas fa-exclamation-circle fa-5x"></i>
						</div>
						<div
							v-show="msgData.icon.type.toUpperCase() == 'Quest'.toUpperCase()"
							class="col-12 text-secondary"
						>
							<i class="fas fa-question-circle fa-5x"></i>
						</div>
						<div
							v-if="msgData.icon.type.toUpperCase() == 'Custom'.toUpperCase()"
							class="col-12"
						>
							<i
								class="fa-5x"
								v-bind:class="[
									msgData.icon.custom.icon,
									msgData.icon.custom.color,
								]"
							></i>
						</div>
					</div>
					<div class="row mt-4">
						<div class="col-12">
							<h5>
								<strong>{{ msgData.msg.title }}</strong>
							</h5>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<div class="msgscroll" v-html="msgData.msg.message"></div>
						</div>
					</div>
					<hr v-if="msgData.btn != null && msgData.btn.length != 0" />
					<div class="row" v-if="msgData.btn.length == 1">
						<div class="col-12">
							<button
								class="btn w-100"
								v-bind:class="[msgData.btn[0].type]"
								v-on:click="runMethod(msgData, msgData.btn[0])"
							>
								{{ msgData.btn[0].text }}
							</button>
						</div>
					</div>
					<div class="row" v-if="msgData.btn.length == 2">
						<div class="col-6 pe-2">
							<button
								class="btn w-100"
								v-bind:class="[msgData.btn[1].type]"
								v-on:click="runMethod(msgData, msgData.btn[1])"
							>
								{{ msgData.btn[1].text }}
							</button>
						</div>
						<div class="col-6 ps-2">
							<button
								class="btn w-100"
								v-bind:class="[msgData.btn[0].type]"
								v-on:click="runMethod(msgData, msgData.btn[0])"
							>
								{{ msgData.btn[0].text }}
							</button>
						</div>
					</div>
					<div class="row" v-if="msgData.btn.length > 2">
						<div
							class="col-12"
							v-for="(btn, i) in msgData.btn"
							v-bind:key="i"
							v-bind:class="{
								'mt-4': i == msgData.btn.length - 1,
								'mt-2': i != msgData.btn.length - 1,
							}"
						>
							<button
								class="btn w-100"
								v-bind:class="[btn.type]"
								v-on:click="runMethod(msgData, btn)"
							>
								{{ btn.text }}
							</button>
						</div>
					</div>
				</div>
				<div class="contentframeborder"></div>
			</div>
		</div>
	</div>
</template>

<script>
import store from "../store";
import debounce from "lodash/debounce";
import { removeMsg } from "../utils/msgCtrl";
export default {
	name: "MsgBox",
	props: {},
	data() {
		return {
			rootOpacity: 0,
			rootTrans: 0.5,
			rootTiming: "",
			wrapperstatus: "",
			wrapperBlur: 0,
			wrapperTrans: 0.5,
			wrapperTiming: "",
			msgZoom: 1.2,
			msgPointEvent: "none",
			msgOpacity: 0,
			msgTrans: 0.5,
			msgTiming: "",
		};
	},
	computed: {
		msgData: {
			get() {
				if (this.msgList.length > 0) return this.msgList[0];
				else return null;
			},
		},
		msgUID: {
			get() {
				if (this.msgData == null) return null;
				return this.msgData.uid;
			},
		},
		msgList: {
			get() {
				return store.state.msgList;
			},
		},
		msgCount: {
			get() {
				return store.state.msgList.length;
			},
		},
	},
	watch: {
		msgCount(newValue) {
			var vm = this;

			var hideMsg = function () {
				vm.wrapperstatus = "close";
			};
			var _debounce = debounce(hideMsg, 100);
			if (newValue > 0) {
				vm.wrapperstatus = "open";
			} else {
				_debounce();
			}
		},
		wrapperstatus() {
			this.setDim();
		},
		msgUID(newValue) {
			if (newValue != null) this.setMsgIn();
		},
	},
	methods: {
		runMethod(msg, btn) {
			if (btn.method != null) btn.method();
			this.setMsgOut(msg);
		},
		setDim() {
			if (this.wrapperstatus == "open") {
				this.rootOpacity = 1;
				this.rootTrans = 0;

				this.wrapperBlur = 8;
				this.wrapperTrans = 0.45;
				this.wrapperTiming = "ease-in";
				setTimeout(() => {
					this.rootTrans = 1;
					this.wrapperBlur = 4;
					this.wrapperTrans = 0.35;
					this.wrapperTiming = "ease-out";
				}, 450);
			} else {
				this.wrapperBlur = 0;
				this.wrapperTrans = 0.25;
				this.wrapperTiming = "ease-in";
				setTimeout(() => {
					this.rootOpacity = 0;
					this.rootTrans = 1;
				}, 250);
			}
		},
		setMsgIn() {
			this.msgZoom = 0.95;
			this.msgOpacity = 1;
			this.msgPointEvent = "none";
			this.msgTrans = 0.35;
			this.msgTiming = "ease-in";
			setTimeout(() => {
				this.msgZoom = 1;
				this.msgOpacity = 1;
				this.msgPointEvent = "all";
				this.msgTrans = 0.25;
				this.msgTiming = "ease-out";
			}, 350);
		},
		setMsgOut(msg) {
			this.msgZoom = 0.9;
			this.msgOpacity = 1;
			this.msgPointEvent = "none";
			this.msgTrans = 0.15;
			this.msgTiming = "ease-in";

			this.wrapperBlur = 8;
			this.wrapperTrans = 0.15;

			setTimeout(() => {
				this.msgZoom = 1.2;
				this.msgOpacity = 0;
				this.msgPointEvent = "none";
				this.msgTrans = 0.25;
				this.msgTiming = "ease-out";

				if (this.msgCount == 1) {
					this.wrapperBlur = 0;
					this.wrapperTrans = 0.25;
					setTimeout(() => {
						removeMsg(msg);
					}, 250);
				} else {
					this.wrapperBlur = 4;
					this.wrapperTrans = 0.25;
					setTimeout(() => {
						removeMsg(msg);
					}, 250);
				}
			}, 200);
		},
		onWrapperClick() {
			this.msgZoom = 1.03;
			setTimeout(() => {
				this.msgZoom = 1.0;
			}, 250);
		},
	},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.msgwrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	pointer-events: none;
	transition: 0.5s;
	z-index: 999;
}

.msgwrapper.open {
	pointer-events: all;
}

.msgwrapper.close {
	pointer-events: none;
}

.msgwrapperbg {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	pointer-events: none;
	backdrop-filter: blur(4px);
	z-index: -1;
}

.contentwrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.5s;
	z-index: 999;
}

/* @media (max-width: 768px) {
	.contentwrapper {
		display: flex;
		justify-content: center;
		align-items: end;
		padding-bottom: 1.5rem;
	}
}

@media (max-width: 576px) {
	.contentwrapper {
		display: flex;
		justify-content: center;
		align-items: end;
		padding-bottom: 1.5rem;
	}
} */
.contentwrapperbg {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
}

.contentframe {
	position: relative;
	padding-top: 2rem;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-bottom: 1rem;
	min-width: 360px;
	max-width: 85vw;
	border-radius: 16px;
	background: rgba(255, 255, 255, 0.65);
	backdrop-filter: blur(24px);
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.375);
	pointer-events: all;
}

.contentframeborder {
	position: absolute;
	top: -1px;
	left: -1px;
	right: -1px;
	bottom: -1px;
	border-radius: 16px;
	border: 1px solid rgba(100, 100, 100, 0.25);
	pointer-events: none;
	z-index: 999;
}

.msgscroll {
	max-height: 320px;
	overflow-y: auto;
}
</style>
