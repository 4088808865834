<template>
	<div id="app">
		<MainBG />
		<router-view />
		<ProgressInfo />
		<SystemUI />
		<NotificationPanel />
		<MsgBox />
	</div>
</template>

<script>
// @ is an alias to /src
import MainBG from "@/components/MainBG.vue";
import MsgBox from "@/components/MsgBox.vue";
import ProgressInfo from "@/components/ProgressInfo.vue";
import NotificationPanel from "@/components/NotificationPanel.vue";
import SystemUI from "@/components/SystemUI.vue";
import store from "@/store";
import { getUserEntity } from "@/api/info";
import { showMsg } from "@/utils/msgCtrl";
import { setUserEntityClear } from "@/utils/userEntity";

export default {
	components: {
		MainBG,
		MsgBox,
		ProgressInfo,
		SystemUI,
		NotificationPanel,
	},
	data() {
		return {
			timerFunc: null,
			getUserEntityFlag: false,
		};
	},
	computed: {
		baseInfo: {
			get() {
				return store.state.baseInfo;
			},
		},
		userEntity: {
			get() {
				return store.state.userEntity;
			},
		},
	},
	methods: {
		getUserEntity() {
			var vm = this;
			if (store.state.isAppLogout) {
				vm.clearTimer();
			}
			if (
				vm.$route.name.toUpperCase() == "login".toUpperCase() ||
				vm.$route.name.toUpperCase() == "startup".toUpperCase() ||
				vm.$route.name.toUpperCase() == "active".toUpperCase()
			) {
				return;
			}
			if (vm.getUserEntityFlag) {
				return;
			}
			vm.getUserEntityFlag = true;
			getUserEntity()
				.then((res) => {
					if (res.Success) {
						if (store.state.isAppLogout) {
							vm.clearTimer();
						}
						let data = res.Data[0];
						store.dispatch("userEntityLoad", data);
						setTimeout(() => {
							vm.checkUserEntity();
						}, 100);
					} else {
						console.log("Check UserEntity Error : " + res.Message);
					}
					vm.getUserEntityFlag = false;
				})
				.catch((error) => {
					console.log("Check UserEntity Error : Connection Failed");
					console.log(error);
					vm.getUserEntityFlag = false;
				});
		},
		checkUserEntity() {
			var vm = this;
			if (store.state.isAppLogout) {
				vm.clearTimer();
			}
			if (!store.state.userEntity.IsLogin) {
				vm.clearTimer();
				showMsg({
					icon: {
						type: "Alert",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "已登出",
						message: "此 Session 已經登出，請重新載入",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {
								setUserEntityClear();
								window.location.reload();
							},
						},
					],
				});
			}
		},
		clearTimer() {
			return;
		},
		setViewPortHeight() {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		},
	},
	mounted() {
		var vm = this;
		vm.getUserEntity();
		vm.timerFunc = setInterval(() => {
			vm.getUserEntity();
		}, 3000);
		window.addEventListener("resize", () => {
			vm.setViewPortHeight();
		});
		vm.setViewPortHeight();
	},
};
</script>

<style lang="less">
#app {
	font-family: "Noto Sans TC", Roboto, sans-serif;
	color: #000000;
}
</style>
