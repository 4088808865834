var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"framewrapper",class:[_vm.frameStatus]},[_c('div',{ref:"frame",staticClass:"windowframe",style:({
				opacity: _vm.frameOpacity,
				transform: 'scale(' + _vm.frameZoom + ', ' + _vm.frameZoom + ')',
				pointerEvents: _vm.framePointEvent,
				transition: _vm.frameTrans + 's',
				transitionTimingFunction: _vm.frameTiming,
			})},[_c('div',{staticClass:"windowbg"}),_c('div',{staticClass:"windowbase",style:({
					width: _vm.baseWidth + 'px',
					height: _vm.baseHeight + 'px',
					transition: _vm.baseTrans + 's',
					transitionTimingFunction: _vm.baseTiming,
				})}),_c('div',{staticClass:"windowborder"})])]),_c('div',{ref:"content",staticClass:"contentwrapper",class:[_vm.contentStatus],style:({
			opacity: _vm.contentOpacity,
			transform: 'scale(' + _vm.contentZoom + ', ' + _vm.contentZoom + ')',
			pointerEvents: _vm.contentPointEvent,
			transition: _vm.contentTrans + 's',
			transitionTimingFunction: _vm.contentTiming,
		})},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }