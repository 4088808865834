<template>
	<div class="windowwrapper">
		<MenuList width="w2" :isSelected="isSelected">
			<MenuItem :clickEvent="showModalNewQuestProfile">
				<div class="row w-100">
					<div class="col-12 text-center">
						<i class="fa-solid fa-circle-plus"></i> 新增任務設定檔
					</div>
				</div>
			</MenuItem>
			<MenuItem
				v-for="item in targetDataList"
				:key="item.UID"
				:isSelected="checkIsSelectData(item)"
				:targetData="item"
				><div class="row w-100">
					<div class="col-12 text-truncate">
						<i class="fa-solid fa-code-pull-request me-1"></i> {{ item.Name }}
					</div>
				</div>
			</MenuItem>
		</MenuList>
		<ContentPage
			v-if="targetData == null"
			otherclass="hidewhenmobile"
			width="w2"
		>
			<div class="emptywrapper">
				<div class="row">
					<div class="col-12 text-center">
						<i class="fa-solid fa-code-pull-request fa-10x"></i>
					</div>
					<div class="col-12 mt-3 text-center">
						<h4><strong>請選擇任務設定檔</strong></h4>
					</div>
					<div class="col-12 text-center">
						請從選單選擇要管理的任務設定檔資料
					</div>
				</div>
			</div>
		</ContentPage>
		<ContentPage
			v-if="targetData != null"
			:otherclass="contentStatus"
			width="w2"
		>
			<ContentSecion>
				<div class="row">
					<div class="col-12">基本資料</div>
				</div>
				<hr class="mt-2 mb-2" />
				<div class="row">
					<div class="col-12 d-none d-md-block text-end">
						<small class="text-black-50"
							>唯一識別號碼 - {{ targetData.UID }}</small
						>
					</div>
					<div class="col-12 d-block d-md-none">
						<small>唯一識別號碼</small><br />
						<input
							type="text"
							class="form-control"
							disabled
							v-model="targetData.UID"
						/>
					</div>
					<div class="col-6 col-xl-3">
						<small>名稱</small><br />
						<input type="text" class="form-control" v-model="targetData.Name" />
					</div>
					<div class="d-none d-xl-block col-xl-9"></div>
					<div class="col-6 col-xl-3">
						<small>程式識別 Key</small><br />
						<input type="text" class="form-control" v-model="targetData.Key" />
					</div>
					<div class="d-none d-xl-block col-xl-9"></div>
					<div class="col-6 col-xl-3">
						<small>權限</small><br />
						<div class="dropdown">
							<a
								class="btn btn-outline-primary w-50 dropdown-toggle"
								href="#"
								role="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<span v-if="selectPermission == null">未指定</span>
								<span v-if="selectPermission != null">{{
									selectPermission.Name
								}}</span>
							</a>

							<div class="dropdown-menu">
								<div
									v-for="item in permissionList"
									:key="item.UID"
									@click="selectPermission = item"
								>
									<a class="dropdown-item" href="#">{{ item.Name }}</a>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 mt-3 text-black-50">
						<small>建立時間：{{ targetData.CreateTime | moment }}</small
						><br />
						<small>更新時間：{{ targetData.UpdateTime | moment }}</small>
					</div>
				</div>
			</ContentSecion>
			<ContentSecion>
				<div class="row">
					<div class="col-12">Worker 列表</div>
				</div>
				<hr class="mt-2 mb-2" />
				<div class="row">
					<div class="col-12">
						<ContentListItem
							v-for="item in targetData.WorkerList"
							:key="item.ID"
							><div class="row w-100">
								<div class="col-12 d-block d-md-none">
									<div class="row">
										<div class="col-3 text-truncate align-self-center">
											ID：{{ item.ID }}
										</div>
										<div class="col-8 text-truncate align-self-center">
											Wait：{{
												item.WaitID == null || item.WaitID == ""
													? "No Wait"
													: item.WaitID
											}}
										</div>
										<div class="col-8 text-truncate align-self-center">
											名稱：{{ item.WorkerName }}
										</div>
										<div
											class="col-4 text-center text-truncate align-self-center"
										>
											佔比：{{ item.Proportion }}
										</div>
									</div>
									<hr class="mt-2 mb-2" />
									<div class="row">
										<div class="col-12 text-end">
											<button
												class="btn btn-success me-1"
												@click="showModalNewQuestProfileWorker(item)"
											>
												<i class="fa-solid fa-pen-to-square"></i> 編輯
											</button>
											<button
												class="btn btn-danger"
												@click="setRemoveQuestProfileWorker(item)"
											>
												<i class="fa-solid fa-trash"></i> 移除
											</button>
										</div>
									</div>
								</div>
								<div class="col-12 d-none d-md-block">
									<div class="row">
										<div
											class="
												col-3 col-md-1 col-lg-1
												border-end
												text-truncate
												align-self-center
											"
										>
											{{ item.ID }}
										</div>
										<div
											class="
												col-8 col-md-3 col-lg-3
												border-end
												text-truncate
												align-self-center
											"
										>
											{{
												item.WaitID == null || item.WaitID == ""
													? "No Wait"
													: item.WaitID
											}}
										</div>
										<div
											class="
												col-8 col-md-4 col-lg-4
												border-end
												text-truncate
												align-self-center
											"
										>
											{{ item.WorkerName }}
										</div>
										<div
											class="
												col-4 col-md-1 col-lg-1
												border-end
												text-center text-truncate
												align-self-center
											"
										>
											{{ item.Proportion }}
										</div>
										<div class="col-12 col-md-3 col-lg-3 text-end pe-0">
											<button
												class="btn btn-success me-1"
												@click="showModalNewQuestProfileWorker(item)"
											>
												<i class="fa-solid fa-pen-to-square"></i> 編輯
											</button>
											<button
												class="btn btn-danger"
												@click="setRemoveQuestProfileWorker(item)"
											>
												<i class="fa-solid fa-trash"></i> 移除
											</button>
										</div>
									</div>
								</div>
							</div></ContentListItem
						>
					</div>
					<div class="col-12 mt-3 text-end">
						<button
							class="btn btn-primary"
							v-on:click="showModalNewQuestProfileWorker(null)"
						>
							<i class="fa-solid fa-circle-plus"></i> 新增 Worker
						</button>
					</div>
				</div>
			</ContentSecion>
			<ContentSecion>
				<div class="row">
					<div class="col-12 text-center">
						<button class="btn btn-lg btn-danger" @click="getQuestProfile">
							<i class="fa-solid fa-arrow-rotate-right"></i> 重讀
						</button>
						<button
							class="btn btn-lg btn-primary ms-2"
							@click="setQuestProfile"
						>
							<i class="fa-solid fa-floppy-disk"></i> 更新任務設定檔
						</button>
					</div>
				</div>
			</ContentSecion>
		</ContentPage>
		<BottomBar>
			<div class="row">
				<div class="col-12">
					<button class="btn btn-light" @click="setBackBtn">
						<i class="fa-solid fa-arrow-left"></i> 返回
					</button>
					<button
						class="d-none d-sm-inline-block btn btn-danger ms-2"
						@click="getQuestProfileList"
					>
						<i class="fa-solid fa-arrows-rotate"></i> 重讀列表
					</button>
				</div>
			</div>
		</BottomBar>
		<ModalBox ref="modalNewQuestProfile" width="w3">
			<template v-slot:content>
				<div class="row">
					<div class="col-12 col-xl-12">
						<small>名稱</small><br />
						<input type="text" class="form-control" v-model="newData.Name" />
					</div>
					<div class="d-none d-lg-block col-xl-4"></div>
					<div class="col-12 col-xl-12">
						<small>程式識別 Key</small><br />
						<input type="text" class="form-control" v-model="newData.Key" />
					</div>
					<div class="d-none d-lg-block col-xl-4"></div>
				</div>
			</template>
			<template v-slot:bar>
				<div class="row">
					<div class="col-4">
						<button class="btn btn-light" @click="hideModalNewQuestProfile">
							取消
						</button>
					</div>
					<div class="col-8 text-end">
						<button class="btn btn-primary ms-1" @click="setNewQuestProfile">
							新增任務設定檔
						</button>
					</div>
				</div>
			</template>
		</ModalBox>
		<ModalBox ref="modalNewQuestProfileWorker" width="w4">
			<template v-slot:content>
				<div class="row">
					<div class="col-6 col-xl-2">
						<small>ID</small><br />
						<input type="number" class="form-control" v-model="newWorker.ID" />
					</div>
					<div class="col-12 col-xl-4">
						<small>Worker 名稱</small><br />
						<input
							type="text"
							class="form-control"
							v-model="newWorker.WorkerName"
						/>
					</div>
					<div class="col-3 col-xl-2">
						<small>佔比</small><br />
						<input
							type="number"
							class="form-control"
							v-model="newWorker.Proportion"
						/>
					</div>
					<div class="col-12 col-xl-12">
						<small>等待 ID</small><br />
						<input
							type="text"
							class="form-control"
							v-model="newWorker.WaitID"
						/>
					</div>
				</div>
			</template>
			<template v-slot:bar>
				<div class="row">
					<div class="col-6">
						<button
							class="btn btn-light"
							@click="hideModalNewQuestProfileWorker"
						>
							取消
						</button>
					</div>
					<div class="col-6 text-end">
						<button
							class="btn btn-primary ms-1"
							@click="setNewQuestProfileWorker"
						>
							{{ workerIndex == -1 ? "新增 Worker" : "儲存 Worker" }}
						</button>
					</div>
				</div>
			</template>
		</ModalBox>
		<ModalBox ref="modalLoading" width="w3" padding="borderpadding">
			<template v-slot:content>
				<div class="row">
					<div class="col-12 text-center">正在處理，請稍後...</div>
					<div class="col-12">
						<LoadSpin></LoadSpin>
					</div>
				</div>
			</template>
		</ModalBox>
	</div>
</template>

<script>
import BottomBar from "@/components/WindowUI/BottomBar.vue";
import MenuList from "@/components/WindowUI/MenuList.vue";
import MenuItem from "@/components/WindowUI/MenuItem.vue";
import ContentPage from "@/components/WindowUI/ContentPage.vue";
import ContentSecion from "@/components/WindowUI/ContentSection.vue";
import ModalBox from "@/components/WindowUI/ModalBox.vue";
import LoadSpin from "@/components/LoadSpin.vue";
import {
	getQuestProfileList,
	getQuestProfile,
	setQuestProfile,
	setNewQuestProfile,
} from "@/api/quest";
import { getUserPermissionList } from "@/api/user";
import { showMsg } from "@/utils/msgCtrl";
import moment from "moment";
import ContentListItem from "@/components/WindowUI/ContentListItem.vue";
export default {
	name: "QuestProfile",
	props: {},
	data() {
		return {
			windowName: "systemmanager-questprofile",
			targetDataList: [],
			selectItem: null,
			targetData: null,
			contentStatus: "",
			isSelected: false,
			loadingCount: 0,
			newData: {},
			newWorker: {},
			workerIndex: -1,
			afterSelectData: null,
			permissionList: [],
			selectPermissionTemp: null,
		};
	},
	components: {
		BottomBar,
		MenuList,
		MenuItem,
		ContentPage,
		ContentSecion,
		ModalBox,
		LoadSpin,
		ContentListItem,
	},
	computed: {
		selectPermission: {
			get() {
				var vm = this;
				let result = null;
				if (vm.targetData == null) return result;
				if (
					vm.targetData.UserGroupUID == "00000000-0000-0000-0000-000000000000"
				)
					return result;

				vm.permissionList.forEach((item) => {
					if (item.UID == vm.targetData.UserGroupUID) result = item;
				});

				if (result == null)
					vm.targetData.UserGroupUID = "00000000-0000-0000-0000-000000000000";
				return result;
			},
			set(newValue) {
				var vm = this;
				if (vm.targetData == null) return;
				vm.targetData.UserGroupUID = newValue.UID;
			},
		},
	},
	filters: {
		momentDate: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日");
		},
		moment: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日 HH:mm:ss");
		},
		commaFormat: function (value) {
			return value
				.toString()
				.replace(
					/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/,
					function (all, pre, groupOf3Digital) {
						return pre + groupOf3Digital.replace(/\d{3}/g, ",$&");
					}
				);
		},
	},
	watch: {
		selectItem(newValue) {
			var vm = this;
			if (newValue == null) {
				if (window.innerWidth < 576) {
					vm.isSelected = false;
					vm.contentStatus = "mobilehide";
					setTimeout(() => {
						vm.targetData = null;
					}, 650);
				} else {
					vm.targetData = null;
				}
				return;
			} else {
				vm.getQuestProfile();
			}
		},
		loadingCount(newValue) {
			var vm = this;
			if (newValue > 0) {
				vm.showModalLoading();
			} else {
				vm.hideModalLoading();
			}
		},
	},
	methods: {
		getQuestProfileList() {
			var vm = this;
			vm.loadingCount++;
			vm.selectItem = null;
			vm.targetData = null;
			vm.targetDataList = [];
			setTimeout(() => {
				getQuestProfileList()
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.targetDataList = data;
							vm.setItemToNewData();
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.setWindowToMenu();
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得任務設定檔列表時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.setWindowToMenu();
									},
								},
							],
						});
					});
			}, 750);
		},
		getUserPermissionList() {
			var vm = this;
			vm.loadingCount++;
			vm.permissionList = [];
			setTimeout(() => {
				getUserPermissionList()
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.permissionList = data;
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.setWindowToMenu();
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得使用者權限列表時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.setWindowToMenu();
									},
								},
							],
						});
					});
			}, 750);
		},
		getQuestProfile() {
			var vm = this;
			vm.loadingCount++;
			vm.targetData = null;
			setTimeout(() => {
				getQuestProfile(vm.selectItem)
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.targetData = data;
							vm.isSelected = true;
							vm.contentStatus = "mobileshow";
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.selectItem = null;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得任務設定檔資料時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.selectItem = null;
									},
								},
							],
						});
					});
			}, 750);
		},
		setQuestProfile() {
			var vm = this;
			if (vm.targetData == null) {
				showMsg({
					icon: {
						type: "Error",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "更新任務設定檔",
						message:
							"無法進行更新任務設定檔，必須先選擇一個任務設定檔之後才能繼續",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {},
						},
					],
				});
				return;
			}
			vm.loadingCount++;
			setTimeout(() => {
				setQuestProfile(vm.targetData)
					.then((res) => {
						if (res.Success) {
							vm.getQuestProfile();
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "更新任務設定檔",
								message:
									"連線到伺服器更新任務設定檔時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
									},
								},
							],
						});
					});
			}, 750);
		},
		setNewQuestProfile() {
			var vm = this;
			if (
				vm.newData.Name == null ||
				vm.newData.Name == "" ||
				vm.newData.Key == null ||
				vm.newData.Key == ""
			) {
				showMsg({
					icon: {
						type: "Error",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "新增任務設定檔",
						message: "無法新增任務設定檔，名稱與程式識別Key為必填欄位",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {},
						},
					],
				});
				return;
			}
			vm.loadingCount++;
			setTimeout(() => {
				setNewQuestProfile(vm.newData)
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.afterSelectData = data;
							vm.getQuestProfileList();
							vm.hideModalNewQuestProfile();
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "新增任務設定檔",
								message:
									"連線到伺服器新增任務設定檔時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
									},
								},
							],
						});
					});
			}, 750);
		},
		setItemToNewData() {
			var vm = this;
			if (vm.afterSelectData == null) return;
			let result = null;
			vm.targetDataList.forEach((item) => {
				if (item.UID == vm.afterSelectData.UID) result = item;
			});
			if (result != null) vm.setItemClick(result);
		},
		setItemClick(input) {
			var vm = this;
			if (vm.checkIsSelectData(input)) return;
			vm.selectItem = JSON.parse(JSON.stringify(input));
		},
		setItemClear() {
			var vm = this;
			if (window.innerWidth < 576) {
				if (vm.selectItem != null) {
					vm.selectItem = null;
				} else {
					vm.setWindowToMenu();
				}
			} else {
				vm.selectItem = null;
			}
		},
		checkIsSelectData(input) {
			var vm = this;
			let result = false;
			if (vm.selectItem != null) {
				if (vm.selectItem.UID == input.UID) result = true;
			}
			return result;
		},
		setBackBtn() {
			var vm = this;
			if (window.innerWidth < 576) {
				if (vm.selectItem != null) {
					vm.selectItem = null;
				} else {
					vm.setWindowToMenu();
				}
			} else {
				vm.setWindowToMenu();
			}
		},
		setWindowToMenu() {
			var vm = this;
			vm.$parent.$parent.$parent.setWindowToMenu(vm);
			setTimeout(() => {
				vm.selectItem = null;
				vm.targetDataList = [];
			}, 500);
		},
		showModalLoading() {
			var vm = this;
			vm.$refs.modalLoading.showModal();
		},
		hideModalLoading() {
			var vm = this;
			vm.$refs.modalLoading.hideModal();
		},
		showModalNewQuestProfile() {
			var vm = this;
			vm.newData = {
				Name: "",
				Key: "",
				UserGroupUID: "00000000-0000-0000-0000-000000000000",
				UserUID: "00000000-0000-0000-0000-000000000000",
			};
			vm.$refs.modalNewQuestProfile.showModal();
		},
		hideModalNewQuestProfile() {
			var vm = this;
			vm.$refs.modalNewQuestProfile.hideModal();
		},
		showModalNewQuestProfileWorker(input) {
			var vm = this;
			let ID = 1;
			vm.targetData.WorkerList.forEach((item) => {
				if (item.ID >= ID) ID = item.ID + 1;
			});
			if (input == null) {
				vm.newWorker = {
					ID: ID,
					WaitID: "",
					WorkerName: "",
					Proportion: 0,
				};
				vm.workerIndex = -1;
			} else {
				vm.newWorker = JSON.parse(JSON.stringify(input));
				vm.workerIndex = vm.targetData.WorkerList.indexOf(input);
			}
			vm.$refs.modalNewQuestProfileWorker.showModal();
		},
		hideModalNewQuestProfileWorker() {
			var vm = this;
			vm.$refs.modalNewQuestProfileWorker.hideModal();
		},
		setNewQuestProfileWorker() {
			var vm = this;
			if (
				vm.newWorker.ID == null ||
				vm.newWorker.WorkerName == null ||
				vm.newWorker.WorkerName == "" ||
				vm.newWorker.Proportion == 0
			) {
				showMsg({
					icon: {
						type: "Error",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "新增 Worker",
						message: "無法新增 Worker，所有欄位都是必填欄位",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {},
						},
					],
				});
				return;
			}

			if (vm.workerIndex == -1) {
				let isIDMatch = false;
				vm.targetData.WorkerList.forEach((item) => {
					if (item.ID == vm.newWorker.ID) {
						isIDMatch = true;
					}
				});
				if (isIDMatch) {
					showMsg({
						icon: {
							type: "Error",
							custom: {
								icon: "",
								color: "",
							},
						},
						msg: {
							title: "新增 Worker",
							message: "無法新增 Worker，此 Worker ID 已經存在",
						},
						btn: [
							{
								text: "確定",
								type: "btn-primary",
								method: function () {},
							},
						],
					});
					return;
				}
				vm.newWorker.HeaderUID = vm.targetData.UID;
				vm.targetData.WorkerList.push(JSON.parse(JSON.stringify(vm.newWorker)));
			} else {
				let isIDMatch = false;
				vm.targetData.WorkerList.forEach((item, i) => {
					if (item.ID == vm.newWorker.ID && i != vm.workerIndex) {
						isIDMatch = true;
					}
				});
				if (isIDMatch) {
					showMsg({
						icon: {
							type: "Error",
							custom: {
								icon: "",
								color: "",
							},
						},
						msg: {
							title: "新增 Worker",
							message: "無法新增 Worker，此 Worker ID 已經存在",
						},
						btn: [
							{
								text: "確定",
								type: "btn-primary",
								method: function () {},
							},
						],
					});
					return;
				}
				vm.targetData.WorkerList.splice(vm.workerIndex, 1, vm.newWorker);
			}
			vm.hideModalNewQuestProfileWorker();
		},
		setRemoveQuestProfileWorker(input) {
			var vm = this;
			if (vm.targetData == null) {
				showMsg({
					icon: {
						type: "Alert",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "移除 Worker",
						message: "必須選擇要設定的任務設定檔之後才能移除權限",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {},
						},
					],
				});
			}
			showMsg({
				icon: {
					type: "Custom",
					custom: {
						icon: "fa-solid fa-trash",
						color: "text-danger",
					},
				},
				msg: {
					title: "移除 Worker",
					message:
						"確定要從任務設定檔 " +
						vm.targetData.Name +
						" 的 Worker 列表中移除 Worker " +
						input.WorkerName +
						" 嗎？",
				},
				btn: [
					{
						text: "確定移除",
						type: "btn-danger",
						method: function () {
							vm.targetData.WorkerList.splice(
								vm.targetData.WorkerList.indexOf(input),
								1
							);
						},
					},
					{
						text: "取消",
						type: "btn-light",
						method: function () {},
					},
				],
			});
		},
		setDataInit() {
			var vm = this;
			setTimeout(() => {
				vm.getQuestProfileList();
				vm.getUserPermissionList();
			}, 750);
		},
	},
	mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
