<template>
	<div>
		<div class="topuiwrapper">
			<div class="container">
				<div class="topuibar">
					<div class="row">
						<div class="col-12 text-end p-0">
							<DropContentButton
								v-if="
									userEntity != null &&
									userEntity.IsLogin &&
									userEntity.IsActive
								"
								ref="userbotton"
								mainalign="top"
								buttonalign="topright"
								contentalign="topright"
							>
								<template v-slot:btn>
									{{ userEntity.DisplayName }} ({{ userEntity.UserName }})
								</template>
								<template v-slot:content>
									<div class="userContent">
										<div class="row">
											<div class="col-3 p-0 text-center">
												<img src="../assets/logo.png" width="64px" />
											</div>
											<div class="col-9">
												<div class="row">
													<div class="col-12">
														<h4>{{ userEntity.DisplayName }}</h4>
													</div>
													<div class="col-12">
														帳號: {{ userEntity.UserName }}
													</div>
													<div class="col-12">
														Email: {{ userEntity.Email }}
													</div>
													<div class="col-12">權限: {{ permissionName }}</div>
												</div>
											</div>
										</div>
										<hr class="mt-1 mb-2" />
										<div class="row">
											<div class="col-6 text-start">
												<button class="btn btn-dark btn-circle">
													<i class="fa-solid fa-gear"></i>
												</button>
											</div>
											<div class="col-6 text-end">
												<button class="btn btn-danger" @click="logoutUser">
													<i class="fa-solid fa-right-from-bracket"></i>
													登出帳號
												</button>
											</div>
										</div>
									</div>
								</template></DropContentButton
							>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
  
<script>
import store from "@/store";
import DropContentButton from "@/components/DropContentButton.vue";
import { showMsg } from "@/utils/msgCtrl";
import { logoutUser } from "@/api/user";
import { setUserTokenClear } from "@/utils/userToken";
import { setUserEntityClear } from "@/utils/userEntity";
export default {
	name: "SystemUI",
	props: {},
	data() {
		return {
			userBtnClass: "",
		};
	},
	components: { DropContentButton },
	computed: {
		userEntity: {
			get() {
				return store.state.userEntity;
			},
		},
		permissionName: {
			get() {
				var vm = this;
				let result = "";
				vm.userEntity.Permissions.forEach((item) => {
					if (result == "") result = item.Name;
					else result += "、" + item.Name;
				});
				return result;
			},
		},
	},
	watch: {},
	methods: {
		logoutUser() {
			var vm = this;
			showMsg({
				icon: {
					type: "custom",
					custom: {
						icon: "fa-solid fa-right-from-bracket",
						color: "text-danger",
					},
				},
				msg: {
					title: "登出帳號",
					message: "確定要登出帳號 " + vm.userEntity.DisplayName + " 嗎？",
				},
				btn: [
					{
						text: "登出帳號",
						type: "btn-danger",
						method: function () {
							vm.doLogoutUser();
						},
					},
					{
						text: "返回",
						type: "btn-light",
						method: function () {},
					},
				],
			});
		},
		doLogoutUser() {
			store.state.isAppLogout = true;
			showMsg({
				icon: {
					type: "custom",
					custom: {
						icon: "fa-solid fa-right-from-bracket",
						color: "text-danger",
					},
				},
				msg: {
					title: "正在登出",
					message: "正在登出帳號，請稍後...",
				},
				btn: [],
			});
			logoutUser()
				.then((res) => {
					if (res.Success) {
						store.dispatch("userEntityLoad", null);
						setUserTokenClear();
						setUserEntityClear();
						setTimeout(() => {
							window.location.reload();
						}, 2500);
					} else {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: res.Title,
								message: res.Message,
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										store.state.isAppLogout = false;
									},
								},
							],
						});
					}
				})
				.catch((error) => {
					showMsg({
						icon: {
							type: "Error",
							custom: {
								icon: "",
								color: "",
							},
						},
						msg: {
							title: "取得資料失敗",
							message:
								"連線到伺服器進行登出使用者時發生錯誤:" +
								JSON.stringify(error, null, "\t"),
						},
						btn: [
							{
								text: "確定",
								type: "btn-primary",
								method: function () {
									store.state.isAppLogout = false;
								},
							},
						],
					});
				});
		},
	},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.topuiwrapper {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 980;
	pointer-events: none;
}
.topuibar {
	padding: 4px;
}
.userContent {
	width: 320px;
}
</style>