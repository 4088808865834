<template>
	<div class="contentwrapper" :class="[width, otherclass]">
		<div
			class="topbarwrapper"
			v-if="topbar"
			:style="{
				top: topBarPosition + 'px',
			}"
		>
			<slot name="topbar"></slot>
		</div>
		<div
			class="list"
			:style="{
				paddingTop: listPaddingTop + 'px',
				paddingBottom: listPaddingBottom + 'px',
			}"
		>
			<div class="listcontent">
				<slot></slot>
			</div>
		</div>
		<div
			class="bottombarwrapper"
			v-if="bottombar"
			:style="{
				bottom: bottomBarPosition + 'px',
			}"
		>
			<slot name="bottombar"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "ContentPage",
	props: ["width", "otherclass", "topbar", "bottombar", "windowtopbar"],
	data() {
		return {
			autoHide: "",
			listPaddingTop: 16,
			listPaddingBottom: 54,
			topBarPosition: 0,
			bottomBarPosition: 45,
		};
	},
	watch: {
		topbar() {
			var vm = this;
			vm.setListPadding();
		},
		bottombar() {
			var vm = this;
			vm.setListPadding();
		},
		windowtopbar() {
			var vm = this;
			vm.setListPadding();
		},
	},
	methods: {
		setListPadding() {
			var vm = this;
			let top = 16;
			if (vm.windowtopbar) {
				top = 46;
			}
			if (vm.topbar) {
				top += 44;
			}
			vm.listPaddingTop = top;

			let bottom = 46;
			if (vm.bottombar) {
				bottom += 44;
			}
			vm.listPaddingBottom = bottom;

			if (vm.windowtopbar) vm.topBarPosition = 45;
		},
	},
	created() {
		var vm = this;
		vm.setListPadding();
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.contentwrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 30%;
	transition: 0.35s;
	background: rgba(255, 255, 255, 0.25);
	border-left: 1px solid rgba(100, 100, 100, 0.25);
}
.contentwrapper.w0 {
	left: 0;
	border-left: none;
}
.contentwrapper.w2 {
	left: 20%;
}
.contentwrapper.w3 {
	left: 30%;
}
.contentwrapper.w4 {
	left: 40%;
}
.contentwrapper.w5 {
	left: 50%;
}
.contentwrapper.w6 {
	left: 60%;
}
@media (max-width: 575px) {
	.contentwrapper {
		left: 0 !important;
		background: rgba(255, 255, 255, 0);
		border-left: none;
		z-index: 10;
	}
	.contentwrapper.hidewhenmobile {
		display: none;
	}
	.contentwrapper.mobileshow {
		pointer-events: all;
		opacity: 1;
		animation: contentwrapper-mobileshow 0.65s ease-in-out;
	}
	.contentwrapper.mobilehide {
		opacity: 0;
		pointer-events: none;
		animation: contentwrapper-mobilehide 0.65s ease-in-out;
	}
	@keyframes contentwrapper-mobileshow {
		0% {
			opacity: 0;
			transform: scale(1, 1) translate(575px, 0px);
		}
		100% {
			opacity: 1;
			transform: scale(1, 1) translate(0px, 0px);
		}
	}
	@keyframes contentwrapper-mobilehide {
		0% {
			opacity: 1;
			transform: scale(1, 1) translate(0px, 0px);
		}
		100% {
			opacity: 0;
			transform: scale(1, 1) translate(575px, 0px);
		}
	}
}

@media (max-width: 767px) {
	.contentwrapper.w2 {
		left: 35%;
	}
}
@media (max-width: 991px) {
	.contentwrapper.w2 {
		left: 30%;
	}
	.contentwrapper.w3 {
		left: 35%;
	}
}
.topbarwrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 6px 12px;
	border-bottom: 1px solid rgba(100, 100, 100, 0.25);
	background: rgba(255, 255, 255, 0.25);
	backdrop-filter: blur(12px) saturate(1.5);
	z-index: 25;
}
.bottombarwrapper {
	position: absolute;
	bottom: 46px;
	left: 0;
	right: 0;
	padding: 6px 12px;
	border-top: 1px solid rgba(100, 100, 100, 0.25);
	background: rgba(255, 255, 255, 0.25);
	backdrop-filter: blur(12px) saturate(1.5);
	z-index: 25;
}
@media (max-width: 767px) {
	.topbarwrapper {
		padding: 8px 16px;
	}
}
.list {
	height: 100%;
	overflow: auto;
	padding-top: 1rem;
	padding-bottom: 54px;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}
.list::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}
.listcontent {
	position: relative;
	min-height: 100%;
	padding-bottom: 1rem;
}
</style>
