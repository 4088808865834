<template>
	<div
		class="progressbarwrapper"
		:style="{
			height: height + 'px',
		}"
	>
		<div
			class="progressbar"
			:style="{
				height: height / 2 + 'px',
			}"
		>
			<div class="progressbarborder"></div>
			<div class="progressbarwait" v-if="useWaitBar">
				<div class="progressbarwaitbg"></div>
			</div>
			<div
				class="progressbarshow"
				v-if="!useWaitBar"
				:style="{
					width: value + '%',
				}"
			>
				<div class="progressbarshowbg"></div>
			</div>
		</div>
	</div>
</template>
  
<script>
export default {
	name: "ProgressBar",
	props: ["iswait", "value", "height"],
	data() {
		return {
			useWaitBar: false,
		};
	},
	computed: {},
	watch: {
		iswait() {
			var vm = this;
			vm.setWaitBar();
		},
	},
	methods: {
		setWaitBar() {
			var vm = this;
			if (!vm.iswait) vm.useWaitBar = true;
			else vm.useWaitBar = false;
		},
	},
	created() {
		var vm = this;
		vm.setWaitBar();
	},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.progressbarwrapper {
	position: relative;
	height: 24px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
}
.progressbar {
	position: relative;
	height: 12px;
	width: 100%;
	opacity: 1;
	border-radius: 18px;
	background: rgba(255, 255, 255, 0.25);
	transition: 0.5s;
}
.progressbarborder {
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
	border: 1px solid rgba(100, 100, 100, 0.35);
	border-radius: 12px;
}
.progressbarwait {
	position: relative;
	height: 100%;
	width: 100%;
	border-radius: 12px;
	overflow: hidden;
}
.progressbarwaitbg {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 50%;
	filter: blur(8px);
	background: rgb(255, 255, 255);
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(0, 0, 255, 0.5) 40%,
		rgba(0, 0, 255, 1) 80%,
		rgba(0, 0, 255, 1) 90%,
		rgba(255, 255, 255, 0) 100%
	);
	animation: progressbarwaitbg-spin 1.5s ease-in-out infinite;
}
@keyframes progressbarwaitbg-spin {
	0% {
		transform: translateX(-200%);
	}
	100% {
		transform: translateX(200%);
	}
}

.progressbarshow {
	position: relative;
	height: 100%;
	width: 100%;
	border-radius: 12px;
	background: rgba(0, 0, 255, 0.5);
	border-right: 1px solid rgba(100, 100, 100, 0.35);
	overflow: hidden;
	transition: 0.35s;
}
.progressbarshowbg {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 50%;
	filter: blur(8px);
	background: rgb(255, 255, 255);
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.5) 40%,
		rgba(255, 255, 255, 1) 80%,
		rgba(255, 255, 255, 1) 90%,
		rgba(255, 255, 255, 0) 100%
	);
	animation: progressbarshowbg-spin 1s ease-in-out infinite;
}
@keyframes progressbarshowbg-spin {
	0% {
		transform: translateX(200%);
	}
	100% {
		transform: translateX(-200%);
	}
}
</style>