<template>
	<div class="debug">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<button class="btn btn-sm btn-primary" @click="windowFadeIn">
						Window Fade In
					</button>
					<button class="btn btn-sm btn-primary" @click="windowFadeOut">
						Window Fade Out
					</button>
					<button class="btn btn-sm btn-primary" @click="progressInfoIn">
						Progress In
					</button>
					<button class="btn btn-sm btn-primary" @click="progressInfoOut">
						Progress Out
					</button>
					<button class="btn btn-sm btn-primary" @click="fadeInMenu">
						Menu Fade In
					</button>
				</div>
				<div class="col-12 mt-2">
					<button class="btn btn-sm btn-success" @click="showMsg1">
						Msg Test 1
					</button>

					<button class="btn btn-sm btn-primary" @click="showWindow">
						Show Window
					</button>
					<button class="btn btn-sm btn-primary" @click="hideWindow">
						Hidden Window
					</button>
				</div>
			</div>
		</div>
		<WindowFrame ref="windowframe">
			<WindowContent ref="windowcontent1"
				>------------------- 測試視窗內容 -------------------<br />-------------------
				測試視窗內容 -------------------<br />------------------- 測試視窗內容
				-------------------<br />------------------- 測試視窗內容
				-------------------<br />------------------- 測試視窗內容
				-------------------<br />------------------- 測試視窗內容
				-------------------<br />------------------- 測試視窗內容
				-------------------<br
			/></WindowContent>
		</WindowFrame>
		<MenuToWindow ref="menutowindow" />
		<SystemManagerMenuVue ref="menu"></SystemManagerMenuVue>
		<WindowFrame ref="winframe_test1">
			<WindowContent ref="wincontent_test1"
				>-------------------------------------- 測試視窗內容
				--------------------------------------<br />--------------------------------------
				測試視窗內容 --------------------------------------<br />--------------------------------------
				測試視窗內容 --------------------------------------<br />--------------------------------------
				測試視窗內容 --------------------------------------<br />--------------------------------------
				測試視窗內容 --------------------------------------<br />--------------------------------------
				測試視窗內容 --------------------------------------<br />--------------------------------------
				測試視窗內容 --------------------------------------<br />--------------------------------------
				測試視窗內容 --------------------------------------<br
			/></WindowContent>
		</WindowFrame>
	</div>
</template>
  
<script>
import store from "@/store";
import MenuToWindow from "@/components/MenuToWindow.vue";
import WindowFrame from "@/components/WindowFrame.vue";
import WindowContent from "@/components/WindowContent.vue";
import SystemManagerMenuVue from "@/components/Menu/SystemManagerMenu.vue";
import { showMsg } from "@/utils/msgCtrl";
export default {
	name: "DebugView",
	components: {
		WindowFrame,
		WindowContent,
		SystemManagerMenuVue,
		MenuToWindow,
	},
	methods: {
		windowFadeIn() {
			store.dispatch("windowFadeIn");
			//store.state.isBGBlur = true;
			//store.state.isBGZoom = true;
		},
		windowFadeOut() {
			store.dispatch("windowFadeOut");
			//store.state.isBGBlur = false;
			//store.state.isBGZoom = false;
		},
		progressInfoIn() {
			store.dispatch("progressInfoIn");
			//store.state.isBGBlur = true;
			//store.state.isBGZoom = true;
		},
		progressInfoOut() {
			store.dispatch("progressInfoOut");
			//store.state.isBGBlur = false;
			//store.state.isBGZoom = false;
		},
		showMsg1() {
			showMsg({
				icon: {
					type: "Error",
					custom: {
						icon: "",
						color: "",
					},
				},
				msg: {
					title: "測試錯誤訊息",
					message: "這是一個測試用的錯誤訊息",
				},
				btn: [
					{
						text: "確定",
						type: "btn-primary",
						method: function () {},
					},
				],
			});
		},
		showWindow() {
			this.$refs.windowframe.setWindowSize(this.$refs.windowcontent1, false);
			this.$refs.windowcontent1.showContent();
			setTimeout(() => {
				this.$refs.windowframe.setWindowShow();
			}, 350);
		},
		hideWindow() {
			this.$refs.windowframe.setWindowHidden();
		},
		fadeInMenu() {
			this.$refs.menu.setFadeInItem();
		},
		setMenuToWindow(input) {
			var vm = this;
			let frame = null;
			let content = null;

			frame = vm.$refs.winframe_test1;
			content = vm.$refs.wincontent_test1;
			vm.$refs.menu.setFadeOutItem(input);
			vm.$refs.menutowindow.setMenuToWindow(input, frame, content);
		},
	},
	mounted() {
		var vm = this;
		store.dispatch("windowFadeIn");
		setTimeout(() => {
			vm.fadeInMenu();
		}, 150);
	},
};
</script>
