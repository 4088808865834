<template>
	<div class="modalwrapper" :class="[wrapperStatus]">
		<div class="modalwrapperbg" @click="onWrapperClick"></div>
		<div class="modalmain" :class="[modalStatus, width]">
			<div
				class="modalbg"
				:style="{
					opacity: modalOpacity,
					transform: 'scale(' + modalZoom + ', ' + modalZoom + ')',
					pointerEvents: modalPointEvent,
					transition: modalTrans + 's',
					transitionTimingFunction: modalTiming,
				}"
			></div>
			<div
				class="modalcontentwrapper"
				ref="modalfocus"
				:style="{
					opacity: contentOpacity,
					transform: 'scale(' + contentZoom + ', ' + contentZoom + ')',
					pointerEvents: contentPointEvent,
					transition: contentTrans + 's',
					transitionTimingFunction: contentTiming,
				}"
			>
				<div class="modalcontent" :class="[scroll, padding]">
					<slot name="content"></slot>
				</div>
				<div class="bottombarslot" :class="[padding]">
					<div class="bottombarslotbg"></div>
					<slot name="bar"></slot>
				</div>
			</div>
			<div
				class="modelborder"
				:style="{
					opacity: contentOpacity,
					transform: 'scale(' + contentZoom + ', ' + contentZoom + ')',
					transition: contentTrans + 's',
					transitionTimingFunction: contentTiming,
				}"
			></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ModalBox",
	props: ["width", "padding", "scroll"],
	data() {
		return {
			modalStatus: "",
			wrapperStatus: "",
			modalZoom: 1.2,
			modalPointEvent: "none",
			modalOpacity: 0,
			modalTrans: 0.25,
			modalTiming: "",
			contentZoom: 1.2,
			contentPointEvent: "none",
			contentOpacity: 0,
			contentTrans: 0.25,
			contentTiming: "",
		};
	},
	methods: {
		showModal() {
			var vm = this;
			vm.modalStatus = "show";
			vm.wrapperStatus = "show";
			vm.modalTrans = 0.25;
			vm.modalZoom = 0.95;
			vm.modalOpacity = 1;
			vm.contentTrans = 0.25;
			vm.contentZoom = 0.95;
			vm.contentOpacity = 1;
			setTimeout(() => {
				vm.modalTrans = 0.25;
				vm.modalZoom = 1;
				vm.modalOpacity = 1;
				vm.modalPointEvent = "all";
				vm.contentTrans = 0.25;
				vm.contentZoom = 1.0;
				vm.contentOpacity = 1;
				vm.contentPointEvent = "all";
			}, 250);
		},
		hideModal() {
			var vm = this;
			vm.modalTrans = 0.25;
			vm.modalZoom = 0.95;
			vm.modalOpacity = 0.5;
			vm.modalPointEvent = "none";
			vm.contentTrans = 0.25;
			vm.contentZoom = 0.95;
			vm.contentOpacity = 1;
			vm.contentPointEvent = "none";
			setTimeout(() => {
				vm.modalTrans = 0.15;
				vm.modalZoom = 1.2;
				vm.modalOpacity = 0;
				vm.contentTrans = 0.25;
				vm.contentZoom = 1.2;
				vm.contentOpacity = 0;
				vm.modalPointEvent = "none";
			}, 250);
			setTimeout(() => {
				vm.modalStatus = "hide";
				vm.wrapperStatus = "hide";
			}, 500);
		},
		onWrapperClick() {
			this.modalZoom = 1.03;
			this.contentZoom = 1.03;
			setTimeout(() => {
				this.modalZoom = 1.0;
				this.contentZoom = 1.0;
			}, 250);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.modalwrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	transition: 0.35s;
	z-index: 50;
}
.modalwrapper.show {
	pointer-events: all;
}
.modalwrapper.hide {
	pointer-events: none;
}
.modalwrapperbg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.modelborder {
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
	border: 1px solid rgba(100, 100, 100, 0.25);
	pointer-events: none;
	border-radius: 16px;
	z-index: 35;
}
.modalmain {
	position: relative;
	border-radius: 16px;
	opacity: 0;
	pointer-events: none;
	z-index: 40;
}
.modalbg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 16px;
	background: rgba(255, 255, 255, 0.25);
	backdrop-filter: blur(24px) saturate(1.5);
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.25);
	z-index: -1;
}
.modalmain.show {
	opacity: 1;
	pointer-events: all;
}
.modalmain.hide {
	opacity: 0;
	pointer-events: none;
}
.modalcontentwrapper {
	position: relative;
}
.modalcontent {
	max-height: 75vh;
	max-height: calc(var(--vh, 1vh) * 75);
	padding: 16px;
	padding-bottom: 64px;
	border-radius: 16px;
	overflow: visible;
	overflow-y: visible;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}
.modalcontent.borderpadding {
	padding: 16px;
	padding-bottom: 16px;
}
.modalcontent.borderpadding.nopadding {
	padding: 8px;
	padding-bottom: 8px;
}
.modalcontent.nopadding {
	padding: 8px;
}
.modalcontent.usescroll {
	overflow: hidden;
	overflow-y: auto;
}
.modalcontent::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}
.modalmain.w2 {
	width: 20%;
}
.modalmain.w3 {
	width: 30%;
}
.modalmain.w4 {
	width: 40%;
}
.modalmain.w5 {
	width: 50%;
}
.modalmain.w6 {
	width: 60%;
}
.modalmain.w7 {
	width: 70%;
}
.modalmain.w8 {
	width: 80%;
}
.modalmain.w9 {
	width: 90%;
}
@media (max-width: 767px) {
	.modalmain {
		min-width: 60% !important;
	}
	.modalcontent {
		max-height: 85vh;
		max-height: calc(var(--vh, 1vh) * 85);
	}
}
@media (max-width: 575px) {
	.modalmain {
		min-width: 80% !important;
	}
	.modalcontent {
		max-height: 80vh;
		max-height: calc(var(--vh, 1vh) * 80);
	}
}
@media (max-width: 374px) {
	.modalmain {
		min-width: 90% !important;
	}
	.modalcontent {
		max-height: 80vh;
		max-height: calc(var(--vh, 1vh) * 80);
	}
}
.bottombarslot {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 12px 16px;
	border-top: 1px solid rgba(100, 100, 100, 0.25);
	background: rgba(255, 255, 255, 0.25);
	backdrop-filter: blur(24px) saturate(1.5);
	border-bottom-right-radius: 16px;
	border-bottom-left-radius: 16px;
	z-index: 30;
}
.bottombarslot.borderpadding {
	display: none;
}
.bottombarslot.nopadding {
	padding: 6px 8px;
}
</style>
