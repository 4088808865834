export function getUserEntity() {
	return localStorage.getItem("userEntity")
		? JSON.parse(localStorage.getItem("userEntity")).UserEntity
		: null;
}

export function setUserEntity(userEntity) {
	let data = {
		UserEntity: userEntity,
		CreateTime: new Date().now,
	};
	localStorage.setItem("userEntity", JSON.stringify(data));
}

export function setUserEntityClear() {
	localStorage.removeItem("userEntity");
}
