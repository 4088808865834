import client from '@/api/client'

export function getSystemBase() {
    return client({
        url: 'api/Info/GetSystemBase'
        , method: 'get'
    })
}

export function getUserEntity() {
    return client({
        url: 'api/Info/GetUserEntity'
        , method: 'get'
    })
}