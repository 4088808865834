//複製到剪貼簿
export function copyToClipboard(input) {
	if (navigator.clipboard && window.isSecureContext) {
		return navigator.clipboard.writeText(input);
	} else {
		let textArea = document.createElement("textarea");
		textArea.value = input;
		textArea.style.position = "absolute";
		textArea.style.opacity = 0;
		textArea.style.left = "-999999px";
		textArea.style.top = "-999999px";
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		return new Promise((res, rej) => {
			document.execCommand("copy") ? res() : rej();
			textArea.remove();
		});
	}
}
