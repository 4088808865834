import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView.vue";
import DebugView from "@/views/DebugView.vue";
import StartupView from "@/views/StartupView.vue";
import LoginView from "@/views/LoginView.vue";
import ActiveView from "@/views/ActiveView.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "startup",
		component: StartupView,
		meta: {
			title: "小艾版補丁 Ver7 - 初始化",
		},
	},
	{
		path: "/debug",
		name: "debug",
		component: DebugView,
		meta: {
			title: "小艾版補丁 Ver7 - Debug",
		},
	},
	{
		path: "/login",
		name: "login",
		component: LoginView,
		meta: {
			title: "小艾版補丁 Ver7 - 登入",
		},
	},
	{
		path: "/home",
		name: "home",
		component: HomeView,
		meta: {
			title: "小艾版補丁 Ver7",
		},
	},
	{
		path: "/active",
		name: "active",
		component: ActiveView,
		meta: {
			title: "小艾版補丁 Ver7 - 啟用帳號",
		},
	},
	{
		path: "/about",
		name: "about",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.name == "debug") {
		next();
		return;
	}
	if (to.name == "active") {
		next();
		return;
	}
	if (to.name !== "startup" && store.state.baseInfo == null) {
		store.dispatch("routerStartupBack", {
			toName: to.name,
			query: to.query,
		});
		next({ name: "startup" });
		return;
	}
	if (
		to.name !== "login" &&
		to.name !== "startup" &&
		(store.state.userEntity == null || !store.state.userEntity.IsLogin)
	) {
		store.dispatch("routerLoginBack", {
			toName: to.name,
			query: to.query,
		});
		try {
			next({ name: "login" });
		} catch {
			console.log("router to login return to startup");
		}
	} else {
		next();
	}
});

const DEFAULT_TITLE = "小艾版補丁 Ver7";
router.afterEach((to) => {
	Vue.nextTick(() => {
		document.title = to.meta.title || DEFAULT_TITLE;
	});
});
export default router;
