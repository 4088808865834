var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"msgwrapper",class:[_vm.wrapperstatus],style:({
		opacity: _vm.rootOpacity,
		transition: _vm.rootTrans + 's',
		transitionTimingFunction: _vm.rootTiming,
	})},[_c('div',{staticClass:"msgwrapperbg",style:({
			backdropFilter: 'blur(' + _vm.wrapperBlur + 'px)',
			transition: _vm.wrapperTrans + 's',
			transitionTimingFunction: _vm.wrapperTiming,
		})}),_c('div',{staticClass:"contentwrapper"},[_c('div',{staticClass:"contentwrapperbg",on:{"click":_vm.onWrapperClick}}),_c('div',{staticClass:"contentframe",style:({
				opacity: _vm.msgOpacity,
				transform: 'scale(' + _vm.msgZoom + ', ' + _vm.msgZoom + ')',
				pointerEvents: _vm.msgPointEvent,
				transition: _vm.msgTrans + 's',
				transitionTimingFunction: _vm.msgTiming,
			})},[(_vm.msgData != null)?_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.msgData.icon.type.toUpperCase() == 'OK'.toUpperCase()),expression:"msgData.icon.type.toUpperCase() == 'OK'.toUpperCase()"}],staticClass:"col-12 text-success"},[_c('i',{staticClass:"fas fa-check-circle fa-5x"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.msgData.icon.type.toUpperCase() == 'Error'.toUpperCase()),expression:"msgData.icon.type.toUpperCase() == 'Error'.toUpperCase()"}],staticClass:"col-12 text-danger"},[_c('i',{staticClass:"fas fa-times-circle fa-5x"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.msgData.icon.type.toUpperCase() == 'Alert'.toUpperCase()),expression:"msgData.icon.type.toUpperCase() == 'Alert'.toUpperCase()"}],staticClass:"col-12 text-warning"},[_c('i',{staticClass:"fas fa-exclamation-circle fa-5x"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.msgData.icon.type.toUpperCase() == 'Quest'.toUpperCase()),expression:"msgData.icon.type.toUpperCase() == 'Quest'.toUpperCase()"}],staticClass:"col-12 text-secondary"},[_c('i',{staticClass:"fas fa-question-circle fa-5x"})]),(_vm.msgData.icon.type.toUpperCase() == 'Custom'.toUpperCase())?_c('div',{staticClass:"col-12"},[_c('i',{staticClass:"fa-5x",class:[
								_vm.msgData.icon.custom.icon,
								_vm.msgData.icon.custom.color,
							]})]):_vm._e()]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('h5',[_c('strong',[_vm._v(_vm._s(_vm.msgData.msg.title))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"msgscroll",domProps:{"innerHTML":_vm._s(_vm.msgData.msg.message)}})])]),(_vm.msgData.btn != null && _vm.msgData.btn.length != 0)?_c('hr'):_vm._e(),(_vm.msgData.btn.length == 1)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn w-100",class:[_vm.msgData.btn[0].type],on:{"click":function($event){return _vm.runMethod(_vm.msgData, _vm.msgData.btn[0])}}},[_vm._v(" "+_vm._s(_vm.msgData.btn[0].text)+" ")])])]):_vm._e(),(_vm.msgData.btn.length == 2)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 pe-2"},[_c('button',{staticClass:"btn w-100",class:[_vm.msgData.btn[1].type],on:{"click":function($event){return _vm.runMethod(_vm.msgData, _vm.msgData.btn[1])}}},[_vm._v(" "+_vm._s(_vm.msgData.btn[1].text)+" ")])]),_c('div',{staticClass:"col-6 ps-2"},[_c('button',{staticClass:"btn w-100",class:[_vm.msgData.btn[0].type],on:{"click":function($event){return _vm.runMethod(_vm.msgData, _vm.msgData.btn[0])}}},[_vm._v(" "+_vm._s(_vm.msgData.btn[0].text)+" ")])])]):_vm._e(),(_vm.msgData.btn.length > 2)?_c('div',{staticClass:"row"},_vm._l((_vm.msgData.btn),function(btn,i){return _c('div',{key:i,staticClass:"col-12",class:{
							'mt-4': i == _vm.msgData.btn.length - 1,
							'mt-2': i != _vm.msgData.btn.length - 1,
						}},[_c('button',{staticClass:"btn w-100",class:[btn.type],on:{"click":function($event){return _vm.runMethod(_vm.msgData, btn)}}},[_vm._v(" "+_vm._s(btn.text)+" ")])])}),0):_vm._e()]):_vm._e(),_c('div',{staticClass:"contentframeborder"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }