<template>
	<div class="windowwrapper">
		<MenuList width="w2" :isSelected="isSelected">
			<MenuItem
				v-for="item in targetDataList"
				:key="item.UID"
				:isSelected="checkIsSelectData(item)"
				:targetData="item"
				><div class="row w-100">
					<div class="col-12 text-truncate">
						<i class="fa-solid fa-circle-user me-1"></i> {{ item.UserName }}
					</div>
				</div>
			</MenuItem>
		</MenuList>
		<ContentPage
			v-if="targetData == null"
			otherclass="hidewhenmobile"
			width="w2"
		>
			<div class="emptywrapper">
				<div class="row">
					<div class="col-12 text-center">
						<i class="fa-solid fa-circle-user fa-10x"></i>
					</div>
					<div class="col-12 mt-3 text-center">
						<h4><strong>請選擇使用者</strong></h4>
					</div>
					<div class="col-12 text-center">
						請從選單選擇要查看的使用者執行階段
					</div>
				</div>
			</div>
		</ContentPage>
		<ContentPage
			v-if="targetData != null"
			:otherclass="contentStatus"
			width="w2"
		>
			<ContentSecion
				v-for="loginType in loginTypeFilterList"
				:key="loginType.value"
			>
				<div class="row">
					<div class="col-12">{{ loginType.name }}</div>
				</div>
				<hr class="mt-2 mb-2" />
				<div class="row">
					<div class="col-12">
						<ContentListItem
							v-for="item in targetList(loginType.value)"
							:key="item.UID"
							><div class="row">
								<div class="col-12">
									<div class="row">
										<div class="col-12">
											<strong>{{ getDisplayName(item) }}</strong>
										</div>
									</div>
									<template v-if="item.LastHistory != null">
										<hr class="mt-1 mb-2" />
										<div class="row">
											<div
												class="col-12"
												v-if="item.LastHistory.ClientData != null"
											>
												<div class="row">
													<div class="col-12 col-md-6 col-lg-3">
														系統：{{ item.LastHistory.ClientData.OSSystemName }}
														{{ item.LastHistory.ClientData.OSSystemVersion }}
													</div>
													<div class="col-12 col-md-6 col-lg-5">
														瀏覽器：{{
															item.LastHistory.ClientData.BrowserName
														}}
														{{ item.LastHistory.ClientData.BrowserVersion }}
													</div>
													<div
														class="col-12 col-md-6 col-lg-4"
														v-if="
															item.LastHistory.ClientData.DeviceType ==
															'desktop'
														"
													>
														裝置：Desktop
													</div>
													<div
														class="col-12 col-md-6 col-lg-4"
														v-if="
															item.LastHistory.ClientData.DeviceType !=
															'desktop'
														"
													>
														系統：{{ item.LastHistory.ClientData.DeviceBrand }}
														{{ item.LastHistory.ClientData.DeviceModel }}
													</div>
												</div>
											</div>
										</div>
									</template>
									<template v-if="item.LastHistory != null"></template>
									<hr class="mt-1 mb-2" />
									<div class="row">
										<div class="col-12">
											<div class="row">
												<div class="col-12">
													IP位置：{{ item.LastHistory.HostIPv4 }}
												</div>
											</div>
											<div
												class="row"
												v-if="item.LastHistory.LocationData != null"
											>
												<div class="col-12">
													國家/地區：{{
														item.LastHistory.LocationData.Country
													}}
													({{ item.LastHistory.LocationData.Currency }})
												</div>
												<div class="col-12">
													縣市/區域：{{ item.LastHistory.LocationData.Region }}
												</div>
												<div class="col-12">
													電信公司：{{ item.LastHistory.LocationData.ISP }}
													<span v-if="item.LastHistory.LocationData.Mobile"
														>(行動數據)</span
													>
												</div>
												<div class="col-12">
													時區：{{ item.LastHistory.LocationData.TimeZone }}
												</div>
											</div>
										</div>
									</div>
									<hr class="mt-1 mb-2" />
									<div class="row">
										<div
											v-if="item.IsActive && !item.IsDisabled"
											class="col-12"
										>
											<small>登入於 {{ item.CreateTime | moment }}</small>
											<br />
											<small>啟用於 {{ item.ActiveTime | moment }}</small>
											<br />
											<small>最後紀錄 {{ item.UpdateTime | moment }}</small>
										</div>
										<div
											v-if="!item.IsActive && !item.IsDisabled"
											class="col-12"
										>
											<small>登入於 {{ item.CreateTime | moment }}</small>
											<br />
											<small>最後紀錄 {{ item.UpdateTime | moment }}</small>
										</div>
										<div
											v-if="!item.IsActive && item.IsDisabled"
											class="col-12"
										>
											<small>登入於 {{ item.CreateTime | moment }}</small>
											<br />
											<small>中斷於 {{ item.DisabledTime | moment }}</small>
											<br />
											<small>最後紀錄 {{ item.UpdateTime | moment }}</small>
										</div>
										<div v-if="item.IsActive && item.IsDisabled" class="col-12">
											<small>登入於 {{ item.CreateTime | moment }}</small>
											<br />
											<small>登出於 {{ item.DisabledTime | moment }}</small>
										</div>
									</div>
									<hr class="mt-1 mb-2" />
									<div class="row">
										<div class="col-6">
											<button
												class="btn btn-light"
												@click="getUserSessionHistoryList(item)"
											>
												<i class="fa-solid fa-table-list"></i> 歷史紀錄
											</button>
										</div>
										<div class="col-6 text-end">
											<button
												class="btn btn-danger"
												v-if="item.IsActive && !item.IsDisabled"
												@click="getUserSessionDisabled(item)"
											>
												<i class="fa-solid fa-ban"></i> 登出執行階段
											</button>
											<button
												class="btn btn-danger"
												v-if="!item.IsActive && !item.IsDisabled"
												@click="getUserSessionDisabled(item)"
											>
												<i class="fa-solid fa-ban"></i> 取消登入
											</button>
										</div>
									</div>
								</div>
							</div>
						</ContentListItem>
					</div>
				</div>
			</ContentSecion>
			<ContentSecion>
				<div class="row">
					<div class="col-12 text-center">
						<button class="btn btn-lg btn-danger" @click="getUserSession">
							<i class="fa-solid fa-arrow-rotate-right"></i> 重讀
						</button>
					</div>
				</div>
			</ContentSecion>
		</ContentPage>
		<BottomBar>
			<div class="row">
				<div class="col-12">
					<button class="btn btn-light" @click="setBackBtn">
						<i class="fa-solid fa-arrow-left"></i> 返回
					</button>
					<button
						class="d-none d-sm-inline-block btn btn-danger ms-2"
						@click="getUserAccountList"
					>
						<i class="fa-solid fa-arrows-rotate"></i> 重讀列表
					</button>
				</div>
			</div>
		</BottomBar>
		<ModalBox ref="modalSessionHistory" width="w5" scroll="usescroll">
			<template v-slot:content>
				<div class="row" v-if="historyList.length != 0">
					<div class="col-12">
						<ContentListItem v-for="item in historyList" :key="item.UID"
							><div class="row">
								<div class="col-12">
									<div class="row">
										<div class="col-12">
											<div class="row">
												<div class="col-12">
													UserAgent：{{ item.UserAgent }}
												</div>
											</div>
											<div class="row" v-if="item.ClientData != null">
												<div class="col-12">
													系統：{{ item.ClientData.OSSystemName }}
													{{ item.ClientData.OSSystemVersion }}
												</div>
												<div class="col-12">
													瀏覽器：{{ item.ClientData.BrowserName }}
													{{ item.ClientData.BrowserVersion }}
												</div>
												<div
													class="col-12"
													v-if="item.ClientData.DeviceType == 'desktop'"
												>
													裝置：Desktop
												</div>
												<div
													class="col-12"
													v-if="item.ClientData.DeviceType != 'desktop'"
												>
													系統：{{ item.ClientData.DeviceBrand }}
													{{ item.ClientData.DeviceModel }}
												</div>
											</div>
										</div>
									</div>
									<hr class="mt-1 mb-2" />
									<div class="row">
										<div class="col-12">
											<div class="row">
												<div class="col-12">IP位置：{{ item.HostIPv4 }}</div>
											</div>
											<div class="row" v-if="item.LocationData != null">
												<div class="col-12">
													國家/地區：{{ item.LocationData.Country }} ({{
														item.LocationData.Currency
													}})
												</div>
												<div class="col-12">
													縣市/區域：{{ item.LocationData.Region }}
												</div>
												<div class="col-12">
													電信公司：{{ item.LocationData.ISP }}
													<span v-if="item.LocationData.Mobile"
														>(行動數據)</span
													>
												</div>
												<div class="col-12">
													時區：{{ item.LocationData.TimeZone }}
												</div>
											</div>
										</div>
									</div>
									<hr class="mt-1 mb-2" />
									<div class="row">
										<div class="col-12">
											<small>發現於 {{ item.CreateTime | moment }}</small>
										</div>
									</div>
								</div>
							</div>
						</ContentListItem>
					</div>
				</div>
			</template>
			<template v-slot:bar>
				<div class="row">
					<div class="col-12 text-end">
						<button class="btn btn-primary" @click="hideModalSessionHistory">
							完成
						</button>
					</div>
				</div>
			</template>
		</ModalBox>
		<ModalBox ref="modalSessionDisabled" width="w5">
			<template v-slot:content>
				<div class="row" v-if="disabledTarget != null">
					<div class="col-12">
						<small>目標執行階段 UID</small><br />
						<strong>{{ disabledTarget.UID }}</strong>
					</div>
					<div class="col-12">
						<small>登出原因</small><br />
						<div class="dropdown">
							<a
								class="btn btn-outline-secondary w-75 dropdown-toggle"
								href="#"
								role="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<span v-if="selectDisabledReason == null">請選擇原因</span>
								<span v-if="selectDisabledReason != null">{{
									selectDisabledReason.name
								}}</span>
							</a>

							<div class="dropdown-menu">
								<div
									v-for="item in disabledReasonList"
									:key="item.id"
									@click="selectDisabledReason = item"
								>
									<a class="dropdown-item" href="#">{{ item.name }}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:bar>
				<div class="row">
					<div class="col-6">
						<button class="btn btn-light" @click="hideModalSessionDisabled">
							返回
						</button>
					</div>
					<div class="col-6 text-end">
						<button class="btn btn-danger" @click="checkSessionDisabled">
							登出執行階段
						</button>
					</div>
				</div></template
			>
		</ModalBox>
		<ModalBox ref="modalLoading" width="w3" padding="borderpadding">
			<template v-slot:content>
				<div class="row">
					<div class="col-12 text-center">正在處理，請稍後...</div>
					<div class="col-12">
						<LoadSpin></LoadSpin>
					</div>
				</div>
			</template>
		</ModalBox>
	</div>
</template>

<script>
import BottomBar from "@/components/WindowUI/BottomBar.vue";
import MenuList from "@/components/WindowUI/MenuList.vue";
import MenuItem from "@/components/WindowUI/MenuItem.vue";
import ContentPage from "@/components/WindowUI/ContentPage.vue";
import ContentSecion from "@/components/WindowUI/ContentSection.vue";
import ModalBox from "@/components/WindowUI/ModalBox.vue";
import LoadSpin from "@/components/LoadSpin.vue";
import {
	getUserAccountList,
	getUserSession,
	getUserSessionHistoryList,
	setUserSessionDisabled,
} from "@/api/user";
import { showMsg } from "@/utils/msgCtrl";
import ContentListItem from "@/components/WindowUI/ContentListItem.vue";
import moment from "moment";
export default {
	name: "UserSession",
	props: {},
	data() {
		return {
			windowName: "systemmanager-usersession",
			targetDataList: [],
			selectItem: null,
			targetData: null,
			contentStatus: "",
			isSelected: false,
			permissionList: [],
			selectPermission: null,
			loadingCount: 0,
			historyList: [],
			loginTypeList: [
				{
					name: "已登入",
					value: "Active",
				},
				{
					name: "等待啟用",
					value: "WaitActive",
				},
				{
					name: "登入失敗",
					value: "ActiveFailed",
				},
				{
					name: "已登出",
					value: "Logout",
				},
			],
			selectDisabledReason: null,
			disabledTarget: null,
			disabledReasonList: [
				{
					id: 0,
					name: "不再使用此執行階段",
				},
				{
					id: 1,
					name: "可能被盜用的執行階段",
				},
				{
					id: 2,
					name: "不認識的執行階段",
				},
			],
		};
	},
	components: {
		BottomBar,
		MenuList,
		MenuItem,
		ContentPage,
		ContentSecion,
		ModalBox,
		LoadSpin,
		ContentListItem,
	},
	computed: {
		loginTypeFilterList: {
			get() {
				var vm = this;
				let result = [];
				vm.loginTypeList.forEach((item) => {
					if (vm.targetList(item.value).length != 0) result.push(item);
				});
				return result;
			},
		},
	},
	filters: {
		momentDate: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日");
		},
		moment: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日 HH:mm:ss");
		},
		commaFormat: function (value) {
			return value
				.toString()
				.replace(
					/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/,
					function (all, pre, groupOf3Digital) {
						return pre + groupOf3Digital.replace(/\d{3}/g, ",$&");
					}
				);
		},
	},
	watch: {
		selectItem(newValue) {
			var vm = this;
			if (newValue == null) {
				if (window.innerWidth < 576) {
					vm.isSelected = false;
					vm.contentStatus = "mobilehide";
					setTimeout(() => {
						vm.targetData = null;
					}, 650);
				} else {
					vm.targetData = null;
				}
				return;
			} else {
				vm.getUserSession();
			}
		},
		loadingCount(newValue) {
			var vm = this;
			if (newValue > 0) {
				vm.showModalLoading();
			} else {
				vm.hideModalLoading();
			}
		},
	},
	methods: {
		getUserAccountList() {
			var vm = this;
			vm.loadingCount++;
			vm.selectItem = null;
			vm.targetDataList = [];
			setTimeout(() => {
				getUserAccountList()
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.targetDataList = data;
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.setWindowToMenu();
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得使用者列表時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.setWindowToMenu();
									},
								},
							],
						});
					});
			}, 750);
		},
		getUserSession() {
			var vm = this;
			vm.loadingCount++;
			vm.targetData = null;
			setTimeout(() => {
				getUserSession(vm.selectItem)
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.targetData = data;
							vm.isSelected = true;
							vm.contentStatus = "mobileshow";
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.selectItem = null;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得使用者資料時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.selectItem = null;
									},
								},
							],
						});
					});
			}, 750);
		},
		getUserSessionHistoryList(input) {
			var vm = this;
			vm.loadingCount++;
			vm.historyList = [];
			setTimeout(() => {
				getUserSessionHistoryList(input)
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.historyList = data;
							vm.showModalSessionHistory();
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得使用者執行階段歷史列表時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
									},
								},
							],
						});
					});
			}, 750);
		},
		getUserSessionDisabled(input) {
			var vm = this;
			vm.disabledTarget = input;
			vm.showModalSessionDisabled();
		},
		targetList(input) {
			var vm = this;
			let result = [];
			vm.targetData.forEach((item) => {
				if (input == "Active") {
					if (item.IsActive && !item.IsDisabled) result.push(item);
				}
				if (input == "WaitActive") {
					if (!item.IsActive && !item.IsDisabled) result.push(item);
				}
				if (input == "ActiveFailed") {
					if (!item.IsActive && item.IsDisabled) result.push(item);
				}
				if (input == "Logout") {
					if (item.IsActive && item.IsDisabled) result.push(item);
				}
			});
			return result;
		},
		setItemClick(input) {
			var vm = this;
			if (vm.checkIsSelectData(input)) return;
			vm.selectItem = JSON.parse(JSON.stringify(input));
		},
		setItemClear() {
			var vm = this;
			if (window.innerWidth < 576) {
				if (vm.selectItem != null) {
					vm.selectItem = null;
				} else {
					vm.setWindowToMenu();
				}
			} else {
				vm.selectItem = null;
			}
		},
		checkIsSelectData(input) {
			var vm = this;
			let result = false;
			if (vm.selectItem != null) {
				if (vm.selectItem.UID == input.UID) result = true;
			}
			return result;
		},
		setBackBtn() {
			var vm = this;
			if (window.innerWidth < 576) {
				if (vm.selectItem != null) {
					vm.selectItem = null;
				} else {
					vm.setWindowToMenu();
				}
			} else {
				vm.setWindowToMenu();
			}
		},
		setWindowToMenu() {
			var vm = this;
			vm.$parent.$parent.$parent.setWindowToMenu(vm);
			setTimeout(() => {
				vm.selectItem = null;
				vm.targetDataList = [];
			}, 500);
		},
		showModalLoading() {
			var vm = this;
			vm.$refs.modalLoading.showModal();
		},
		hideModalLoading() {
			var vm = this;
			vm.$refs.modalLoading.hideModal();
		},
		showModalSessionHistory() {
			var vm = this;
			vm.$refs.modalSessionHistory.showModal();
		},
		hideModalSessionHistory() {
			var vm = this;
			vm.$refs.modalSessionHistory.hideModal();
		},
		showModalSessionDisabled() {
			var vm = this;
			vm.selectDisabledReason = vm.disabledReasonList[0];
			vm.$refs.modalSessionDisabled.showModal();
		},
		hideModalSessionDisabled() {
			var vm = this;
			setTimeout(() => {
				vm.selectDisabledReason = vm.disabledReasonList[0];
				vm.disabledTarget = null;
			}, 500);
			vm.$refs.modalSessionDisabled.hideModal();
		},
		checkSessionDisabled() {
			var vm = this;
			if (vm.disabledTarget == null) {
				showMsg({
					icon: {
						type: "Error",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "登出使用者執行階段",
						message: "必須選擇要登出的使用者執行階段，才能進行登出",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {
								vm.hideModalSessionDisabled();
							},
						},
					],
				});
				return;
			}
			if (vm.selectDisabledReason == null) {
				showMsg({
					icon: {
						type: "Error",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "登出使用者執行階段",
						message:
							"必須選擇要登出的使用者執行階段的原因進行紀錄，才能進行登出",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {},
						},
					],
				});
				return;
			}
			vm.disabledTarget.DisabledReason = vm.selectDisabledReason.name;
			showMsg({
				icon: {
					type: "Custom",
					custom: {
						icon: "fa-solid fa-trash",
						color: "text-danger",
					},
				},
				msg: {
					title: "登出使用者執行階段",
					message:
						"確定要登出此使用者階段嗎？<br/>" +
						"目標使用者：" +
						vm.selectItem.UserName +
						"<br/>目標執行階段：" +
						vm.disabledTarget.UID +
						"<br/>登出原因：" +
						vm.disabledTarget.DisabledReason +
						"",
				},
				btn: [
					{
						text: "確定登出",
						type: "btn-danger",
						method: function () {
							vm.setSessionDisabled();
						},
					},
					{
						text: "取消",
						type: "btn-light",
						method: function () {
							vm.hideModalSessionDisabled();
						},
					},
				],
			});
		},
		setSessionDisabled() {
			var vm = this;
			vm.loadingCount++;
			setTimeout(() => {
				setUserSessionDisabled(vm.disabledTarget)
					.then((res) => {
						if (res.Success) {
							vm.getUserSession();
							vm.hideModalSessionDisabled();
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "登出使用者執行階段",
								message:
									"連線到伺服器登出使用者執行階段時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
									},
								},
							],
						});
					});
			}, 750);
		},
		setDataInit() {
			var vm = this;
			setTimeout(() => {
				vm.getUserAccountList();
			}, 750);
		},
		getDisplayName(input) {
			let result = "Unknown Device";

			if (input.LastHistory != null && input.LastHistory.ClientData != null) {
				let clientData = input.LastHistory.ClientData;
				if (clientData.DeviceType.toUpperCase() == "desktop".toUpperCase())
					result =
						"Desktop - " +
						clientData.OSSystemName +
						" " +
						clientData.OSSystemVersion;
				else
					result =
						clientData.DeviceBrand +
						" " +
						clientData.DeviceModel +
						" (" +
						clientData.OSSystemName +
						" " +
						clientData.OSSystemVersion +
						")";
			}

			if (input.DisplayName != null && input.DisplayName != "")
				result = input.DisplayName;

			return result;
		},
	},
	mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
