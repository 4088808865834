var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modalwrapper",class:[_vm.wrapperStatus]},[_c('div',{staticClass:"modalwrapperbg",on:{"click":_vm.onWrapperClick}}),_c('div',{staticClass:"modalmain",class:[_vm.modalStatus, _vm.width]},[_c('div',{staticClass:"modalbg",style:({
				opacity: _vm.modalOpacity,
				transform: 'scale(' + _vm.modalZoom + ', ' + _vm.modalZoom + ')',
				pointerEvents: _vm.modalPointEvent,
				transition: _vm.modalTrans + 's',
				transitionTimingFunction: _vm.modalTiming,
			})}),_c('div',{ref:"modalfocus",staticClass:"modalcontentwrapper",style:({
				opacity: _vm.contentOpacity,
				transform: 'scale(' + _vm.contentZoom + ', ' + _vm.contentZoom + ')',
				pointerEvents: _vm.contentPointEvent,
				transition: _vm.contentTrans + 's',
				transitionTimingFunction: _vm.contentTiming,
			})},[_c('div',{staticClass:"modalcontent",class:[_vm.scroll, _vm.padding]},[_vm._t("content")],2),_c('div',{staticClass:"bottombarslot",class:[_vm.padding]},[_c('div',{staticClass:"bottombarslotbg"}),_vm._t("bar")],2)]),_c('div',{staticClass:"modelborder",style:({
				opacity: _vm.contentOpacity,
				transform: 'scale(' + _vm.contentZoom + ', ' + _vm.contentZoom + ')',
				transition: _vm.contentTrans + 's',
				transitionTimingFunction: _vm.contentTiming,
			})})])])
}
var staticRenderFns = []

export { render, staticRenderFns }