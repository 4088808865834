<template>
	<div class="listwrapper" :class="[width, listStatus]">
		<div
			class="list"
			:style="{
				paddingTop: listPaddingTop + 'px',
				paddingBottom: listPaddingBottom + 'px',
			}"
		>
			<div class="listcontent">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MenuList",
	props: ["isSelected", "width", "topbar", "bottombar", "windowtopbar"],
	data() {
		return {
			listStatus: "",
			listPaddingTop: 4,
			listPaddingBottom: 46,
		};
	},
	watch: {
		isSelected(newValue) {
			var vm = this;
			if (newValue) {
				vm.listStatus = "mobilehide";
			} else {
				vm.listStatus = "mobileshow";
			}
		},
		topbar() {
			var vm = this;
			vm.setListPadding();
		},
		bottombar() {
			var vm = this;
			vm.setListPadding();
		},
		windowtopbar() {
			var vm = this;
			vm.setListPadding();
		},
	},
	methods: {
		setListPadding() {
			var vm = this;
			let top = 4;
			if (vm.windowtopbar) {
				top = 46;
			}
			if (vm.topbar) {
				top += 44;
			}
			vm.listPaddingTop = top;

			let bottom = 46;
			if (vm.bottombar) {
				bottom += 44;
			}
			vm.listPaddingBottom = bottom;
		},
	},
	created() {
		var vm = this;
		vm.setListPadding();
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.listwrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 30%;
	transition: 0.35s;
}
.listwrapper.w2 {
	width: 20%;
}
.listwrapper.w3 {
	width: 30%;
}
.listwrapper.w4 {
	width: 40%;
}
.listwrapper.w5 {
	width: 50%;
}
.listwrapper.w6 {
	width: 60%;
}
@media (max-width: 575px) {
	.listwrapper {
		width: auto !important;
		right: 0;
	}
	.listwrapper.mobilehide {
		opacity: 0;
		pointer-events: none;
		animation: listwrapper-mobilehide 0.65s ease-in-out;
	}
	.listwrapper.mobileshow {
		opacity: 1;
		animation: listwrapper-mobileshow 0.65s ease-in-out;
	}
	@keyframes listwrapper-mobilehide {
		0% {
			opacity: 1;
			transform: scale(1, 1) translate(0px, 0px);
		}
		100% {
			opacity: 0;
			transform: scale(1, 1) translate(-575px, 0px);
		}
	}
	@keyframes listwrapper-mobileshow {
		0% {
			opacity: 0;
			transform: scale(1, 1) translate(-575px, 0px);
		}
		100% {
			opacity: 1;
			transform: scale(1, 1) translate(0px, 0px);
		}
	}
}
@media (max-width: 767px) {
	.listwrapper.w2 {
		width: 35%;
	}
}
@media (max-width: 991px) {
	.listwrapper.w2 {
		width: 30%;
	}
	.listwrapper.w3 {
		width: 35%;
	}
}
.list {
	height: 100%;
	overflow: auto;
	padding-bottom: 54px;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}
.list::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}
</style>
