<template>
	<div class="windowwrapper">
		<ContentPage v-if="targetDataList != null" width="w0">
			<ContentSecion v-for="item in targetDataList" :key="item.UID">
				<div class="row">
					<div class="col-12 smallfont-10px">{{ item.UID }}</div>
				</div>
				<hr class="mt-2 mb-2" />
				<div class="row">
					<div class="col-12 smallfont-10px">系統名稱</div>
					<div class="col-12">
						{{ item.Name }}
					</div>
					<div class="col-12 smallfont-10px">詳細說明</div>
					<div class="col-12">
						{{ item.Description }}
					</div>
					<div class="col-12 smallfont-10px">金鑰</div>
					<div class="col-12" style="word-break: break-all">
						{{ item.PrivateAPIKey }}
					</div>
				</div>
				<hr class="mt-2 mb-2" />
				<div class="row">
					<div class="col-6">
						<button
							class="btn btn-light"
							@click="setCopyToClipBoard(item.PrivateAPIKey)"
						>
							<i class="fa-solid fa-copy"></i> 複製金鑰
						</button>
					</div>
					<div class="col-6 text-end">
						<button class="btn btn-success" @click="showModalEditSystem(item)">
							<i class="fa-solid fa-pen-to-square"></i> 編輯
						</button>
					</div>
				</div>
			</ContentSecion>
		</ContentPage>
		<BottomBar>
			<div class="row">
				<div class="col-12">
					<button class="btn btn-light" @click="setBackBtn">
						<i class="fa-solid fa-arrow-left"></i> 返回
					</button>
					<button
						class="d-none d-sm-inline-block btn btn-danger ms-2"
						@click="getSystemList"
					>
						<i class="fa-solid fa-arrows-rotate"></i> 重讀列表
					</button>
				</div>
			</div>
		</BottomBar>
		<ModalBox ref="modalEditSystem" width="w6">
			<template v-slot:content>
				<div class="row" v-if="targetData != null">
					<div class="col-12 col-xl-12">
						<small>名稱</small><br />
						<input type="text" class="form-control" v-model="targetData.Name" />
					</div>
					<div class="col-12 col-xl-12">
						<small>詳細說明</small><br />
						<textarea
							class="form-control"
							rows="3"
							v-model="targetData.Description"
						></textarea>
					</div>
				</div>
			</template>
			<template v-slot:bar>
				<div class="row">
					<div class="col-6">
						<button class="btn btn-light" @click="hideModalEditSystem">
							返回
						</button>
					</div>
					<div class="col-6 text-end">
						<button class="btn btn-primary ms-1" @click="setSystem">
							設定系統
						</button>
					</div>
				</div>
			</template>
		</ModalBox>
		<ModalBox ref="modalLoading" width="w3" padding="borderpadding">
			<template v-slot:content>
				<div class="row">
					<div class="col-12 text-center">正在處理，請稍後...</div>
					<div class="col-12">
						<LoadSpin></LoadSpin>
					</div>
				</div>
			</template>
		</ModalBox>
	</div>
</template>

<script>
import BottomBar from "@/components/WindowUI/BottomBar.vue";
import ContentPage from "@/components/WindowUI/ContentPage.vue";
import ContentSecion from "@/components/WindowUI/ContentSection.vue";
import ModalBox from "@/components/WindowUI/ModalBox.vue";
import LoadSpin from "@/components/LoadSpin.vue";
import { setSystem, getSystemList } from "@/api/system";
import { showMsg } from "@/utils/msgCtrl";
import { copyToClipboard } from "@/utils/clipBoard";
import moment from "moment";
export default {
	name: "SystemManager",
	props: {},
	data() {
		return {
			windowName: "systemmanager-systemmanager",
			targetDataList: [],
			targetData: null,
			loadingCount: 0,
		};
	},
	components: {
		BottomBar,
		ContentPage,
		ContentSecion,
		ModalBox,
		LoadSpin,
	},
	computed: {},
	filters: {
		momentDate: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日");
		},
		moment: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日 HH:mm:ss");
		},
		commaFormat: function (value) {
			return value
				.toString()
				.replace(
					/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/,
					function (all, pre, groupOf3Digital) {
						return pre + groupOf3Digital.replace(/\d{3}/g, ",$&");
					}
				);
		},
	},
	watch: {
		loadingCount(newValue) {
			var vm = this;
			if (newValue > 0) {
				vm.showModalLoading();
			} else {
				vm.hideModalLoading();
			}
		},
	},
	methods: {
		getSystemList() {
			var vm = this;
			vm.loadingCount++;
			vm.targetDataList = [];
			setTimeout(() => {
				getSystemList()
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.targetDataList = data;
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.setWindowToMenu();
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得系統列表時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.setWindowToMenu();
									},
								},
							],
						});
					});
			}, 750);
		},
		setSystem() {
			var vm = this;
			if (vm.targetData == null) {
				showMsg({
					icon: {
						type: "Error",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "設定系統",
						message: "無法進行設定系統，必須先選擇一個系統之後才能繼續",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {},
						},
					],
				});
				return;
			}
			vm.loadingCount++;
			setTimeout(() => {
				setSystem(vm.targetData)
					.then((res) => {
						if (res.Success) {
							vm.getSystemList();
							vm.hideModalEditSystem();
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "設定系統",
								message:
									"連線到伺服器設定系統時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
									},
								},
							],
						});
					});
			}, 750);
		},
		setBackBtn() {
			var vm = this;
			vm.setWindowToMenu();
		},
		setWindowToMenu() {
			var vm = this;
			vm.$parent.$parent.$parent.setWindowToMenu(vm);
			setTimeout(() => {
				vm.selectItem = null;
				vm.targetDataList = [];
			}, 500);
		},
		setCopyToClipBoard(input) {
			copyToClipboard(input);
		},
		showModalLoading() {
			var vm = this;
			vm.$refs.modalLoading.showModal();
		},
		hideModalLoading() {
			var vm = this;
			vm.$refs.modalLoading.hideModal();
		},
		showModalEditSystem(input) {
			var vm = this;
			if (input == null) {
				vm.targetData = {
					Name: "",
					Description: "",
				};
			} else {
				vm.targetData = JSON.parse(JSON.stringify(input));
			}
			vm.$refs.modalEditSystem.showModal();
		},
		hideModalEditSystem() {
			var vm = this;
			vm.$refs.modalEditSystem.hideModal();
		},
		setDataInit() {
			var vm = this;
			setTimeout(() => {
				vm.getSystemList();
			}, 750);
		},
	},
	mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
