<template>
	<div class="wrapper">
		<WindowFrame ref="windowframe">
			<WindowContent ref="windowloading"
				><div class="loadingwrapper">
					<div class="row">
						<div class="col-12 text-center">{{ loadText }}</div>
						<div class="col-12">
							<LoadSpin></LoadSpin>
						</div>
					</div></div
			></WindowContent>
		</WindowFrame>
	</div>
</template>

<script>
import WindowFrame from "@/components/WindowFrame.vue";
import WindowContent from "@/components/WindowContent.vue";
import LoadSpin from "@/components/LoadSpin.vue";
import { activeSession } from "@/api/user";
import { showMsg } from "@/utils/msgCtrl";
export default {
	name: "ActiveView",
	data() {
		return {
			loadText: "正在驗證帳號，請稍後",
		};
	},
	components: { WindowFrame, WindowContent, LoadSpin },
	methods: {
		activeSession() {
			var vm = this;
			let activeKey = vm.$route.query.Key;
			if (activeKey == null || activeKey == "") {
				showMsg({
					icon: {
						type: "Error",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "未知的金鑰",
						message: "無法執行登入驗證，因為無法處理金鑰資訊",
					},
					btn: [],
				});
				return;
			}

			vm.$refs.windowframe.setWindowSize(vm.$refs.windowloading, false);
			setTimeout(() => {
				vm.$refs.windowloading.showContent();
				vm.$refs.windowframe.setWindowShow();
			}, 300);
			vm.loadText = "正在驗證帳號，請稍後";

			setTimeout(() => {
				activeSession({ ActiveKey: activeKey })
					.then((res) => {
						if (res.Success) {
							showMsg({
								icon: {
									type: "Ok",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [],
							});
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [],
							});
						}

						vm.$refs.windowframe.setWindowHidden();
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "驗證失敗",
								message:
									"連線到伺服器進行驗證時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [],
						});
						vm.$refs.windowframe.setWindowHidden();
					});
			}, 900);
		},
	},
	mounted() {
		var vm = this;
		setTimeout(() => {
			vm.activeSession();
		}, 300);
	},
};
</script>

<style scoped lang="less">
.loadingwrapper {
	width: 240px;
	padding: -16px;
}
@media (max-width: 1200px) {
	.loadingwrapper {
		width: 240px;
		padding: -16px;
	}
}
@media (max-width: 992px) {
	.loadingwrapper {
		width: 240px;
		padding: -16px;
	}
}
@media (max-width: 768px) {
	.loadingwrapper {
		width: 240px;
		padding: -16px;
	}
}
@media (max-width: 576px) {
	.loadingwrapper {
		width: 75vw;
		padding: -16px;
	}
}
</style>