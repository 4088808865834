<template>
	<div class="subwindowwrapper">
		<MenuList width="w3" :isSelected="isSelected" :windowtopbar="true">
			<MenuItem
				v-for="item in targetDataList"
				:key="item.UID"
				:isSelected="checkIsSelectData(item)"
				:targetData="item"
				><div class="row w-100" v-if="getStatusItem(item) != null">
					<div class="col-12 text-truncate">
						<span v-show="getStatusItem(item).Status == 'Padding'">
							<i class="fa-solid fa-hourglass-half me-1"></i
						></span>
						<span v-show="getStatusItem(item).Status == 'Process'">
							<i class="fa-solid fa-play me-1"></i
						></span>
						<span v-show="getStatusItem(item).Status == 'Stop'">
							<i class="fa-solid fa-circle-xmark me-1"></i
						></span>
						<span v-show="getStatusItem(item).Status == 'Finish'">
							<i class="fa-solid fa-circle-check me-1"></i
						></span>
						{{ item.Name }}
					</div>
					<div
						class="col-12 mt-1 pt-1"
						v-if="
							getStatusItem(item).Status == 'Padding' ||
							getStatusItem(item).Status == 'Process'
						"
					>
						<ProgressBar
							:iswait="getStatusItem(item).Status == 'Process'"
							:value="getStatusItem(item).Progress"
							height="8"
						></ProgressBar>
					</div>
				</div>
				<div class="row w-100" v-if="getStatusItem(item) == null">
					<div class="col-12 text-truncate">
						{{ item.Name }}
					</div>
				</div>
			</MenuItem>
		</MenuList>
		<ContentPage
			v-if="targetData == null"
			otherclass="hidewhenmobile"
			:windowtopbar="true"
			width="w3"
		>
			<div class="emptywrapper">
				<div class="row">
					<div class="col-12 text-center">
						<i class="fa-solid fa-code-pull-request fa-10x"></i>
					</div>
					<div class="col-12 mt-3 text-center">
						<h4><strong>請選擇任務</strong></h4>
					</div>
					<div class="col-12 text-center">請從選單選擇要查看的任務</div>
				</div>
			</div>
		</ContentPage>
		<ContentPage
			v-if="targetData != null"
			:otherclass="contentStatus"
			:windowtopbar="true"
			width="w3"
		>
			<div class="row py-2"></div>
			<ContentSecion>
				<div class="row">
					<div
						class="col-4 col-md-2 text-center align-self-center position-relative"
					>
						<div class="questiconwrapper">
							<div class="questicon">
								<i class="fa-solid fa-code-pull-request fa-4x"></i>
								<div class="statusicon" :class="[getStatusTargetStatusColor()]">
									<span
										class="text-dark"
										v-show="getStatusTarget()?.Status == 'Padding'"
									>
										<i class="fa-solid fa-hourglass-half"></i
									></span>
									<span
										class="text-primary"
										v-show="getStatusTarget()?.Status == 'Process'"
									>
										<i class="fa-solid fa-play"></i
									></span>
									<span
										class="text-danger"
										v-show="getStatusTarget()?.Status == 'Stop'"
									>
										<i class="fa-solid fa-circle-xmark"></i
									></span>
									<span
										class="text-success"
										v-show="getStatusTarget()?.Status == 'Finish'"
									>
										<i class="fa-solid fa-circle-check"></i
									></span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-md-10 text-start align-self-center">
						<div class="row">
							<div class="col-12">
								<h4>
									<strong>{{ targetData.Name }}</strong>
								</h4>
							</div>
							<div class="col-12">
								狀態：<span v-show="getStatusTarget() == null">未知狀態</span
								><span v-show="getStatusTarget() != null"
									><span v-show="getStatusTarget()?.Status == 'Padding'">
										正在等待執行</span
									>
									<span v-show="getStatusTarget()?.Status == 'Process'">
										正在執行</span
									>
									<span v-show="getStatusTarget()?.Status == 'Stop'">
										任務中斷或無回應</span
									>
									<span v-show="getStatusTarget()?.Status == 'Finish'">
										已完成任務</span
									></span
								>
							</div>
							<div class="col-12 text-black-50 text-truncate">
								<small>唯一識別碼：{{ targetData.UID }}</small>
							</div>
							<div class="col-12">
								<small class="text-black-50 text-truncate"
									>開始於：{{ targetData.CreateTime | moment }}</small
								>
							</div>
							<div class="col-12">
								<small class="text-black-50 text-truncate"
									>最後更新於：{{ targetData.UpdateTime | moment }}</small
								>
							</div>
						</div>
					</div>
				</div>
			</ContentSecion>
			<ContentSecion>
				<div class="row">
					<div class="col-12">
						<ContentListItem
							v-for="item in targetData.WorkerList"
							:key="item.UID"
						>
							<div class="row">
								<div class="col-12">
									<div class="row">
										<div class="col-1 px-1 text-center border-end">
											<span
												v-show="
													getStatusTargetWorker(item)?.Status == 'Padding'
												"
											>
												<i class="fa-solid fa-hourglass-half"></i
											></span>
											<span
												v-show="
													getStatusTargetWorker(item)?.Status == 'Process'
												"
											>
												<i class="fa-solid fa-play"></i
											></span>
											<span
												v-show="getStatusTargetWorker(item)?.Status == 'Stop'"
											>
												<i class="fa-solid fa-circle-xmark"></i
											></span>
											<span
												v-show="getStatusTargetWorker(item)?.Status == 'Finish'"
											>
												<i class="fa-solid fa-circle-check"></i
											></span>
										</div>
										<div class="col-1 pe-1 text-center border-end">
											{{ item.ID }}
										</div>
										<div class="col-2 pe-1 text-center border-end">
											<span v-if="getStatusTargetWorker(item) == null">
												{{ item.Status }}
											</span>
											<span v-if="getStatusTargetWorker(item) != null">
												<span
													v-show="
														getStatusTargetWorker(item)?.Status == 'Padding'
													"
												>
													等待
												</span>
												<span
													v-show="
														getStatusTargetWorker(item)?.Status == 'Process'
													"
												>
													運行
												</span>
												<span
													v-show="getStatusTargetWorker(item)?.Status == 'Stop'"
												>
													中斷
												</span>
												<span
													v-show="
														getStatusTargetWorker(item)?.Status == 'Finish'
													"
												>
													完成
												</span>
											</span>
										</div>
										<div class="col-2 pe-1 text-center border-end">
											<span v-if="getStatusTargetWorker(item) == null">
												{{ item.Progress }}%
											</span>
											<span v-if="getStatusTargetWorker(item) != null">
												{{ getStatusTargetWorker(item).Progress }}%
											</span>
										</div>
										<div class="col-4 pe-1">
											{{ item.WorkerName }}
										</div>
									</div>
									<div
										class="row border-top mt-2"
										v-if="
											getStatusTargetWorker(item) == null &&
											item.Status == 'Process'
										"
									>
										<div class="col-12 pt-2">
											<ProgressBar
												iswait="false"
												:value="item.Progress"
												height="12"
											></ProgressBar>
										</div>
									</div>
									<div
										class="row border-top mt-2"
										v-if="
											getStatusTargetWorker(item) != null &&
											getStatusTargetWorker(item).Status == 'Process'
										"
									>
										<div class="col-12 pt-2">
											<ProgressBar
												iswait="false"
												:value="getStatusTargetWorker(item).Progress"
												height="12"
											></ProgressBar>
										</div>
									</div>
								</div>
							</div>
						</ContentListItem>
					</div>
				</div>
			</ContentSecion>
		</ContentPage>
		<BottomBar>
			<div class="row">
				<div class="col-12">
					<button class="btn btn-light" @click="setBackBtn">
						<i class="fa-solid fa-arrow-left"></i> 返回
					</button>
					<button
						class="d-none d-sm-inline-block btn btn-danger ms-2"
						@click="getQuestList"
					>
						<i class="fa-solid fa-arrows-rotate"></i> 重讀列表
					</button>
				</div>
			</div>
		</BottomBar>
		<ModalBox ref="modalLoading" width="w3" padding="borderpadding">
			<template v-slot:content>
				<div class="row">
					<div class="col-12 text-center">正在處理，請稍後...</div>
					<div class="col-12">
						<LoadSpin></LoadSpin>
					</div>
				</div>
			</template>
		</ModalBox>
	</div>
</template>

<script>
import BottomBar from "@/components/WindowUI/BottomBar.vue";
import MenuList from "@/components/WindowUI/MenuList.vue";
import MenuItem from "@/components/WindowUI/MenuItem.vue";
import ContentPage from "@/components/WindowUI/ContentPage.vue";
import ContentSecion from "@/components/WindowUI/ContentSection.vue";
import ModalBox from "@/components/WindowUI/ModalBox.vue";
import LoadSpin from "@/components/LoadSpin.vue";
import {
	getQuestList,
	getQuest,
	getQuestStatusList,
	getQuestStatus,
} from "@/api/quest";
import { showMsg } from "@/utils/msgCtrl";
import ContentListItem from "@/components/WindowUI/ContentListItem.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import moment from "moment";
export default {
	name: "QuestManager-QuestHistory",
	props: {},
	data() {
		return {
			targetDataList: [],
			selectItem: null,
			targetData: null,
			contentStatus: "",
			isSelected: false,
			loadingCount: 0,
			statusList: [],
		};
	},
	components: {
		BottomBar,
		MenuList,
		MenuItem,
		ContentPage,
		ContentSecion,
		ModalBox,
		LoadSpin,
		ContentListItem,
		ProgressBar,
	},
	computed: {},
	filters: {
		momentDate: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日");
		},
		moment: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日 HH:mm:ss");
		},
		commaFormat: function (value) {
			return value
				.toString()
				.replace(
					/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/,
					function (all, pre, groupOf3Digital) {
						return pre + groupOf3Digital.replace(/\d{3}/g, ",$&");
					}
				);
		},
	},
	watch: {
		selectItem(newValue) {
			var vm = this;
			if (newValue == null) {
				if (window.innerWidth < 576) {
					vm.isSelected = false;
					vm.contentStatus = "mobilehide";
					setTimeout(() => {
						vm.targetData = null;
					}, 650);
				} else {
					vm.targetData = null;
				}
				return;
			} else {
				vm.getQuest();
			}
		},
		loadingCount(newValue) {
			var vm = this;
			if (newValue > 0) {
				vm.showModalLoading();
			} else {
				vm.hideModalLoading();
			}
		},
	},
	methods: {
		getQuestList() {
			var vm = this;
			vm.loadingCount++;
			vm.selectItem = null;
			vm.targetDataList = [];
			setTimeout(() => {
				getQuestList()
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.targetDataList = data;
							vm.getQuestStatusList();
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.setWindowToMenu();
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得任務設定檔列表時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.setWindowToMenu();
									},
								},
							],
						});
					});
			}, 750);
		},
		getQuest() {
			var vm = this;
			vm.loadingCount++;
			vm.targetData = null;
			vm.statusItem = null;
			setTimeout(() => {
				getQuest(vm.selectItem)
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.targetData = data;
							vm.isSelected = true;
							vm.contentStatus = "mobileshow";
							vm.getQuestStatus();
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.selectItem = null;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得任務設定檔資料時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.selectItem = null;
									},
								},
							],
						});
					});
			}, 750);
		},
		getQuestStatusList() {
			var vm = this;
			if (vm.targetDataList.length == 0) return;
			getQuestStatusList().then((res) => {
				if (res.Success) {
					let data = res.Data[0];
					vm.statusList = data;
				}
			});
		},
		getQuestStatus() {
			var vm = this;
			if (vm.targetDataList.length == 0) return;
			if (vm.selectItem == null) return;
			getQuestStatus(vm.selectItem).then((res) => {
				if (res.Success) {
					let data = res.Data[0];
					vm.statusItem = data;
				}
			});
		},
		getStatusItem(input) {
			var vm = this;
			let result = null;
			if (vm.statusList.length == 0) return result;
			vm.statusList.forEach((item) => {
				if (item.UID == input.UID) result = item;
			});
			return result;
		},
		getStatusTarget() {
			var vm = this;
			let result = null;
			if (vm.statusItem == null) return;
			if (vm.statusItem.UID == vm.targetData.UID) result = vm.statusItem;
			return result;
		},
		getStatusTargetStatusColor() {
			var vm = this;
			let result = "";
			if (vm.getStatusTarget() == null) return result;

			switch (vm.getStatusTarget().Status) {
				case "Padding":
					result = "dark";
					break;
				case "Process":
					result = "primary";
					break;
				case "Stop":
					result = "danger";
					break;
				case "Finish":
					result = "success";
					break;
			}
			return result;
		},
		getStatusTargetWorker(input) {
			var vm = this;
			let result = null;
			if (vm.getStatusTarget() != null) {
				vm.getStatusTarget().WorkerList.forEach((item) => {
					if (item.ID == input.ID) result = item;
				});
			}
			return result;
		},
		setItemClick(input) {
			var vm = this;
			if (vm.checkIsSelectData(input)) return;
			vm.selectItem = JSON.parse(JSON.stringify(input));
		},
		setItemClear() {
			var vm = this;
			if (window.innerWidth < 576) {
				if (vm.selectItem != null) {
					vm.selectItem = null;
				} else {
					vm.setWindowToMenu();
				}
			} else {
				vm.selectItem = null;
			}
		},
		checkIsSelectData(input) {
			var vm = this;
			let result = false;
			if (vm.selectItem != null) {
				if (vm.selectItem.UID == input.UID) result = true;
			}
			return result;
		},
		setBackBtn() {
			var vm = this;
			if (window.innerWidth < 576) {
				if (vm.selectItem != null) {
					vm.selectItem = null;
				} else {
					vm.setWindowToMenu();
				}
			} else {
				vm.setWindowToMenu();
			}
		},
		setWindowToMenu() {
			var vm = this;
			vm.$parent.setWindowToMenu(vm);
		},
		showModalLoading() {
			var vm = this;
			vm.$refs.modalLoading.showModal();
		},
		hideModalLoading() {
			var vm = this;
			vm.$refs.modalLoading.hideModal();
		},
		setDataInit() {
			var vm = this;
			setTimeout(() => {
				vm.getQuestList();
			}, 750);
		},
		setWindowClear() {
			var vm = this;
			vm.selectItem = null;
			vm.targetDataList = [];
		},
		getNeedUpdateStatus() {
			var vm = this;
			var result = true;
			if (vm.targetDataList.length == 0) result = false;
			if (vm.$parent.subWindow != "QuestHistory") result = false;
			return result;
		},
		startStatusUpdate() {
			var vm = this;
			var lastCheckList = Date.now();
			var lastCheckTarget = Date.now();
			setInterval(() => {
				if (Date.now() - lastCheckList > 5000) {
					if (vm.getNeedUpdateStatus()) {
						vm.getQuestStatusList();
					}
					lastCheckList = Date.now();
				}
				if (Date.now() - lastCheckTarget > 1000) {
					if (vm.getNeedUpdateStatus()) {
						vm.getQuestStatus();
					}
					lastCheckTarget = Date.now();
				}
			}, 100);
		},
	},
	created() {
		var vm = this;
		vm.startStatusUpdate();
	},
	mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.questiconwrapper {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.questicon {
	position: relative;
}
.statusicon {
	position: absolute;
	right: -12px;
	bottom: -8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 3px solid #ccc;
	font-size: 1.5rem;
	height: 36px;
	width: 36px;
	opacity: 0;
	border-radius: 36px;
	padding-bottom: 1px;
	padding-left: 1px;
}
.statusicon.danger {
	opacity: 1;
	border-color: rgb(220, 53, 69);
}
.statusicon.primary {
	opacity: 1;
	border-color: rgb(13, 110, 253);
}
.statusicon.dark {
	opacity: 1;
	border-color: rgb(33, 37, 41);
}
.statusicon.success {
	opacity: 1;
	border-color: rgb(25, 135, 84);
}
.statusicon > span {
	z-index: 5;
}
.statusicon::after {
	content: "";
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	top: 0;
	background: rgba(255, 255, 255, 0.75);
	backdrop-filter: blur(5px);
	border-radius: 36px;
}
</style>
