<template>
	<div>
		<div class="startupwrapper"></div>
	</div>
</template>
  
<script>
import store from "@/store";
import router from "@/router";
import { showMsg } from "@/utils/msgCtrl";
import { getSystemBase } from "@/api/info";
import { getUserEntity } from "@/utils/userEntity";
export default {
	name: "StartupView",
	components: {},
	methods: {
		getBaseInfo() {
			setTimeout(() => {
				getSystemBase()
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							store.dispatch("baseInfoLoad", data);
							store.dispatch("userEntityLoad", getUserEntity());
							setTimeout(() => {
								this.goToStartBack();
							}, 500);
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "初始化失敗",
								message:
									"連線到伺服器取得初始化資訊時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {},
								},
							],
						});
					});
			}, 300);
		},
		goToStartBack() {
			if (store.state.router.startupBack == null) {
				if (store.state.userEntity == null || !store.state.userEntity.IsLogin) {
					router.push({ name: "login" });
				} else {
					router.push({ name: "home" });
				}
			} else {
				if (store.state.userEntity == null || !store.state.userEntity.IsLogin) {
					router.push({ name: "login" });
				} else {
					let name = store.state.router.startupBack.toName;
					let query = store.state.router.startupBack.query;
					store.dispatch("routerStartupBack", null);
					router.push({
						name: name,
						query: query,
					});
				}
			}
		},
	},
	mounted() {
		this.getBaseInfo();
	},
};
</script>
