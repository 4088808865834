import Vue from "vue";
import Vuex from "vuex";
import { setUserEntity } from "@/utils/userEntity";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		isBGBlur: false,
		isBGZoom: false,
		isBGDim: false,
		isBGZoomQuake: false,
		isAppLogout: false,
		bgZoomQuakeTime: 1500,
		isLoading: false,
		msgList: [],
		baseInfo: null,
		userEntity: null,
		router: {
			startupBack: null,
			loginBack: null,
		},
	},
	getters: {},
	mutations: {
		setBGBlur(state, v) {
			state.isBGBlur = v;
		},
		setBGZoom(state, v) {
			state.isBGZoom = v;
		},
		setBGDim(state, v) {
			state.isBGDim = v;
		},
		setBGZoomQuake(state, v) {
			state.isBGZoomQuake = v;
		},
		setBGZoomQuakeTime(state, v) {
			state.bgZoomQuakeTime = v;
		},
		setLoading(state, v) {
			state.isLoading = v;
		},
		setBaseInfo(state, v) {
			state.baseInfo = v;
		},
		setUserEntity(state, v) {
			state.userEntity = v;
		},
		setRouterStartupBack(state, v) {
			state.router.startupBack = v;
		},
		setRouterLoginBack(state, v) {
			state.router.loginBack = v;
		},
	},
	actions: {
		windowFadeIn(context) {
			context.commit("setBGBlur", true);
			context.commit("setBGZoom", true);
			context.commit("setBGDim", true);
		},
		windowFadeOut(context) {
			context.commit("setBGBlur", false);
			context.commit("setBGZoom", false);
			context.commit("setBGDim", false);
		},
		backgroundZoomQuake(context) {
			context.commit("setBGZoomQuake", true);
			setTimeout(() => {
				context.commit("setBGZoomQuake", false);
			}, 10);
		},
		backgroundZoomQuakeTime(context, input) {
			context.commit("setBGZoomQuakeTime", input);
		},
		progressInfoIn(context) {
			context.commit("setLoading", true);
		},
		progressInfoOut(context) {
			context.commit("setLoading", false);
		},
		baseInfoLoad(context, input) {
			context.commit("setBaseInfo", input);
		},
		userEntityLoad(context, input) {
			setUserEntity(input);
			context.commit("setUserEntity", input);
		},
		routerStartupBack(context, input) {
			context.commit("setRouterStartupBack", input);
		},
		routerLoginBack(context, input) {
			context.commit("setRouterLoginBack", input);
		},
	},
	modules: {},
});
