export function getUserToken() {
	return localStorage.getItem("userToken")
		? JSON.parse(localStorage.getItem("userToken"))
		: { UserToken: null, CreateTime: null };
}

export function setUserToken(userToken) {
	let data = {
		UserToken: userToken,
		CreateTime: new Date().now,
	};
	localStorage.setItem("userToken", JSON.stringify(data));
}

export function setUserTokenClear() {
	localStorage.removeItem("userToken");
}
