export function checkMobile() {
	var mobile = /iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(
		navigator.userAgent.toLowerCase()
	);
	var result = false;

	if (mobile) {
		result = true;
		var userAgent = navigator.userAgent.toLowerCase();
		if (userAgent.search("android") > -1 && userAgent.search("mobile") > -1)
			result = true; // android mobile
		else if (
			userAgent.search("android") > -1 &&
			!(userAgent.search("mobile") > -1)
		)
			result = false; // android tablet
	}
	return result;
}
