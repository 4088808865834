var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"framewrapper",class:[_vm.baseStatus],style:({
		opacity: _vm.baseOpacity,
		transform:
			'scale(' +
			_vm.baseZoom +
			', ' +
			_vm.baseZoom +
			') translate(' +
			_vm.baseMoveX +
			'px,' +
			_vm.baseMoveY +
			'px)',
		width: _vm.baseWidth + 'px',
		height: _vm.baseHeight + 'px',
		top: _vm.baseTop + 'px',
		left: _vm.baseLeft + 'px',
		pointerEvents: _vm.basePointEvent,
		transition: _vm.baseTrans + 's',
		transitionTimingFunction: _vm.baseTiming,
	})},[_c('div',{staticClass:"itemcontent",class:[_vm.itemStatus]},[_c('div',{staticClass:"itemcontentborder"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }