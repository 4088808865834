<template>
	<div class="menuwrapper">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="menutitle">
						<div
							class="menutitlebg"
							:style="{
								opacity: titleOpacity,
								transform:
									'scale(' +
									titleZoom +
									', ' +
									titleZoom +
									') translate(' +
									titleMoveX +
									'px,' +
									titleMoveY +
									'px)',
								pointerEvents: titlePointEvent,
								transition: titleTrans + 's',
								transitionTimingFunction: titleTiming,
							}"
						></div>
						<div
							class="menutitleblur"
							:style="{
								opacity: titleOpacity,
								transform:
									'scale(' +
									titleZoom +
									', ' +
									titleZoom +
									') translate(' +
									titleMoveX +
									'px,' +
									titleMoveY +
									'px)',
								pointerEvents: titlePointEvent,
								transition: titleTrans + 's',
								transitionTimingFunction: titleTiming,
							}"
						></div>
						<div
							:style="{
								opacity: titleOpacity,
								transform:
									'scale(' +
									titleZoom +
									', ' +
									titleZoom +
									') translate(' +
									titleMoveX +
									'px,' +
									titleMoveY +
									'px)',
								pointerEvents: titlePointEvent,
								transition: titleTrans + 's',
								transitionTimingFunction: titleTiming,
							}"
						>
							系統管理
						</div>
					</div>
				</div>
			</div>
			<div
				class="row"
				:style="{
					opacity: menuOpacity,
					transform:
						'scale(' +
						menuZoom +
						', ' +
						menuZoom +
						') translate(' +
						menuMoveX +
						'px,' +
						menuMoveY +
						'px)',
					pointerEvents: menuPointEvent,
					transition: menuTrans + 's',
					transitionTimingFunction: menuTiming,
				}"
			>
				<div class="col-12 p-2 p-sm-0">
					<MenuItem targetname="systemmanager-systemmanager">
						<template v-slot:content
							><div class="itemwrapper">
								<div class="row">
									<div class="col-12 text-center">
										<i class="fa-solid fa-list fa-4x"></i>
									</div>
								</div></div
						></template>
						<template v-slot:light>
							<div class="itemlightwrapper">
								<div class="row">
									<div class="col-12 text-center">
										<i class="fa-solid fa-list fa-4x"></i>
									</div>
									<div class="mt-3 col-12 text-center">
										<div class="itemlight">系統管理</div>
									</div>
								</div>
							</div></template
						></MenuItem
					>
					<MenuItem targetname="systemmanager-systemlog">
						<template v-slot:content
							><div class="itemwrapper">
								<div class="row">
									<div class="col-12 text-center">
										<i class="fa-solid fa-book fa-4x"></i>
									</div>
								</div></div
						></template>
						<template v-slot:light>
							<div class="itemlightwrapper">
								<div class="row">
									<div class="col-12 text-center">
										<i class="fa-solid fa-book fa-4x"></i>
									</div>
									<div class="mt-3 col-12 text-center">
										<div class="itemlight">系統日誌</div>
									</div>
								</div>
							</div></template
						></MenuItem
					>
					<MenuItem targetname="systemmanager-regkey">
						<template v-slot:content
							><div class="itemwrapper">
								<div class="row">
									<div class="col-12 text-center">
										<i class="fa-solid fa-key fa-4x"></i>
									</div>
								</div></div
						></template>
						<template v-slot:light>
							<div class="itemlightwrapper">
								<div class="row">
									<div class="col-12 text-center">
										<i class="fa-solid fa-key fa-4x"></i>
									</div>
									<div class="mt-3 col-12 text-center">
										<div class="itemlight">金鑰管理</div>
									</div>
								</div>
							</div></template
						></MenuItem
					>
					<MenuItem targetname="systemmanager-questprofile">
						<template v-slot:content
							><div class="itemwrapper">
								<div class="row">
									<div class="col-12 text-center">
										<i class="fa-solid fa-code-pull-request fa-4x"></i>
									</div>
								</div></div
						></template>
						<template v-slot:light>
							<div class="itemlightwrapper">
								<div class="row">
									<div class="col-12 text-center">
										<i class="fa-solid fa-code-pull-request fa-4x"></i>
									</div>
									<div class="mt-3 col-12 text-center">
										<div class="itemlight">任務設定檔</div>
									</div>
								</div>
							</div></template
						></MenuItem
					>
					<MenuItem targetname="systemmanager-useraccount">
						<template v-slot:content
							><div class="itemwrapper">
								<div class="row">
									<div class="col-12 text-center">
										<i class="fa-solid fa-users-gear fa-4x"></i>
									</div>
								</div></div
						></template>
						<template v-slot:light>
							<div class="itemlightwrapper">
								<div class="row">
									<div class="col-12 text-center">
										<i class="fa-solid fa-users-gear fa-4x"></i>
									</div>
									<div class="mt-3 col-12 text-center">
										<div class="itemlight">使用者管理</div>
									</div>
								</div>
							</div></template
						></MenuItem
					>
					<MenuItem targetname="systemmanager-usersession">
						<template v-slot:content
							><div class="itemwrapper">
								<div class="row">
									<div class="col-12 text-center">
										<i class="fa-solid fa-bars-progress fa-4x"></i>
									</div>
								</div></div
						></template>
						<template v-slot:light>
							<div class="itemlightwrapper">
								<div class="row">
									<div class="col-12 text-center">
										<i class="fa-solid fa-bars-progress fa-4x"></i>
									</div>
									<div class="mt-3 col-12 text-center">
										<div class="itemlight">執行階段管理</div>
									</div>
								</div>
							</div></template
						></MenuItem
					>
				</div>
			</div>
		</div>
	</div>
</template>
  
<script>
import MenuItem from "@/components/MenuItem.vue";
export default {
	name: "SystemManagerMenu",
	props: {},
	data() {
		return {
			status: "",
			menuMoveX: 0,
			menuMoveY: 0,
			menuZoom: 1,
			menuPointEvent: "none",
			menuOpacity: 1,
			menuTrans: 0.5,
			menuTiming: "",
			titleMoveX: 0,
			titleMoveY: 0,
			titleZoom: 1,
			titlePointEvent: "none",
			titleOpacity: 0,
			titleTrans: 0.5,
			titleTiming: "",
			fadeInTime: 1000,
		};
	},
	components: { MenuItem },
	computed: {},
	watch: {},
	methods: {
		setFadeInItem(input) {
			var vm = this;
			let number = 0;

			var arr = [];
			vm.$children.forEach((item, i) => {
				if (item.$options.name != "MenuItem") return;
				if (input != null) if (input.UID == item.UID) return;
				arr.push(i);
			});
			arr.sort(function () {
				return 0.5 - Math.random();
			});
			arr.forEach((index) => {
				let item = vm.$children[index];
				setTimeout(() => {
					item.setItemFadeIn();
				}, 100 * number);
				number++;
			});

			let totaltime = number * 100 + 150;
			vm.fadeInTime = totaltime;

			vm.menuTrans = 0;
			vm.menuOpacity = 1;

			setTimeout(() => {
				vm.menuZoom = 0.9;
				vm.menuTrans = totaltime / 1000;
				vm.menuOpacity = 1;
				vm.menuTiming = "ease-in";
				setTimeout(() => {
					vm.menuZoom = 1;
					vm.menuTrans = 0.45;
					vm.menuTiming = "ease-out";
					vm.menuPointEvent = "all";
				}, totaltime);

				vm.titleTrans = 0;
				vm.titleZoom = 0.9;
				vm.titleOpacity = 0;
				setTimeout(() => {
					vm.titleZoom = 1;
					vm.titleOpacity = 1;
					vm.titleTrans = 0.65;
					vm.titleTiming = "ease-in-out";
					vm.titlePointEvent = "all";
				}, totaltime + 150);
			}, 10);
		},
		setFadeOutItem(input) {
			var vm = this;
			let number = 0;

			vm.menuPointEvent = "none";

			var arr = [];
			vm.$children.forEach((item, i) => {
				if (item.$options.name != "MenuItem") return;
				if (input.UID == item.UID) return;
				arr.push(i);
			});
			arr.sort(function () {
				return 0.5 - Math.random();
			});
			arr.forEach((index) => {
				let item = vm.$children[index];
				setTimeout(() => {
					item.setItemFadeOut();
				}, 15 * number);
				number++;
			});
			let totaltime = number * 15 + 250;
			vm.fadeInTime = totaltime;

			vm.menuZoom = 0.9;
			vm.menuTrans = totaltime / 1000;

			setTimeout(() => {
				vm.menuTrans = 0;
				vm.menuOpacity = 0;
			}, totaltime);

			setTimeout(() => {
				vm.titleZoom = 0.9;
				vm.titleOpacity = 0;
				vm.titleTrans = 0.25;
				vm.titleTiming = "ease-in-out";
				vm.titlePointEvent = "none";
			}, totaltime + 150);
		},
		setItemLight() {
			var vm = this;
			/* 
			vm.$children.forEach((item) => {
				if (item.$options.name != "MenuItem") return;
				if (item == input) return;
				item.setItemZoomOut();
			}); */
			vm.menuTrans = 0.35;
			vm.menuZoom = 0.9;
		},
		setItemLightOut() {
			var vm = this;

			vm.menuTrans = 0.75;
			vm.menuZoom = 1;
		},
		setMenuToWindow(input) {
			var vm = this;
			vm.$parent.setMenuToWindow(input);
		},
		getMenuItemByWindow(input) {
			var vm = this;
			let result = null;
			vm.$children.forEach((item) => {
				if (input.windowName == item.targetname) {
					result = item;
				}
			});
			return result;
		},
	},
	mounted() {},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.menuwrapper {
	margin-bottom: 32px;
}
.itemwrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.itemlightwrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.itemlight {
	background: rgba(50, 50, 255, 0.85);
	border-radius: 32px;
	padding: 3px 16px 4px 16px;
	color: white;
	display: inline-block;
}
.menutitle {
	position: relative;
	border-radius: 16px;
	transition: 0.35s;
	font-size: 1.5rem;
	padding: 6px 12px 0 12px;
}
.menutitlebg {
	content: "";
	transition: 0.35s;
	position: absolute;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	top: 0;
	left: 0;
	right: 0;
	bottom: -48px;
	opacity: 1;
	background: rgba(240, 240, 240, 0.5);
	mask-image: linear-gradient(
		0deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.5) 30%,
		rgba(255, 255, 255, 0.7) 60%,
		rgba(255, 255, 255, 1) 100%
	);
	z-index: -1;
}
.menutitleblur {
	content: "";
	transition: 0.35s;
	position: absolute;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	top: 0;
	left: 0;
	right: 0;
	bottom: -48px;
	opacity: 1;
	mask-image: linear-gradient(
		0deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.5) 30%,
		rgba(255, 255, 255, 1) 60%,
		rgba(255, 255, 255, 1) 100%
	);
	backdrop-filter: blur(5px) saturate(1.5);
	z-index: -2;
}
</style>