import { render, staticRenderFns } from "./SystemManagerMenu.vue?vue&type=template&id=dca60942&scoped=true&"
import script from "./SystemManagerMenu.vue?vue&type=script&lang=js&"
export * from "./SystemManagerMenu.vue?vue&type=script&lang=js&"
import style0 from "./SystemManagerMenu.vue?vue&type=style&index=0&id=dca60942&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dca60942",
  null
  
)

export default component.exports