import axios from "axios";
import { getUserToken } from "@/utils/userToken";
import store from "../store";

var axiosRequstCount = 0;

const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	headers: {
		"X-Requested-With": "XMLHttpRequest",
		"Cache-Control": "no-cache",
		pragma: "no-cache",
		post: {
			"Content-Type": "application/json;charset=UTF-8",
		},
	},
});

service.interceptors.request.use(
	(config) => {
		let useSpin = true;
		axiosRequstCount++;
		if (config.url.indexOf("api/Info/GetUserEntity") >= 0) useSpin = false;
		if (config.url.indexOf("api/Quest/GetQuestStatusList") >= 0)
			useSpin = false;
		if (config.url.indexOf("api/Quest/GetQuestStatus") >= 0) useSpin = false;
		if (useSpin) {
			store.dispatch("progressInfoIn");
		}
		config.headers["SessionToken"] = getUserToken().UserToken;
		return config;
	},
	(error) => {
		console.log(error);
		return Promise.reject(error);
	}
);

service.interceptors.response.use(
	(response) => {
		axiosRequstCount--;
		if (axiosRequstCount == 0) {
			store.dispatch("progressInfoOut");
		}
		const res = response.data;
		return res;
	},
	(error) => {
		axiosRequstCount--;
		if (axiosRequstCount == 0) {
			store.dispatch("progressInfoOut");
		}
		console.log("axios client error :" + error);
		return Promise.reject(error);
	}
);

export default service;
