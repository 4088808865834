var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menuwrapper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"menutitle"},[_c('div',{staticClass:"menutitlebg",style:({
							opacity: _vm.titleOpacity,
							transform:
								'scale(' +
								_vm.titleZoom +
								', ' +
								_vm.titleZoom +
								') translate(' +
								_vm.titleMoveX +
								'px,' +
								_vm.titleMoveY +
								'px)',
							pointerEvents: _vm.titlePointEvent,
							transition: _vm.titleTrans + 's',
							transitionTimingFunction: _vm.titleTiming,
						})}),_c('div',{staticClass:"menutitleblur",style:({
							opacity: _vm.titleOpacity,
							transform:
								'scale(' +
								_vm.titleZoom +
								', ' +
								_vm.titleZoom +
								') translate(' +
								_vm.titleMoveX +
								'px,' +
								_vm.titleMoveY +
								'px)',
							pointerEvents: _vm.titlePointEvent,
							transition: _vm.titleTrans + 's',
							transitionTimingFunction: _vm.titleTiming,
						})}),_c('div',{style:({
							opacity: _vm.titleOpacity,
							transform:
								'scale(' +
								_vm.titleZoom +
								', ' +
								_vm.titleZoom +
								') translate(' +
								_vm.titleMoveX +
								'px,' +
								_vm.titleMoveY +
								'px)',
							pointerEvents: _vm.titlePointEvent,
							transition: _vm.titleTrans + 's',
							transitionTimingFunction: _vm.titleTiming,
						})},[_vm._v(" Project Shadow 管理 ")])])])]),_c('div',{staticClass:"row",style:({
				opacity: _vm.menuOpacity,
				transform:
					'scale(' +
					_vm.menuZoom +
					', ' +
					_vm.menuZoom +
					') translate(' +
					_vm.menuMoveX +
					'px,' +
					_vm.menuMoveY +
					'px)',
				pointerEvents: _vm.menuPointEvent,
				transition: _vm.menuTrans + 's',
				transitionTimingFunction: _vm.menuTiming,
			})},[_c('div',{staticClass:"col-12 p-2 p-sm-0"},[_c('MenuItem',{attrs:{"targetname":"shadowmanager-questmanager"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"itemwrapper"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center"},[_c('i',{staticClass:"fa-solid fa-list-check fa-4x"})])])])]},proxy:true},{key:"light",fn:function(){return [_c('div',{staticClass:"itemlightwrapper"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center"},[_c('i',{staticClass:"fa-solid fa-list-check fa-4x"})]),_c('div',{staticClass:"mt-3 col-12 text-center"},[_c('div',{staticClass:"itemlight"},[_vm._v("任務管理")])])])])]},proxy:true}])})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }