<template>
	<div>
		<div class="viewwrapper">
			<div class="viewcontent">
				<ShadowManagerMenu ref="shadowmanagermenu"></ShadowManagerMenu>
				<SystemManagerMenu ref="systemmanagermenu"></SystemManagerMenu>
			</div>
		</div>
		<MenuToWindow ref="menutowindow"></MenuToWindow>

		<WindowFrame ref="winframe_systemmanager_useraccount">
			<WindowContent
				ref="wincontent_systemmanager_useraccount"
				padding="nopadding"
			>
				<UserAccount ref="systemmanager_useraccount" />
			</WindowContent>
		</WindowFrame>
		<WindowFrame ref="winframe_systemmanager_regkey">
			<WindowContent ref="wincontent_systemmanager_regkey" padding="nopadding">
				<RegKey ref="systemmanager_regkey" />
			</WindowContent>
		</WindowFrame>
		<WindowFrame ref="winframe_systemmanager_usersession">
			<WindowContent
				ref="wincontent_systemmanager_usersession"
				padding="nopadding"
			>
				<UserSession ref="systemmanager_usersession" />
			</WindowContent>
		</WindowFrame>
		<WindowFrame ref="winframe_systemmanager_systemlog">
			<WindowContent
				ref="wincontent_systemmanager_systemlog"
				padding="nopadding"
			>
				<SystemLog ref="systemmanager_systemlog" />
			</WindowContent>
		</WindowFrame>
		<WindowFrame ref="winframe_systemmanager_systemmanager">
			<WindowContent
				ref="wincontent_systemmanager_systemmanager"
				padding="nopadding"
			>
				<SystemManager ref="systemmanager_systemmanager" />
			</WindowContent>
		</WindowFrame>
		<WindowFrame ref="winframe_systemmanager_questprofile">
			<WindowContent
				ref="wincontent_systemmanager_questprofile"
				padding="nopadding"
			>
				<QuestProfile ref="systemmanager_questprofile" />
			</WindowContent>
		</WindowFrame>
		<WindowFrame ref="winframe_shadowmanager_questmanager">
			<WindowContent
				ref="wincontent_shadowmanager_questmanager"
				padding="nopadding"
			>
				<QuestManager ref="shadowmanager_questmanager" />
			</WindowContent>
		</WindowFrame>
	</div>
</template>

<script>
import SystemManagerMenu from "@/components/Menu/SystemManagerMenu.vue";
import ShadowManagerMenu from "@/components/Menu/ShadowManagerMenu.vue";
import MenuToWindow from "@/components/MenuToWindow.vue";
import WindowFrame from "@/components/WindowFrame.vue";
import WindowContent from "@/components/WindowContent.vue";
import store from "@/store";
import Scrollbar from "smooth-scrollbar";
import UserAccount from "@/components/SystemManager/UserAccount.vue";
import RegKey from "@/components/SystemManager/RegKey.vue";
import UserSession from "@/components/SystemManager/UserSession.vue";
import SystemLog from "@/components/SystemManager/SystemLog.vue";
import SystemManager from "@/components/SystemManager/SystemManager.vue";
import QuestProfile from "@/components/SystemManager/QuestProfile.vue";
import QuestManager from "@/components/ShadowManager/QuestManager.vue";
import { checkMobile } from "@/utils/checkMobile";
export default {
	name: "HomeView",
	data() {
		return {
			targetWindowName: null,
		};
	},
	components: {
		SystemManagerMenu,
		ShadowManagerMenu,
		MenuToWindow,
		WindowFrame,
		WindowContent,
		UserAccount,
		UserSession,
		RegKey,
		SystemLog,
		SystemManager,
		QuestProfile,
		QuestManager,
	},
	methods: {
		setMenuFadeIn(input) {
			var vm = this;
			vm.$refs.systemmanagermenu.setFadeInItem(input);
			vm.$refs.shadowmanagermenu.setFadeInItem(input);
			if (input == null) {
				let fadeInTime = vm.$refs.systemmanagermenu.fadeInTime;
				store.dispatch("backgroundZoomQuakeTime", fadeInTime);
				store.dispatch("backgroundZoomQuake");
			}
		},
		setMenuAutoToWindow() {
			var vm = this;
			if (vm.$route.query.window != null) {
				let windowName = vm.$route.query.window;
				let menuItem = null;
				if (menuItem == null)
					menuItem = vm.$refs.systemmanagermenu.getMenuItemByWindow({
						windowName: windowName,
					});
				if (menuItem == null)
					menuItem = vm.$refs.shadowmanagermenu.getMenuItemByWindow({
						windowName: windowName,
					});
				if (menuItem != null) {
					menuItem.setMouseClick();
				}
			}
		},
		setMenuToWindow(input) {
			var vm = this;
			let frame = null;
			let content = null;
			let targetwindow = null;

			if (input.targetname == "systemmanager-useraccount") {
				frame = vm.$refs.winframe_systemmanager_useraccount;
				content = vm.$refs.wincontent_systemmanager_useraccount;
				targetwindow = vm.$refs.systemmanager_useraccount;
			}
			if (input.targetname == "systemmanager-regkey") {
				frame = vm.$refs.winframe_systemmanager_regkey;
				content = vm.$refs.wincontent_systemmanager_regkey;
				targetwindow = vm.$refs.systemmanager_regkey;
			}
			if (input.targetname == "systemmanager-usersession") {
				frame = vm.$refs.winframe_systemmanager_usersession;
				content = vm.$refs.wincontent_systemmanager_usersession;
				targetwindow = vm.$refs.systemmanager_usersession;
			}
			if (input.targetname == "systemmanager-systemlog") {
				frame = vm.$refs.winframe_systemmanager_systemlog;
				content = vm.$refs.wincontent_systemmanager_systemlog;
				targetwindow = vm.$refs.systemmanager_systemlog;
			}
			if (input.targetname == "systemmanager-systemmanager") {
				frame = vm.$refs.winframe_systemmanager_systemmanager;
				content = vm.$refs.wincontent_systemmanager_systemmanager;
				targetwindow = vm.$refs.systemmanager_systemmanager;
			}
			if (input.targetname == "systemmanager-questprofile") {
				frame = vm.$refs.winframe_systemmanager_questprofile;
				content = vm.$refs.wincontent_systemmanager_questprofile;
				targetwindow = vm.$refs.systemmanager_questprofile;
			}
			if (input.targetname == "shadowmanager-questmanager") {
				frame = vm.$refs.winframe_shadowmanager_questmanager;
				content = vm.$refs.wincontent_shadowmanager_questmanager;
				targetwindow = vm.$refs.shadowmanager_questmanager;
			}

			if (vm.$route.query.window != input.targetname) {
				vm.$router.push({
					path: vm.$route.path,
					query: { ...vm.$route.query, window: input.targetname },
				});
			}
			vm.$refs.systemmanagermenu.setFadeOutItem(input);
			vm.$refs.shadowmanagermenu.setFadeOutItem(input);
			vm.$refs.menutowindow.setMenuToWindow(input, frame, content);
			if (targetwindow != null)
				if (targetwindow.setDataInit != null) targetwindow.setDataInit();
		},
		setWindowToMenu(input) {
			var vm = this;
			let frame = null;
			let content = null;

			if (input.windowName == "systemmanager-useraccount") {
				frame = vm.$refs.winframe_systemmanager_useraccount;
				content = vm.$refs.wincontent_systemmanager_useraccount;
			}

			if (input.windowName == "systemmanager-regkey") {
				frame = vm.$refs.winframe_systemmanager_regkey;
				content = vm.$refs.wincontent_systemmanager_regkey;
			}

			if (input.windowName == "systemmanager-usersession") {
				frame = vm.$refs.winframe_systemmanager_usersession;
				content = vm.$refs.wincontent_systemmanager_usersession;
			}
			if (input.windowName == "systemmanager-systemlog") {
				frame = vm.$refs.winframe_systemmanager_systemlog;
				content = vm.$refs.wincontent_systemmanager_systemlog;
			}
			if (input.windowName == "systemmanager-systemmanager") {
				frame = vm.$refs.winframe_systemmanager_systemmanager;
				content = vm.$refs.wincontent_systemmanager_systemmanager;
			}
			if (input.windowName == "systemmanager-questprofile") {
				frame = vm.$refs.winframe_systemmanager_questprofile;
				content = vm.$refs.wincontent_systemmanager_questprofile;
			}
			if (input.windowName == "shadowmanager-questmanager") {
				frame = vm.$refs.winframe_shadowmanager_questmanager;
				content = vm.$refs.wincontent_shadowmanager_questmanager;
			}

			vm.$router.push({
				path: vm.$route.path,
				query: { ...vm.$route.query, window: null },
			});

			let menuItem = null;
			if (menuItem == null)
				menuItem = vm.$refs.systemmanagermenu.getMenuItemByWindow(input);
			if (menuItem == null)
				menuItem = vm.$refs.shadowmanagermenu.getMenuItemByWindow(input);
			vm.$refs.menutowindow.setWindowToMenu(
				input,
				frame,
				content,
				menuItem,
				vm
			);
		},
	},
	mounted() {
		var vm = this;
		setTimeout(() => {
			vm.setMenuFadeIn();
		}, 500);
		setTimeout(() => {
			vm.setMenuAutoToWindow();
		}, 1350);
		if (!checkMobile()) {
			var scrollbar = Scrollbar.init(document.querySelector(".viewwrapper"));
			scrollbar.track.xAxis.element.remove();
		}
	},
};
</script>
<style lang="less" scoped>
.viewwrapper {
	overflow: auto;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100vw;
}
.viewcontent {
	margin-top: 48px;
}
</style>