var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contentwrapper",class:[_vm.width, _vm.otherclass]},[(_vm.topbar)?_c('div',{staticClass:"topbarwrapper",style:({
			top: _vm.topBarPosition + 'px',
		})},[_vm._t("topbar")],2):_vm._e(),_c('div',{staticClass:"list",style:({
			paddingTop: _vm.listPaddingTop + 'px',
			paddingBottom: _vm.listPaddingBottom + 'px',
		})},[_c('div',{staticClass:"listcontent"},[_vm._t("default")],2)]),(_vm.bottombar)?_c('div',{staticClass:"bottombarwrapper",style:({
			bottom: _vm.bottomBarPosition + 'px',
		})},[_vm._t("bottombar")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }