<template>
	<div
		class="framewrapper"
		:class="[baseStatus]"
		:style="{
			opacity: baseOpacity,
			transform:
				'scale(' +
				baseZoom +
				', ' +
				baseZoom +
				') translate(' +
				baseMoveX +
				'px,' +
				baseMoveY +
				'px)',
			width: baseWidth + 'px',
			height: baseHeight + 'px',
			top: baseTop + 'px',
			left: baseLeft + 'px',
			pointerEvents: basePointEvent,
			transition: baseTrans + 's',
			transitionTimingFunction: baseTiming,
		}"
	>
		<div class="itemcontent" :class="[itemStatus]">
			<div class="itemcontentborder"></div>
		</div>
	</div>
</template>
  
<script>
import store from "@/store";
export default {
	name: "MenuToWindow",
	props: {},
	data() {
		return {
			baseStatus: "",
			baseMoveX: 0,
			baseMoveY: 0,
			baseTop: 0,
			baseLeft: 0,
			baseZoom: 1,
			basePointEvent: "none",
			baseOpacity: 0,
			baseWidth: 1,
			baseHeight: 1,
			baseTrans: 0,
			baseTiming: "",
			itemStatus: "",
		};
	},
	computed: {},
	watch: {},
	methods: {
		setMenuToWindow(input, target, content) {
			var vm = this;
			if (input == null || target == null || content == null) return;

			let sourceRect = input.$refs.itemmain.getBoundingClientRect();
			vm.baseTrans = 0;
			vm.baseOpacity = 1;
			vm.baseLeft = sourceRect.left;
			vm.baseTop = sourceRect.top;
			vm.baseWidth = sourceRect.width;
			vm.baseHeight = sourceRect.height;

			let delay = 50;
			setTimeout(() => {
				vm.baseTrans = 0.25;
				vm.baseZoom = 0.8;
			}, delay);

			target.setWindowSize(content, false);
			content.showContent();
			target.setWindowShowFromMenu();
			setTimeout(() => {
				let targetRect = target.$refs.frame.getBoundingClientRect();
				vm.baseTrans = 0.35;
				vm.baseLeft = targetRect.left;
				vm.baseTop = targetRect.top;
				vm.baseWidth = targetRect.width;
				vm.baseHeight = targetRect.height;
				vm.baseZoom = 1.05;
				vm.baseTiming = "ease-in";
				vm.itemStatus = "window";
			}, 250 + delay);
			setTimeout(() => {
				vm.baseTrans = 0.25;
				vm.baseZoom = 1;
				vm.baseTiming = "ease-out";
			}, 650 + delay);
			setTimeout(() => {
				target.setContentShow(false);
			}, 700 + delay);
			setTimeout(() => {
				target.setWindowShowForMenuAfter();
				vm.baseTrans = 0;
				vm.baseOpacity = 0;
			}, 900 + delay);
		},
		setWindowToMenu(input, target, content, menuItem, view) {
			var vm = this;
			if (input == null || target == null || content == null) return;

			let sourceRect = target.$refs.frame.getBoundingClientRect();
			vm.baseTrans = 0;
			vm.baseOpacity = 0;
			vm.baseLeft = sourceRect.left;
			vm.baseTop = sourceRect.top;
			vm.baseWidth = sourceRect.width;
			vm.baseHeight = sourceRect.height;
			vm.itemStatus = "window";

			target.setContentHidden(false);
			setTimeout(() => {
				content.hideContent();
				target.setWindowHiddenFromMenu();
				vm.baseOpacity = 1;

				let delay = 50;
				setTimeout(() => {
					vm.baseTrans = 0.25;
					vm.baseZoom = 1.05;
					vm.baseTiming = "ease-in";
					vm.itemStatus = "";
				}, delay);
				if (target.isBGZoom) {
					setTimeout(() => {
						store.dispatch("windowFadeOut");
					}, 150);
				}
				setTimeout(() => {
					let targetRect = menuItem.$refs.itemmain.getBoundingClientRect();
					vm.baseTrans = 0.25;
					vm.baseZoom = 0.9;
					vm.baseLeft = targetRect.left;
					vm.baseTop = targetRect.top;
					vm.baseWidth = targetRect.width;
					vm.baseHeight = targetRect.height;
					vm.baseTiming = "ease-in";
					vm.itemStatus = "";
				}, 250 + delay);
				setTimeout(() => {
					vm.baseTrans = 0.25;
					vm.baseZoom = 1;
					vm.baseTiming = "ease-out";
					let targetRect = menuItem.$refs.itemmain.getBoundingClientRect();
					vm.baseLeft = targetRect.left;
					vm.baseTop = targetRect.top;
					vm.baseWidth = targetRect.width;
					vm.baseHeight = targetRect.height;
				}, 500 + delay);
				setTimeout(() => {
					view.setMenuFadeIn(menuItem);
				}, 250 + delay);
				setTimeout(() => {
					menuItem.contentStatus = "hide";
					setTimeout(() => {
						vm.baseTrans = 0;
						vm.baseOpacity = 0;
						menuItem.setItemShow();
					}, 150);
				}, 750);
			}, 350);
		},
	},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.framewrapper {
	position: fixed;
	top: 0;
	left: 0;
	opacity: 1;
	height: 128px;
	width: 128px;
	transition: 0.45s;
	pointer-events: none;
	padding: 0;
	transition: 0.35s;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 500;
}
.framewrapper.hide {
	opacity: 0;
}
.framewrapper.show {
	opacity: 1;
}
.itemcontent {
	position: relative;
	height: 100%;
	width: 100%;
	background: rgba(255, 255, 255, 0.45);
	backdrop-filter: blur(12px) saturate(1.5);
	border-radius: 16px;
	box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15);
	transition: 0.35s;
}
.itemcontent.window {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.25);
}
.itemcontentborder {
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
	border: 1px solid rgba(100, 100, 100, 0.25);
	border-radius: 16px;
}
</style>