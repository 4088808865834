var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainwrapper",class:[_vm.mainalign, _vm.mainClass, _vm.mainStatus]},[_c('div',{staticClass:"clickwrapper",class:[_vm.clickStatus],style:({
			top: _vm.backTop + 'px',
			left: _vm.backLeft + 'px',
		}),on:{"click":_vm.clickBack}}),_c('div',{ref:"buttonBase",staticClass:"buttonwrapper",class:[_vm.buttonalign, _vm.buttonStatus],on:{"click":_vm.clickBtn,"mouseenter":_vm.btnMouseIn,"mouseout":_vm.btnMouseOut}},[_vm._t("btn")],2),_c('div',{ref:"contentBase",staticClass:"contentwrapper",class:[_vm.contentalign, _vm.contentStatus]},[_vm._t("content")],2),_c('div',{staticClass:"mainbase",style:({
			width: _vm.baseWidth + 'px',
			height: _vm.baseHeight + 'px',
			transition: _vm.baseTrans + 's',
			transitionTimingFunction: _vm.baseTiming,
		})})])
}
var staticRenderFns = []

export { render, staticRenderFns }