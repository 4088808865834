<template>
	<div class="windowwrapper">
		<TopBar>
			<div class="row">
				<div class="col-12 text-center">
					<div class="btn-group" role="group">
						<button
							class="btn btn-outline-primary"
							v-for="item in subWindowList"
							:class="{ active: subWindow == item.ID }"
							@click="subWindow = item.ID"
							:key="item.ID"
						>
							{{ item.Name }}
						</button>
					</div>
				</div>
			</div>
		</TopBar>
		<QuestProfile
			v-show="subWindow == 'QuestProfile'"
			ref="questprofile"
		></QuestProfile>
		<QuestHistory
			v-show="subWindow == 'QuestHistory'"
			ref="questhistory"
		></QuestHistory>
	</div>
</template>

<script>
import TopBar from "@/components/WindowUI/TopBar.vue";
import QuestProfile from "@/components/ShadowManager/QuestManager/QuestProfile.vue";
import QuestHistory from "@/components/ShadowManager/QuestManager/QuestHistory.vue";
import moment from "moment";
export default {
	name: "QuestManager",
	props: {},
	data() {
		return {
			windowName: "shadowmanager-questmanager",
			subWindow: "QuestHistory",
			subWindowList: [
				{ ID: "QuestProfile", Name: "執行任務" },
				{ ID: "QuestHistory", Name: "任務歷史" },
			],
		};
	},
	components: {
		TopBar,
		QuestProfile,
		QuestHistory,
	},
	computed: {},
	filters: {
		momentDate: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日");
		},
		moment: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日 HH:mm:ss");
		},
		commaFormat: function (value) {
			return value
				.toString()
				.replace(
					/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/,
					function (all, pre, groupOf3Digital) {
						return pre + groupOf3Digital.replace(/\d{3}/g, ",$&");
					}
				);
		},
	},
	watch: {},
	methods: {
		setWindowToMenu() {
			var vm = this;
			vm.$parent.$parent.$parent.setWindowToMenu(vm);
			setTimeout(() => {
				vm.$refs.questprofile.setWindowClear();
				vm.$refs.questhistory.setWindowClear();
			}, 750);
		},
		setDataInit() {
			var vm = this;
			if (vm.$refs.questprofile.setDataInit != null)
				vm.$refs.questprofile.setDataInit();
			if (vm.$refs.questhistory.setDataInit != null)
				vm.$refs.questhistory.setDataInit();
		},
	},
	mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
