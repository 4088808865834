import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import _ from "lodash";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/bootstrap-custom.css";

Vue.config.productionTip = false;
new Vue({
	router,
	store,
	_,
	render: (h) => h(App),
}).$mount("#app");
