<template>
	<div>
		<WindowFrame ref="windowframe">
			<WindowContent ref="windowlogin"
				><div class="loginwrapper">
					<div class="row">
						<div class="col-12 text-center">
							<img src="../assets/logo.png" width="96px" />
						</div>
					</div>
					<div class="row">
						<div class="col-12 text-center">
							<h3><strong>小艾版補丁</strong></h3>
							<p>Ver7</p>
						</div>
					</div>
					<div class="row">
						<div class="col-12 text-center">
							<h4><strong>Ver7</strong></h4>
						</div>
					</div>
					<div class="row mt-2">
						<div class="col-2"></div>
						<div class="col-8">
							<input
								type="email"
								class="form-control"
								v-model="email"
								placeholder="從輸入 Email 開始"
								@keyup.enter="loginUser"
							/>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-12 text-center">
							<button class="btn btn-primary" @click="loginUser">
								開始 <i class="fa-solid fa-arrow-right"></i>
							</button>
						</div>
					</div>
					<div class="row mt-4">
						<div class="col-12 ps-4 pe-4 text-center">
							<small
								>Copyright © 2011~{{ new Date().getFullYear() }} By 小艾</small
							>
						</div>
					</div>
				</div></WindowContent
			>
			<WindowContent ref="windowloading"
				><div class="loadingwrapper">
					<div class="row">
						<div class="col-12 text-center">{{ loadText }}</div>
						<div class="col-12">
							<LoadSpin></LoadSpin>
						</div>
					</div></div
			></WindowContent>
			<WindowContent ref="windowactive"
				><div class="activewrapper">
					<div class="row">
						<div class="col-12 text-center">
							<img src="../assets/logo.png" width="96px" />
						</div>
					</div>
					<div class="row">
						<div class="col-12 text-center">
							<h3><strong>小艾版補丁</strong></h3>
							<p>Ver7</p>
						</div>
					</div>
					<div class="row">
						<div class="col-12 text-center">
							<h4 v-if="loginResult != null">
								<strong>歡迎回來 {{ loginResult.DisplayName }}</strong>
							</h4>
							<h4 v-if="loginResult == null">
								<strong>歡迎回來</strong>
							</h4>
							<p>系統已經發送一封登入驗證信到您的信箱</p>
						</div>
					</div>
					<div class="row mt-4">
						<div class="col-12 ps-4 pe-4 text-center">
							<small
								>Copyright © 2011~{{ new Date().getFullYear() }} By 小艾</small
							>
						</div>
					</div>
				</div></WindowContent
			>
			<WindowContent ref="windowfinish"
				><div class="windowfinishwrapper">
					<div class="row">
						<div class="col-12 text-center">
							<img src="../assets/logo.png" width="96px" />
						</div>
					</div>
					<div class="row">
						<div class="col-12 text-center">
							<h3><strong>小艾版補丁</strong></h3>
							<p>Ver7</p>
						</div>
					</div>
					<div class="row">
						<div class="col-12 text-center text-success">
							<i class="fas fa-check-circle fa-5x"></i>
						</div>
						<div class="col-12 text-center mt-3">
							<h4 v-if="userEntity != null">
								<strong>歡迎回來 {{ userEntity.DisplayName }}</strong>
							</h4>
							<h4 v-if="userEntity == null">
								<strong>歡迎回來</strong>
							</h4>
							<p>您已經成功登入</p>
						</div>
					</div>
					<div class="row mt-4">
						<div class="col-12 ps-4 pe-4 text-center">
							<small
								>Copyright © 2011~{{ new Date().getFullYear() }} By 小艾</small
							>
						</div>
					</div>
				</div></WindowContent
			>
			<WindowContent ref="windowregister"
				><div class="registerwrapper">
					<div class="row">
						<div class="col-12 text-center">
							<img src="../assets/logo.png" width="96px" />
						</div>
					</div>
					<div class="row">
						<div class="col-12 text-center">
							<h3><strong>小艾版補丁</strong></h3>
							<p>Ver7</p>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<div class="row">
								<div class="col-12 text-center">
									<h6><strong>初次註冊</strong></h6>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<small>Email</small><br />
									<input
										type="text"
										class="form-control"
										disabled
										v-model="email"
										placeholder="Email"
									/>
								</div>
							</div>
							<div class="row">
								<div class="col-6">
									<small>使用者帳號</small><br />
									<input
										type="text"
										class="form-control"
										@keyup.enter="registerUser"
										v-model="userName"
										placeholder=""
									/>
								</div>
								<div class="col-6">
									<small>暱稱</small><br />
									<input
										type="text"
										class="form-control"
										@keyup.enter="registerUser"
										v-model="displayName"
										placeholder=""
									/>
								</div>
							</div>
							<div class="row" v-if="!baseInfo.IsFreeReg">
								<div class="col-12">
									<small>邀請碼</small><br />
									<input
										type="text"
										class="form-control"
										@keyup.enter="registerUser"
										v-model="regKey"
										placeholder="xxxx-xxxx-xxxx-xxxx"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-4">
							<button class="btn btn-light" @click="gotoLogin">
								<i class="fa-solid fa-arrow-left"></i> 返回
							</button>
						</div>
						<div class="col-8 text-end">
							<button class="btn btn-success" @click="registerUser">
								進行註冊 <i class="fa-solid fa-arrow-right"></i>
							</button>
						</div>
					</div>
					<div class="row mt-4">
						<div class="col-12 ps-4 pe-4 text-center">
							<small
								>Copyright © 2011~{{ new Date().getFullYear() }} By 小艾</small
							>
						</div>
					</div>
				</div></WindowContent
			>
		</WindowFrame>
	</div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import WindowFrame from "@/components/WindowFrame.vue";
import { setUserToken } from "@/utils/userToken";
import WindowContent from "@/components/WindowContent.vue";
import LoadSpin from "@/components/LoadSpin.vue";
import { showMsg } from "@/utils/msgCtrl";
import { getUserEntity } from "@/api/info";
import { loginUser, registerUser } from "@/api/user";
export default {
	name: "LoginView",
	data() {
		return {
			email: "",
			userName: "",
			displayName: "",
			regKey: "",
			loginResult: null,
			loadText: "正在嘗試使用 Email 帳號登入",
		};
	},
	components: { WindowFrame, WindowContent, LoadSpin },
	computed: {
		baseInfo: {
			get() {
				return store.state.baseInfo;
			},
		},
		userEntity: {
			get() {
				return store.state.userEntity;
			},
		},
	},
	methods: {
		checkUserEntity() {
			var vm = this;
			getUserEntity()
				.then((res) => {
					if (res.Success) {
						let data = res.Data[0];
						store.dispatch("userEntityLoad", data);
						setTimeout(() => {
							vm.goToRouter();
						}, 500);
					} else {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: res.Title,
								message: res.Message,
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {},
								},
							],
						});
					}
				})
				.catch((error) => {
					showMsg({
						icon: {
							type: "Error",
							custom: {
								icon: "",
								color: "",
							},
						},
						msg: {
							title: "取得資料失敗",
							message:
								"連線到伺服器取得使用者資訊時發生錯誤:" +
								JSON.stringify(error, null, "\t"),
						},
						btn: [
							{
								text: "確定",
								type: "btn-primary",
								method: function () {},
							},
						],
					});
				});
		},
		loginUser() {
			var vm = this;
			let mailRegex =
				/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
			if (!mailRegex.test(vm.email)) {
				showMsg({
					icon: {
						type: "Alert",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "登入失敗",
						message: "輸入的 Email 格式不正確，或是不在許可的範圍內",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {
								vm.email = "";
							},
						},
					],
				});
				return;
			}

			vm.changeWindowContent(vm.$refs.windowloading);
			vm.loadText = "正在嘗試使用 Email 帳號登入";

			setTimeout(() => {
				loginUser({ Email: vm.email })
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.loginResult = data;
							vm.gotoLoginResult();
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.email = "";
											vm.changeWindowContent(vm.$refs.windowlogin);
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "登入連線失敗",
								message:
									"連線到伺服器進行登入時時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.changeWindowContent(vm.$refs.windowlogin);
									},
								},
							],
						});
					})
					.finally(() => {});
			}, 500);
		},
		registerUser() {
			var vm = this;
			if (!vm.baseInfo.IsFreeReg && (vm.regKey == "" || vm.regKey == null)) {
				showMsg({
					icon: {
						type: "Alert",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "邀請碼為必填項目",
						message: "小艾版補丁目前為邀請註冊機制，請填寫邀請碼才能進行註冊。",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {},
						},
					],
				});
				return;
			}
			let regKeyRegex =
				/[a-zA-z0-9]{4}-[a-zA-z0-9]{4}-[a-zA-z0-9]{4}-[a-zA-z0-9]{4}/g;
			if (!regKeyRegex.test(vm.regKey)) {
				showMsg({
					icon: {
						type: "Alert",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "邀請碼格式錯誤",
						message: "邀請碼格式為16碼英文數字，請確認輸入的邀請碼是否正確。",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {
								vm.regKey = "";
							},
						},
					],
				});
				return;
			}
			if (vm.userName == null || vm.userName == "") {
				showMsg({
					icon: {
						type: "Alert",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "必填項目",
						message: "請填寫要註冊的使用者帳號。",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {},
						},
					],
				});
				return;
			}
			if (vm.displayName == null || vm.displayName == "") {
				showMsg({
					icon: {
						type: "Alert",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "必填項目",
						message: "請填寫要使用的暱稱。",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {},
						},
					],
				});
				return;
			}

			vm.changeWindowContent(vm.$refs.windowloading);
			vm.loadText = "正在進行註冊，請稍後";

			setTimeout(() => {
				registerUser({
					RegKey: vm.regKey,
					UserName: vm.userName,
					Email: vm.email,
					DisplayName: vm.displayName,
				})
					.then((res) => {
						if (res.Success) {
							showMsg({
								icon: {
									type: "Ok",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.email = "";
											vm.changeWindowContent(vm.$refs.windowlogin);
										},
									},
								],
							});
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.changeWindowContent(vm.$refs.windowregister);
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "註冊連線失敗",
								message:
									"連線到伺服器進行註冊時時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.changeWindowContent(vm.$refs.windowregister);
									},
								},
							],
						});
					})
					.finally(() => {});
			}, 500);
		},
		gotoLogin() {
			var vm = this;
			vm.changeWindowContent(vm.$refs.windowlogin);
		},
		changeWindowContent(input) {
			var vm = this;
			vm.$refs.windowframe.setContentHidden();
			setTimeout(() => {
				vm.$refs.windowframe.setWindowSize(input, true);
			}, 150);
			setTimeout(() => {
				vm.setAllWindowContentHidden();
			}, 300);
			setTimeout(() => {
				input.showContent();
				vm.$refs.windowframe.setContentShow();
			}, 320);
		},
		setAllWindowContentHidden() {
			var vm = this;
			vm.$refs.windowlogin.hideContent();
			vm.$refs.windowloading.hideContent();
			vm.$refs.windowactive.hideContent();
			vm.$refs.windowregister.hideContent();
			vm.$refs.windowfinish.hideContent();
		},
		gotoLoginResult() {
			var vm = this;
			if (!vm.loginResult.IsLogin) {
				setTimeout(() => {
					vm.changeWindowContent(vm.$refs.windowregister);
				}, 500);
			} else {
				setUserToken(vm.loginResult.LoginKey);
				setTimeout(() => {
					setTimeout(() => {
						vm.checkUserEntity();
					}, 3000);
					vm.changeWindowContent(vm.$refs.windowactive);
				}, 500);
			}
		},
		goToRouter() {
			var vm = this;
			if (store.state.userEntity == null) {
				showMsg({
					icon: {
						type: "Error",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "內部錯誤",
						message: "出現邏輯錯誤，使用者資訊沒有正確寫入到 Store 中",
					},
					btn: [
						{
							text: "重試",
							type: "btn-danger",
							method: function () {
								vm.checkUserEntity();
							},
						},
					],
				});
				return;
			}
			if (!store.state.userEntity.IsLogin) {
				vm.$refs.windowframe.setWindowSize(vm.$refs.windowlogin, true);
				vm.setAllWindowContentHidden();
				setTimeout(() => {
					vm.$refs.windowlogin.showContent();
					vm.$refs.windowframe.setWindowShow();
				}, 300);
				return;
			}

			if (store.state.userEntity.IsLogin && !store.state.userEntity.IsActive) {
				if (vm.loginResult == null) {
					vm.$refs.windowframe.setWindowSize(vm.$refs.windowactive, false);
					vm.setAllWindowContentHidden();
					setTimeout(() => {
						vm.$refs.windowactive.showContent();
						vm.$refs.windowframe.setWindowShow();
					}, 300);
					vm.loginResult = {
						IsLogin: true,
						Method: "WaitSessionActive",
						LoginKey: null,
						DisplayName: store.state.userEntity.DisplayName,
					};
				}

				setTimeout(() => {
					vm.checkUserEntity();
				}, 3000);

				return;
			}

			vm.$refs.windowframe.setContentHidden();
			setTimeout(() => {
				vm.$refs.windowframe.setWindowSize(vm.$refs.windowfinish, true);
			}, 150);
			setTimeout(() => {
				vm.setAllWindowContentHidden();
			}, 300);
			setTimeout(() => {
				vm.$refs.windowfinish.showContent();
				vm.$refs.windowframe.setWindowShow();
			}, 320);
			setTimeout(() => {
				vm.gotoFinish();
			}, 3500);
		},
		gotoFinish() {
			var vm = this;
			vm.$refs.windowframe.setWindowHidden();
			setTimeout(() => {
				if (
					store.state.router.loginBack == null &&
					store.state.router.startupBack == null
				) {
					router.push({ name: "home" });
				} else {
					let name = "home";
					let query = null;
					if (store.state.router.loginBack != null) {
						name = store.state.router.loginBack.toName;
						query = store.state.router.loginBack.query;
					}
					if (store.state.router.startupBack != null) {
						name = store.state.router.startupBack.toName;
						query = store.state.router.startupBack.query;
					}
					if (name == "login") name = "home";
					store.dispatch("routerStartupBack", null);
					store.dispatch("routerLoginBack", null);
					router.push({
						name: name,
						query: query,
					});
				}
			}, 500);
		},
	},
	mounted() {
		var vm = this;
		setTimeout(() => {
			vm.checkUserEntity();
		}, 150);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.loginwrapper {
	width: 360px;
}
@media (max-width: 1200px) {
	.loginwrapper {
		width: 360px;
	}
}
@media (max-width: 992px) {
	.loginwrapper {
		width: 360px;
	}
}
@media (max-width: 768px) {
	.loginwrapper {
		width: 360px;
	}
}
@media (max-width: 576px) {
	.loginwrapper {
		width: 85vw;
	}
}

.loadingwrapper {
	width: 240px;
	padding: -16px;
}
@media (max-width: 1200px) {
	.loadingwrapper {
		width: 240px;
		padding: -16px;
	}
}
@media (max-width: 992px) {
	.loadingwrapper {
		width: 240px;
		padding: -16px;
	}
}
@media (max-width: 768px) {
	.loadingwrapper {
		width: 240px;
		padding: -16px;
	}
}
@media (max-width: 576px) {
	.loadingwrapper {
		width: 75vw;
		padding: -16px;
	}
}

.activewrapper {
	width: 360px;
	padding: -16px;
}
@media (max-width: 1200px) {
	.activewrapper {
		width: 360px;
		padding: -16px;
	}
}
@media (max-width: 992px) {
	.activewrapper {
		width: 360px;
		padding: -16px;
	}
}
@media (max-width: 768px) {
	.activewrapper {
		width: 360px;
		padding: -16px;
	}
}
@media (max-width: 576px) {
	.activewrapper {
		width: 85vw;
		padding: -16px;
	}
}

.windowfinishwrapper {
	width: 360px;
	padding: -16px;
}
@media (max-width: 1200px) {
	.windowfinishwrapper {
		width: 360px;
		padding: -16px;
	}
}
@media (max-width: 992px) {
	.windowfinishwrapper {
		width: 360px;
		padding: -16px;
	}
}
@media (max-width: 768px) {
	.windowfinishwrapper {
		width: 360px;
		padding: -16px;
	}
}
@media (max-width: 576px) {
	.windowfinishwrapper {
		width: 85vw;
		padding: -16px;
	}
}

.registerwrapper {
	width: 360px;
	padding: -16px;
}
@media (max-width: 1200px) {
	.registerwrapper {
		width: 360px;
		padding: -16px;
	}
}
@media (max-width: 992px) {
	.registerwrapper {
		width: 360px;
		padding: -16px;
	}
}
@media (max-width: 768px) {
	.registerwrapper {
		width: 360px;
		padding: -16px;
	}
}
@media (max-width: 576px) {
	.registerwrapper {
		width: 85vw;
		padding: -16px;
	}
}
</style>