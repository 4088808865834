<template>
	<div class="windowwrapper">
		<MenuList width="w2" :isSelected="isSelected">
			<MenuItem :clickEvent="showModalNewRegKey">
				<div class="row w-100">
					<div class="col-12 text-center">
						<i class="fa-solid fa-circle-plus"></i> 新增金鑰
					</div>
				</div>
			</MenuItem>
			<MenuItem
				v-for="item in targetDataList"
				:key="item.UID"
				:isSelected="checkIsSelectData(item)"
				:targetData="item"
				><div class="row w-100">
					<div class="col-12 text-truncate">
						<i class="fa-solid fa-key me-1"></i> {{ item.KeyMain }}
					</div>
				</div>
			</MenuItem>
		</MenuList>
		<ContentPage
			v-if="targetData == null"
			otherclass="hidewhenmobile"
			width="w2"
		>
			<div class="emptywrapper">
				<div class="row">
					<div class="col-12 text-center">
						<i class="fa-solid fa-key fa-10x"></i>
					</div>
					<div class="col-12 mt-3 text-center">
						<h4><strong>請選擇金鑰</strong></h4>
					</div>
					<div class="col-12 text-center">請從選單選擇要管理的金鑰資料</div>
				</div>
			</div>
		</ContentPage>
		<ContentPage
			v-if="targetData != null"
			:otherclass="contentStatus"
			width="w2"
		>
			<ContentSecion>
				<div class="row">
					<div class="col-12">基本資料</div>
				</div>
				<hr class="mt-2 mb-2" />
				<div class="row">
					<div class="col-12 d-none d-md-block text-end">
						<small class="text-black-50"
							>唯一識別號碼 - {{ targetData.UID }}</small
						>
					</div>
					<div class="col-12 d-block d-md-none">
						<small>唯一識別號碼</small><br />
						<input
							type="text"
							class="form-control"
							disabled
							v-model="targetData.UID"
						/>
					</div>
					<div class="col-6 col-xl-3">
						<small>金鑰本體</small><br />
						<input
							type="text"
							class="form-control"
							disabled
							v-model="targetData.KeyMain"
						/>
					</div>
					<div class="d-none d-lg-block col-xl-5"></div>
					<div class="col-12 col-xl-8">
						<small>備註</small><br />
						<input
							type="text"
							class="form-control"
							v-model="targetData.Remark"
						/>
					</div>
					<div class="d-none d-lg-block col-xl-4"></div>
					<div class="col-12 col-xl-12">
						<small>詳細說明</small><br />
						<textarea
							class="form-control"
							rows="3"
							v-model="targetData.Description"
						></textarea>
					</div>
					<div class="col-3 col-xl-2 align-self-end">
						<div class="form-check mb-1">
							<input
								class="form-check-input"
								type="checkbox"
								value=""
								id="forRegKeyDisabled"
								v-model="targetData.IsDisabled"
							/>
							<label class="form-check-label" for="forRegKeyDisabled">
								停用金鑰
							</label>
						</div>
					</div>
					<div v-if="targetData.IsDisabled" class="col-12 col-xl-12">
						<small>停用原因</small><br />
						<textarea
							class="form-control"
							rows="3"
							v-model="targetData.DisabledReason"
						></textarea>
					</div>
					<div class="col-12 mt-3 text-black-50">
						<template v-if="targetData.IsActive">
							<small>啟用時間：{{ targetData.ActiveTime | moment }}</small
							><br />
						</template>
						<template v-if="targetData.IsDisabled">
							<small>停用時間：{{ targetData.DisabledTime | moment }}</small
							><br />
						</template>
						<small>建立時間：{{ targetData.CreateTime | moment }}</small
						><br />
						<small>更新時間：{{ targetData.UpdateTime | moment }}</small>
					</div>
				</div>
			</ContentSecion>
			<ContentSecion>
				<div class="row">
					<div class="col-12">目標使用者</div>
				</div>
				<hr class="mt-2 mb-2" />
				<div class="row" v-if="targetData.UserAccount == null">
					<div class="col-12">此金鑰尚未註冊使用者</div>
				</div>
				<div class="row" v-if="targetData.UserAccount != null">
					<div class="col-12 d-none d-md-block text-end">
						<small class="text-black-50"
							>唯一識別號碼 - {{ targetData.UserAccount.UID }}</small
						>
					</div>
					<div class="col-12 d-block d-md-none">
						<small>唯一識別號碼</small><br />
						<strong>{{ targetData.UserAccount.UID }}</strong>
					</div>
					<div class="col-6 col-xl-3">
						<small>帳號</small><br />
						<strong>{{ targetData.UserAccount.UserName }}</strong>
					</div>
					<div class="col-6 col-xl-3">
						<small>暱稱</small><br />
						<strong>{{ targetData.UserAccount.DisplayName }}</strong>
					</div>
					<div class="col-12 col-xl-6">
						<small>Email</small><br />
						<strong>{{ targetData.UserAccount.Email }}</strong>
					</div>
					<div class="col-12 mt-3 text-black-50">
						<small
							>建立時間：{{ targetData.UserAccount.CreateTime | moment }}</small
						><br />
						<small
							>更新時間：{{ targetData.UserAccount.UpdateTime | moment }}</small
						>
					</div>
				</div>
			</ContentSecion>
			<ContentSecion>
				<div class="row">
					<div class="col-12 text-center">
						<button class="btn btn-lg btn-danger" @click="getRegKey">
							<i class="fa-solid fa-arrow-rotate-right"></i> 重讀
						</button>
						<button class="btn btn-lg btn-primary ms-2" @click="setRegKey">
							<i class="fa-solid fa-floppy-disk"></i> 更新金鑰
						</button>
					</div>
				</div>
			</ContentSecion>
		</ContentPage>
		<BottomBar>
			<div class="row">
				<div class="col-12">
					<button class="btn btn-light" @click="setBackBtn">
						<i class="fa-solid fa-arrow-left"></i> 返回
					</button>
					<button
						class="d-none d-sm-inline-block btn btn-danger ms-2"
						@click="getRegKeyList"
					>
						<i class="fa-solid fa-arrows-rotate"></i> 重讀列表
					</button>
				</div>
			</div>
		</BottomBar>
		<ModalBox ref="modalNewRegKey" width="w6">
			<template v-slot:content>
				<div class="row">
					<div class="col-12 col-xl-8">
						<small>備註</small><br />
						<input type="text" class="form-control" v-model="newData.Remark" />
					</div>
					<div class="d-none d-lg-block col-xl-4"></div>
					<div class="col-12 col-xl-12">
						<small>詳細說明</small><br />
						<textarea
							class="form-control"
							rows="3"
							v-model="newData.Description"
						></textarea>
					</div>
				</div>
			</template>
			<template v-slot:bar>
				<div class="row">
					<div class="col-6">
						<button class="btn btn-light" @click="hideModalNewRegKey">
							取消
						</button>
					</div>
					<div class="col-6 text-end">
						<button class="btn btn-primary ms-1" @click="setNewRegKey">
							新增金鑰
						</button>
					</div>
				</div>
			</template>
		</ModalBox>
		<ModalBox ref="modalLoading" width="w3" padding="borderpadding">
			<template v-slot:content>
				<div class="row">
					<div class="col-12 text-center">正在處理，請稍後...</div>
					<div class="col-12">
						<LoadSpin></LoadSpin>
					</div>
				</div>
			</template>
		</ModalBox>
	</div>
</template>

<script>
import BottomBar from "@/components/WindowUI/BottomBar.vue";
import MenuList from "@/components/WindowUI/MenuList.vue";
import MenuItem from "@/components/WindowUI/MenuItem.vue";
import ContentPage from "@/components/WindowUI/ContentPage.vue";
import ContentSecion from "@/components/WindowUI/ContentSection.vue";
import ModalBox from "@/components/WindowUI/ModalBox.vue";
import LoadSpin from "@/components/LoadSpin.vue";
import { getRegKeyList, getRegKey, setRegKey, setNewRegKey } from "@/api/user";
import { showMsg } from "@/utils/msgCtrl";
import moment from "moment";
export default {
	name: "RegKey",
	props: {},
	data() {
		return {
			windowName: "systemmanager-regkey",
			targetDataList: [],
			selectItem: null,
			targetData: null,
			contentStatus: "",
			isSelected: false,
			loadingCount: 0,
			newData: {},
			afterSelectData: null,
		};
	},
	components: {
		BottomBar,
		MenuList,
		MenuItem,
		ContentPage,
		ContentSecion,
		ModalBox,
		LoadSpin,
	},
	computed: {},
	filters: {
		momentDate: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日");
		},
		moment: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日 HH:mm:ss");
		},
		commaFormat: function (value) {
			return value
				.toString()
				.replace(
					/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/,
					function (all, pre, groupOf3Digital) {
						return pre + groupOf3Digital.replace(/\d{3}/g, ",$&");
					}
				);
		},
	},
	watch: {
		selectItem(newValue) {
			var vm = this;
			if (newValue == null) {
				if (window.innerWidth < 576) {
					vm.isSelected = false;
					vm.contentStatus = "mobilehide";
					setTimeout(() => {
						vm.targetData = null;
					}, 650);
				} else {
					vm.targetData = null;
				}
				return;
			} else {
				vm.getRegKey();
			}
		},
		loadingCount(newValue) {
			var vm = this;
			if (newValue > 0) {
				vm.showModalLoading();
			} else {
				vm.hideModalLoading();
			}
		},
	},
	methods: {
		getRegKeyList() {
			var vm = this;
			vm.loadingCount++;
			vm.selectItem = null;
			vm.targetDataList = [];
			setTimeout(() => {
				getRegKeyList()
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.targetDataList = data;
							vm.setItemToNewData();
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.setWindowToMenu();
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得金鑰列表時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.setWindowToMenu();
									},
								},
							],
						});
					});
			}, 750);
		},
		getRegKey() {
			var vm = this;
			vm.loadingCount++;
			vm.targetData = null;
			setTimeout(() => {
				getRegKey(vm.selectItem)
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.targetData = data;
							vm.isSelected = true;
							vm.contentStatus = "mobileshow";
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.selectItem = null;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得金鑰資料時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.selectItem = null;
									},
								},
							],
						});
					});
			}, 750);
		},
		setRegKey() {
			var vm = this;
			if (vm.targetData == null) {
				showMsg({
					icon: {
						type: "Error",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "更新金鑰",
						message: "無法進行更新金鑰，必須先選擇一個金鑰之後才能繼續",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {},
						},
					],
				});
				return;
			}
			vm.loadingCount++;
			setTimeout(() => {
				setRegKey(vm.targetData)
					.then((res) => {
						if (res.Success) {
							vm.getRegKey();
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "更新金鑰",
								message:
									"連線到伺服器更新金鑰時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
									},
								},
							],
						});
					});
			}, 750);
		},
		setNewRegKey() {
			var vm = this;
			if (
				vm.newData.Remark == null ||
				vm.newData.Remark == "" ||
				vm.newData.Description == null ||
				vm.newData.Description == ""
			) {
				showMsg({
					icon: {
						type: "Error",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "新增金鑰",
						message: "無法新增金鑰，備註欄位與詳細說明為必填欄位",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {},
						},
					],
				});
				return;
			}
			vm.loadingCount++;
			setTimeout(() => {
				setNewRegKey(vm.newData)
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.afterSelectData = data;
							vm.getRegKeyList();
							vm.hideModalNewRegKey();
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "新增金鑰",
								message:
									"連線到伺服器新增金鑰時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
									},
								},
							],
						});
					});
			}, 750);
		},
		setItemToNewData() {
			var vm = this;
			if (vm.afterSelectData == null) return;
			let result = null;
			vm.targetDataList.forEach((item) => {
				if (item.UID == vm.afterSelectData.UID) result = item;
			});
			if (result != null) vm.setItemClick(result);
		},
		setItemClick(input) {
			var vm = this;
			if (vm.checkIsSelectData(input)) return;
			vm.selectItem = JSON.parse(JSON.stringify(input));
		},
		setItemClear() {
			var vm = this;
			if (window.innerWidth < 576) {
				if (vm.selectItem != null) {
					vm.selectItem = null;
				} else {
					vm.setWindowToMenu();
				}
			} else {
				vm.selectItem = null;
			}
		},
		checkIsSelectData(input) {
			var vm = this;
			let result = false;
			if (vm.selectItem != null) {
				if (vm.selectItem.UID == input.UID) result = true;
			}
			return result;
		},
		setBackBtn() {
			var vm = this;
			if (window.innerWidth < 576) {
				if (vm.selectItem != null) {
					vm.selectItem = null;
				} else {
					vm.setWindowToMenu();
				}
			} else {
				vm.setWindowToMenu();
			}
		},
		setWindowToMenu() {
			var vm = this;
			vm.$parent.$parent.$parent.setWindowToMenu(vm);
			setTimeout(() => {
				vm.selectItem = null;
				vm.targetDataList = [];
			}, 500);
		},
		showModalLoading() {
			var vm = this;
			vm.$refs.modalLoading.showModal();
		},
		hideModalLoading() {
			var vm = this;
			vm.$refs.modalLoading.hideModal();
		},
		showModalNewRegKey() {
			var vm = this;
			vm.newData = {
				Remark: "",
				Description: "",
			};
			vm.$refs.modalNewRegKey.showModal();
		},
		hideModalNewRegKey() {
			var vm = this;
			vm.$refs.modalNewRegKey.hideModal();
		},
		setDataInit() {
			var vm = this;
			setTimeout(() => {
				vm.getRegKeyList();
			}, 750);
		},
	},
	mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
