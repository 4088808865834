<template>
	<div class="mainwrapper" :class="[mainalign, mainClass, mainStatus]">
		<div
			class="clickwrapper"
			:class="[clickStatus]"
			:style="{
				top: backTop + 'px',
				left: backLeft + 'px',
			}"
			@click="clickBack"
		></div>
		<div
			class="buttonwrapper"
			ref="buttonBase"
			:class="[buttonalign, buttonStatus]"
			@click="clickBtn"
			@mouseenter="btnMouseIn"
			@mouseout="btnMouseOut"
		>
			<slot name="btn"></slot>
		</div>
		<div
			class="contentwrapper"
			ref="contentBase"
			:class="[contentalign, contentStatus]"
		>
			<slot name="content"></slot>
		</div>
		<div
			class="mainbase"
			:style="{
				width: baseWidth + 'px',
				height: baseHeight + 'px',
				transition: baseTrans + 's',
				transitionTimingFunction: baseTiming,
			}"
		></div>
	</div>
</template>
  
<script>
import store from "@/store";
export default {
	name: "DropContentButton",
	props: ["mainalign", "buttonalign", "contentalign"],
	data() {
		return {
			backTop: 0,
			backLeft: 0,
			backWidth: 0,
			backHeight: 0,
			baseWidth: 1,
			baseHeight: 1,
			baseTrans: 0.5,
			baseTiming: "",
			mainClass: "",
			clickStatus: "",
			buttonStatus: "",
			contentStatus: "",
			mainStatus: "",
		};
	},
	computed: {
		userEntity: {
			get() {
				return store.state.userEntity;
			},
		},
	},
	watch: {},
	methods: {
		btnMouseIn() {
			var vm = this;
			vm.mainClass = "movein";
		},
		btnMouseOut() {
			var vm = this;
			vm.mainClass = "moveout";
		},
		clickBtn() {
			var vm = this;
			vm.buttonStatus = "hide";
			vm.mainClass = "moveout";
			vm.clickStatus = "show";
			vm.mainStatus = "show";
			setTimeout(() => {
				vm.setMainSizeToContent(true);
			}, 150);
			setTimeout(() => {
				vm.contentStatus = "show";
			}, 650);
		},
		clickBack() {
			var vm = this;
			vm.contentStatus = "hide";
			vm.clickStatus = "hide";
			vm.mainStatus = "hide";
			setTimeout(() => {
				vm.setMainSizeToBtn(true);
			}, 150);
			setTimeout(() => {
				vm.buttonStatus = "show";
			}, 650);
		},
		setButtonShow() {
			var vm = this;
			let targetHeight = vm.$refs.buttonBase.clientHeight;
			let targetWidth = vm.$refs.buttonBase.clientWidth;
			setTimeout(() => {
				vm.setWindowWidth(targetWidth, true);
			}, 250);
			vm.setWindowHeight(targetHeight, true);
			setTimeout(() => {
				vm.buttonStatus = "show";
			}, 550);
			let targetRect = vm.$refs.buttonBase.getBoundingClientRect();
			vm.backTop = -targetRect.y;
			vm.backLeft = -targetRect.x;
		},
		setMainSizeToBtn(useAnime) {
			var vm = this;
			let targetHeight = vm.$refs.buttonBase.clientHeight;
			let targetWidth = vm.$refs.buttonBase.clientWidth;
			setTimeout(() => {
				vm.setWindowWidth(targetWidth, useAnime);
			}, 250);
			vm.setWindowHeight(targetHeight, useAnime);

			let targetRect = vm.$refs.buttonBase.getBoundingClientRect();
			vm.backTop = -targetRect.y;
			vm.backLeft = -targetRect.x;
		},
		setMainSizeToContent(useAnime) {
			var vm = this;
			let targetHeight = vm.$refs.contentBase.clientHeight;
			let targetWidth = vm.$refs.contentBase.clientWidth;
			vm.setWindowWidth(targetWidth, useAnime);
			setTimeout(() => {
				vm.setWindowHeight(targetHeight, useAnime);
			}, 250);
			let targetRect = vm.$refs.contentBase.getBoundingClientRect();
			vm.backTop = -targetRect.y;
			vm.backLeft = -targetRect.x;
		},
		setWindowHeight(target, useAnime) {
			let nowHeight = this.baseHeight;
			let value = target - nowHeight;
			let newtarget = target + (value / 5) * 1;
			if (newtarget <= 0) newtarget = (target / 5) * 4;
			if (newtarget == nowHeight) newtarget = target * 1.05;
			this.baseHeight = newtarget;
			if (useAnime) this.baseTrans = 0.35;
			else this.baseTrans = 0;
			this.baseTiming = "ease-in";
			setTimeout(() => {
				this.baseHeight = target;
				if (useAnime) this.baseTrans = 0.25;
				else this.baseTrans = 0;
				this.baseTiming = "ease-out";
			}, 350);
		},
		setWindowWidth(target, useAnime) {
			let nowWidth = this.baseWidth;
			let value = target - nowWidth;
			let newtarget = target + (value / 5) * 1;
			if (newtarget <= 0) newtarget = (target / 5) * 4;
			if (newtarget == nowWidth) newtarget = target * 1.05;
			this.baseWidth = newtarget;
			if (useAnime) this.baseTrans = 0.35;
			else this.baseTrans = 0;
			this.baseTiming = "ease-in";
			setTimeout(() => {
				this.baseWidth = target;
				if (useAnime) this.baseTrans = 0.25;
				else this.baseTrans = 0;
				this.baseTiming = "ease-out";
			}, 350);
		},
	},
	mounted() {
		var vm = this;
		setTimeout(() => {
			vm.setButtonShow(true);
		}, 50);
	},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.mainwrapper {
	position: relative;
	display: inline-block;
	text-align: left;
	background: rgba(255, 255, 255, 0.25);
	backdrop-filter: blur(24px) saturate(1.5);
	border-radius: 16px;
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.25);
	transition: 0.15s;
	vertical-align: top;
	pointer-events: none;
	cursor: default;
	margin: 3px;
}
.mainwrapper.movein {
	animation: userbtn-movein 0.5s ease-in-out;
	transform: scale(1.01, 1.01);
	background: rgba(85, 85, 255, 0.85);
	color: white;
}
@keyframes userbtn-movein {
	0% {
		background: rgba(255, 255, 255, 0.25);
		transform: scale(1, 1);
	}
	75% {
		transform: scale(1.05, 1.05);
		color: white;
	}
	100% {
		transform: scale(1.01, 1.01);
		background: rgba(85, 85, 255, 0.85);
		color: white;
	}
}
.mainwrapper.moveout {
	animation: userbtn-moveout 0.25s ease-in-out;
	transform: scale(1, 1);
	background: rgba(255, 255, 255, 0.25);
}
@keyframes userbtn-moveout {
	0% {
		background: rgba(85, 85, 255, 0.85);
		transform: scale(1.01, 1.01);
	}
	75% {
		transform: scale(0.95, 0.95);
	}
	100% {
		transform: scale(1, 1);
		background: rgba(255, 255, 255, 0.25);
	}
}
.mainwrapper.show {
	z-index: 99999;
	background: rgba(255, 255, 255, 0.45);
}
.mainwrapper.top {
	vertical-align: top;
}
.mainwrapper.middle {
	vertical-align: middle;
}
.mainwrapper.bottom {
	vertical-align: bottom;
}
.clickwrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	width: 100vw;
	background: transparent;
	z-index: -1;
	pointer-events: none;
	display: none;
}
.clickwrapper.show {
	display: block;
	pointer-events: all;
}
.clickwrapper.hide {
	display: none;
	pointer-events: none;
}
.buttonwrapper {
	position: absolute;
	white-space: nowrap;
	padding: 6px 16px;
	opacity: 0;
	transition: 0.5s;
}
.buttonwrapper.show {
	opacity: 1;
	pointer-events: all;
	animation: buttonwrapper-show 0.35s ease-in-out;
}
@keyframes buttonwrapper-show {
	0% {
		transform: scale(0.95);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
.buttonwrapper.hide {
	opacity: 0;
	pointer-events: none;
	animation: buttonwrapper-hide 0.15s ease-in-out;
}
@keyframes buttonwrapper-hide {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(0.95);
		opacity: 0;
	}
}
.buttonwrapper.topleft {
	top: 0;
	left: 0;
}
.buttonwrapper.topright {
	top: 0;
	right: 0;
}
.buttonwrapper.bottomleft {
	bottom: 0;
	left: 0;
}
.buttonwrapper.bottomright {
	bottom: 0;
	right: 0;
}
.contentwrapper {
	position: absolute;
	padding: 16px;
	opacity: 0;
	pointer-events: none;
}

.contentwrapper.show {
	opacity: 1;
	pointer-events: all;
	animation: contentwrapper-show 0.35s ease-in-out;
}
@keyframes contentwrapper-show {
	0% {
		transform: scale(0.95);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
.contentwrapper.hide {
	opacity: 0;
	pointer-events: none;
	animation: contentwrapper-hide 0.15s ease-in-out;
}
@keyframes contentwrapper-hide {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(0.95);
		opacity: 0;
	}
}
.contentwrapper.topleft {
	top: 0;
	left: 0;
}
.contentwrapper.topright {
	top: 0;
	right: 0;
}
.contentwrapper.bottomleft {
	bottom: 0;
	left: 0;
}
.contentwrapper.bottomright {
	bottom: 0;
	right: 0;
}
.windowbase {
	width: 600px;
	height: 400px;
	transition: 0.5s;
	transition-timing-function: ease-in;
}
.userbtn {
	position: relative;
	background: rgba(255, 255, 255, 0.55);
	display: inline-block;
	padding: 6px 12px;
	border-radius: 32px;
	backdrop-filter: blur(12px);
	transition: 0.35s;
	cursor: default;
}
.userbtn.movein {
	animation: userbtn-movein 0.5s ease-in-out;
	transform: scale(1.01, 1.01);
}
@keyframes userbtn-movein {
	0% {
		transform: scale(1, 1);
	}
	75% {
		transform: scale(1.05, 1.05);
	}
	100% {
		transform: scale(1.01, 1.01);
	}
}
.userbtn.moveout {
	animation: userbtn-moveout 0.25s ease-in-out;
	transform: scale(1, 1);
}
@keyframes userbtn-moveout {
	0% {
		transform: scale(1.01, 1.01);
	}
	75% {
		transform: scale(0.95, 0.95);
	}
	100% {
		transform: scale(1, 1);
	}
}
.userbtn:hover {
	background: rgba(85, 85, 255, 0.85);
	color: white;
}
</style>