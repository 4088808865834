import client from "@/api/client";

export function setUserEntity(data) {
	return client({
		url: "api/User/SetUserHeader",
		method: "post",
		data,
	});
}

export function loginUser(data) {
	return client({
		url: "api/User/LoginUser",
		method: "post",
		data,
	});
}

export function logoutUser() {
	return client({
		url: "api/User/LogoutUser",
		method: "post",
	});
}

export function registerUser(data) {
	return client({
		url: "api/User/RegisterUser",
		method: "post",
		data,
	});
}

export function activeSession(data) {
	return client({
		url: "api/User/ActiveSession",
		method: "post",
		data,
	});
}

export function getUserAccountList(data) {
	return client({
		url: "api/User/GetUserAccountList",
		method: "post",
		data,
	});
}

export function getUserAccount(data) {
	return client({
		url: "api/User/GetUserAccount",
		method: "post",
		data,
	});
}

export function getUserPermissionList(data) {
	return client({
		url: "api/User/GetUserPermissionList",
		method: "post",
		data,
	});
}

export function setUserAccount(data) {
	return client({
		url: "api/User/SetUserAccount",
		method: "post",
		data,
	});
}

export function getRegKeyList(data) {
	return client({
		url: "api/User/GetRegKeyList",
		method: "post",
		data,
	});
}

export function getRegKey(data) {
	return client({
		url: "api/User/GetRegKey",
		method: "post",
		data,
	});
}

export function setRegKey(data) {
	return client({
		url: "api/User/SetRegKey",
		method: "post",
		data,
	});
}

export function setNewRegKey(data) {
	return client({
		url: "api/User/SetNewRegKey",
		method: "post",
		data,
	});
}

export function getUserSession(data) {
	return client({
		url: "api/User/GetUserSession",
		method: "post",
		data,
	});
}

export function getUserSessionHistoryList(data) {
	return client({
		url: "api/User/GetUserSessionHistoryList",
		method: "post",
		data,
	});
}

export function setUserSessionDisabled(data) {
	return client({
		url: "api/User/SetUserSessionDisabled",
		method: "post",
		data,
	});
}
