<template>
	<div class="itemwrapper">
		<div
			class="item"
			:class="[itemMoveStatus, itemSelectStatus]"
			@mouseover="mouseMoveIn"
			@mouseout="mouseMoveOut"
			@click="mouseClick"
		>
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "MenuItem",
	props: ["isSelected", "targetData", "clickEvent"],
	data() {
		return {
			itemMoveStatus: "",
			itemSelectStatus: "",
		};
	},
	watch: {
		isSelected(newValue) {
			var vm = this;
			if (newValue) {
				vm.itemSelectStatus = "selected";
			} else {
				vm.itemSelectStatus = "";
			}
		},
	},
	methods: {
		mouseClick() {
			var vm = this;
			if (vm.targetData != null) vm.$parent.$parent.setItemClick(vm.targetData);
			if (vm.clickEvent != null) vm.clickEvent();
		},
		mouseMoveIn() {
			var vm = this;
			vm.itemMoveStatus = "movein";
		},
		mouseMoveOut() {
			var vm = this;
			vm.itemMoveStatus = "moveout";
			setTimeout(() => {
				if (vm.itemMoveStatus == "moveout") vm.itemMoveStatus = "";
			}, 300);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.itemwrapper {
	position: relative;
	width: 100%;
	padding: 4px 8px 4px 8px;
}
.item {
	position: relative;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.25);
	border-radius: 12px;
	display: flex;
	align-items: center;
	padding: 8px 8px;
	min-height: 36px;
	cursor: pointer;
	transition: 0.5s;
	box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05);
}
.item > .row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
@media (max-width: 575px) {
	.itemwrapper {
		position: relative;
		width: 100%;
		min-height: 48px;
		padding: 8px 16px 0 16px;
	}
	.itemwrapper:nth-of-type(1) {
		padding: 16px 16px 0 16px;
	}
	.item {
		padding: 12px 12px;
	}
}
.item::before {
	content: "";
	position: absolute;
	left: 3px;
	top: 9px;
	bottom: 9px;
	border-radius: 5px;
	width: 4px;
	background: rgba(85, 85, 255, 0);
	transition: 0.5s;
}
.item.selected::before {
	content: "";
	position: absolute;
	left: 3px;
	top: 9px;
	bottom: 9px;
	border-radius: 5px;
	width: 4px;
	background: rgba(85, 85, 255, 1);
	transition: 0.5s;
}
.item.movein {
	background: rgba(85, 85, 255, 0.85);
	animation: item-movein 0.5s ease-in-out;
	color: white;
	box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.25);
}
.item.movein::before {
	content: "";
	position: absolute;
	left: 3px;
	top: 9px;
	bottom: 9px;
	border-radius: 5px;
	width: 4px;
	background: rgba(255, 255, 255, 1);
}
@keyframes item-movein {
	0% {
		transform: scale(1, 1);
		background: rgba(255, 255, 255, 0.25);
		color: black;
	}
	50% {
		transform: scale(1.04, 1.04);
		background: rgba(85, 85, 255, 0.85);
		color: black;
	}
	100% {
		transform: scale(1, 1);
		background: rgba(85, 85, 255, 0.85);
		color: white;
	}
}
.item.moveout {
	background: rgba(255, 255, 255, 0.25);
	animation: item-moveout 0.75s ease-in-out;
	color: black;
}
@keyframes item-moveout {
	0% {
		transform: scale(1, 1);
		background: rgba(85, 85, 255, 0.85);
		color: white;
	}
	50% {
		transform: scale(0.96, 0.96);
		background: rgba(85, 85, 255, 0.85);
		color: black;
	}
	100% {
		transform: scale(1, 1);
		background: rgba(255, 255, 255, 0.25);
		color: black;
	}
}
</style>
