<template>
	<div class="menuitemframewrapper" :class="[sizeset]">
		<div class="menuitemframe">
			<div
				ref="itemmain"
				class="menuitemwrapper"
				:class="[itemStatus]"
				@mouseover="setMouseOver"
				@mouseout="setMouseOut"
				@click="setMouseClick"
				:style="{
					opacity: itemOpacity,
					transform:
						'scale(' +
						itemZoom +
						', ' +
						itemZoom +
						') translate(' +
						itemMoveX +
						'px,' +
						itemMoveY +
						'px)',
					pointerEvents: itemPointEvent,
					transition: itemTrans + 's',
					transitionTimingFunction: itemTiming,
				}"
			>
				<div class="menuitemborder"></div>
				<div class="contentslot" :class="contentStatus">
					<slot name="content"></slot>
				</div>
				<div class="lightslot" :class="lightStatus">
					<slot name="light"></slot>
				</div>
			</div>
		</div>
	</div>
</template>
  
<script>
import { createGUID } from "@/utils/createGUID";
export default {
	name: "MenuItem",
	props: ["itemsize", "targetname", "sizeset"],
	data() {
		return {
			UID: null,
			itemStatus: "",
			itemMoveX: 0,
			itemMoveY: 0,
			itemZoom: 1.5,
			itemPointEvent: "none",
			itemOpacity: 0,
			itemTrans: 0.5,
			itemTiming: "",
			lightStatus: "",
			contentStatus: "",
		};
	},
	computed: {},
	watch: {},
	methods: {
		setMouseClick() {
			var vm = this;
			vm.itemTrans = 0;
			vm.itemOpacity = 0;
			vm.$parent.setMenuToWindow(vm);
			vm.contentStatus = "hide";
			setTimeout(() => {
				vm.lightStatus = "hide";
			}, 50);
		},
		setMouseOver() {
			var vm = this;
			vm.itemTrans = 0.25;
			vm.itemStatus = "show";
			let movePoint = vm.calMovePoint();
			vm.itemMoveX = movePoint[0] / 4;
			vm.itemMoveY = -movePoint[1] / 3;
			if (vm.getViewWidth() < 768) {
				vm.itemZoom = 2;
			} else {
				vm.itemZoom = 1.5;
			}
			vm.itemTiming = "ease-in-out";
			vm.$parent.setItemLight(this);
			vm.contentStatus = "hide";
			setTimeout(() => {
				if (vm.itemStatus == "show") vm.lightStatus = "show";
			}, 150);
		},
		setMouseOut() {
			var vm = this;
			vm.itemTrans = 0.75;
			vm.itemMoveX = 0;
			vm.itemMoveY = 0;
			vm.itemZoom = 1.0;
			vm.itemTiming = "ease-in-out";
			vm.$parent.setItemLightOut(vm);
			if (vm.itemZoom == 1.0) vm.itemStatus = "hide";
			setTimeout(() => {
				if (vm.itemStatus == "hide") {
					vm.itemStatus = "";
					vm.lightStatus = "hide";
					setTimeout(() => {
						if (vm.itemStatus == "") vm.contentStatus = "show";
					}, 150);
				}
			}, 450);
		},
		setItemShow() {
			var vm = this;
			vm.itemTrans = 0;
			vm.itemZoom = 1;
			vm.itemOpacity = 1;
			vm.contentStatus = "show";
		},
		setItemFadeIn() {
			var vm = this;
			let movePoint = vm.calMovePoint();

			vm.itemTrans = 0;
			vm.itemMoveX = movePoint[0];
			vm.itemMoveY = movePoint[1];
			vm.itemZoom = 1.5;
			vm.itemOpacity = 0;
			vm.itemTiming = "ease-in";
			setTimeout(() => {
				vm.itemTrans = 0.5;
				vm.itemZoom = 0.95;
				vm.itemMoveX = 0;
				vm.itemMoveY = 0;
				vm.itemOpacity = 1;
				vm.itemTiming = "ease-out";
			}, 10);
			setTimeout(() => {
				vm.itemTrans = 0.45;
				vm.itemMoveX = 0;
				vm.itemMoveY = 0;
				vm.itemZoom = 1;
				vm.itemOpacity = 1;
				vm.itemTiming = "ease-out";
				vm.itemPointEvent = "all";
			}, 510);
		},
		setItemFadeOut() {
			var vm = this;
			vm.itemTrans = 0.45;
			vm.itemZoom = 0.8;
			vm.itemOpacity = 0;
			vm.itemTiming = "ease-out";
			vm.itemPointEvent = "none";
		},
		setItemZoomOut() {
			var vm = this;
			let movePoint = vm.calMovePoint();
			vm.itemMoveX = -movePoint[0];
			vm.itemMoveY = -movePoint[1];
			vm.itemPointEvent = "none";
			vm.itemTrans = 0.35;
			vm.itemZoom = 0.9;
			vm.itemOpacity = 1;
			vm.itemTiming = "ease-out";
		},
		setItemZoomIn() {
			var vm = this;
			vm.itemMoveX = 0;
			vm.itemMoveY = 0;
			vm.itemTrans = 0.35;
			vm.itemZoom = 1;
			vm.itemOpacity = 1;
			vm.itemTiming = "ease-in-out";
			setTimeout(() => {
				vm.itemPointEvent = "all";
			}, 350);
		},
		setItemPointEvent(input) {
			var vm = this;
			if (input == null) input = "all";
			vm.itemPointEvent = input;
		},
		getViewWidth() {
			let vw = Math.max(
				document.documentElement.clientWidth || 0,
				window.innerWidth || 0
			);
			return vw;
		},
		getViewHeigth() {
			let vh = Math.max(
				document.documentElement.clientHeight || 0,
				window.innerHeight || 0
			);
			return vh;
		},
		calMovePoint() {
			var vm = this;
			let targetRect = vm.$refs.itemmain.getBoundingClientRect();
			let vw = vm.getViewWidth();
			let vh = vm.getViewHeigth();
			let startX = 0;
			let startY = 0;
			if (targetRect.top < vh / 2) startY = targetRect.top;
			else startY = targetRect.bottom;
			if (targetRect.left < vw / 2) startX = targetRect.left;
			else startX = targetRect.right;

			let moveX = (startX - vw / 2) * 0.1;
			let moveY = (startY - vh / 2) * 0.1;
			return [moveX, moveY];
		},
	},
	created() {
		var vm = this;
		vm.UID = createGUID();
	},
	mounted() {},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.menuitemframewrapper {
	position: relative;
	float: left;
	display: inline-block;
	width: 165px;
	height: 124px;
	transition: 0.35s;
}
.menuitemframewrapper.size-2 {
	width: 330px;
	height: 248px;
}
.menuitemframe {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 8px;
	transition: 0.35s;
	display: flex;
	justify-content: center;
	align-items: center;
}
.menuitemwrapper {
	position: relative;
	height: 100%;
	width: 100%;
	background: rgba(255, 255, 255, 0.45);
	backdrop-filter: blur(12px) saturate(1.5);
	border-radius: 16px;
	box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15);
	transition: 0.35s;
}
.menuitemwrapper.show {
	z-index: 999;
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.25);
}
.menuitemwrapper.hide {
	z-index: 400;
}

.lightslot {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: 0.45s;
	transform: scale(0.8, 0.8);
}
.lightslot.show {
	opacity: 1;
	transition: 0.25s;
	transform: scale(1, 1);
}
.lightslot.hide {
	opacity: 0;
	transition: 0.45s;
	transform: scale(0.8, 0.8);
}

.contentslot {
	opacity: 1;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: 0.45s;
	transform: scale(1, 1);
}
.contentslot.show {
	opacity: 1;
	transition: 0.25s;
	transform: scale(1, 1);
}
.contentslot.hide {
	opacity: 0;
	transition: 0.45s;
	transform: scale(0.8, 0.8);
}

.menuitemborder {
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
	border: 1px solid rgba(100, 100, 100, 0.25);
	border-radius: 16px;
	pointer-events: none;
}
@media (max-width: 1399px) {
	.menuitemframewrapper {
		width: 190px;
		height: 142px;
	}
	.menuitemframewrapper.size-2 {
		width: 380px;
		height: 284px;
	}
	.menuitemframe {
		padding: 8px;
	}
}
@media (max-width: 1199px) {
	.menuitemframewrapper {
		width: 160px;
		height: 120px;
	}
	.menuitemframewrapper.size-2 {
		width: 320px;
		height: 240px;
	}
	.menuitemframe {
		padding: 6px;
	}
}
@media (max-width: 991px) {
	.menuitemframewrapper {
		width: 180px;
		height: 135px;
	}
	.menuitemframewrapper.size-2 {
		width: 360px;
		height: 270px;
	}
	.menuitemframe {
		padding: 6px;
	}
}
@media (max-width: 767px) {
	.menuitemframewrapper {
		width: 135px;
		height: 100px;
	}
	.menuitemframewrapper.size-2 {
		width: 270px;
		height: 200px;
	}
	.menuitemframe {
		padding: 4px;
		font-size: 8pt;
	}
}
@media (max-width: 575px) {
	.menuitemframewrapper {
		width: calc((25vw - 0.5rem));
		height: calc(((25vw - 0.5rem) / 4 * 3));
	}
	.menuitemframewrapper.size-2 {
		width: calc((50vw - 1rem));
		height: calc(((50vw - 1.15rem) / 4 * 3));
	}
	.menuitemframe {
		padding: 2px;
		font-size: 6pt;
	}
}
@media (max-width: 374px) {
	.menuitemframewrapper {
		width: calc((33.3vw - 0.5rem));
		height: calc(((33.3vw - 0.5rem) / 4 * 3));
	}
	.menuitemframewrapper.size-2 {
		width: calc((66.6vw - 1rem));
		height: calc(((66.6vw - 1.15rem) / 4 * 3));
	}
	.menuitemframe {
		padding: 2px;
		font-size: 6pt;
	}
}
@media (max-width: 239px) {
	.menuitemframewrapper {
		width: calc((50vw - 0.5rem));
		height: calc(((50vw - 0.5rem) / 4 * 3));
	}
	.menuitemframewrapper.size-2 {
		width: calc((100vw - 1rem));
		height: calc(((100vw - 1rem) / 4 * 3));
	}
	.menuitemframe {
		padding: 2px;
		font-size: 6pt;
	}
}
</style>
