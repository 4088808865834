<template>
	<div class="windowwrapper">
		<MenuList width="w2" :isSelected="isSelected">
			<MenuItem
				v-for="item in targetDataList"
				:key="item.UID"
				:isSelected="checkIsSelectData(item)"
				:targetData="item"
				><div class="row w-100">
					<div class="col-12 text-truncate">
						<i class="fa-solid fa-circle-user me-1"></i> {{ item.UserName }}
					</div>
				</div>
			</MenuItem>
		</MenuList>
		<ContentPage
			v-if="targetData == null"
			otherclass="hidewhenmobile"
			width="w2"
		>
			<div class="emptywrapper">
				<div class="row">
					<div class="col-12 text-center">
						<i class="fa-solid fa-circle-user fa-10x"></i>
					</div>
					<div class="col-12 mt-3 text-center">
						<h4><strong>請選擇使用者</strong></h4>
					</div>
					<div class="col-12 text-center">請從選單選擇要編輯的使用者資料</div>
				</div>
			</div>
		</ContentPage>
		<ContentPage
			v-if="targetData != null"
			:otherclass="contentStatus"
			width="w2"
		>
			<ContentSecion>
				<div class="row">
					<div class="col-12">基本資料</div>
				</div>
				<hr class="mt-2 mb-2" />
				<div class="row">
					<div class="col-12 d-none d-md-block text-end">
						<small class="text-black-50"
							>唯一識別號碼 - {{ targetData.UID }}</small
						>
					</div>
					<div class="col-12 d-block d-md-none">
						<small>唯一識別號碼</small><br />
						<input
							type="text"
							class="form-control"
							disabled
							v-model="targetData.UID"
						/>
					</div>
					<div class="col-6 col-xl-3">
						<small>帳號</small><br />
						<input
							type="text"
							class="form-control"
							v-model="targetData.UserName"
						/>
					</div>
					<div class="col-6 col-xl-3">
						<small>暱稱</small><br />
						<input
							type="text"
							class="form-control"
							v-model="targetData.DisplayName"
						/>
					</div>
					<div class="col-12 col-xl-6">
						<small>Email</small><br />
						<input
							type="text"
							class="form-control"
							v-model="targetData.Email"
						/>
					</div>
					<div class="col-12 mt-3 text-black-50">
						<small>建立時間：{{ targetData.CreateTime | moment }}</small
						><br />
						<small>更新時間：{{ targetData.UpdateTime | moment }}</small>
					</div>
				</div>
			</ContentSecion>
			<ContentSecion>
				<div class="row">
					<div class="col-12">註冊金鑰</div>
				</div>
				<hr class="mt-2 mb-2" />
				<div class="row">
					<div class="col-12 d-none d-md-block text-end">
						<small class="text-black-50"
							>唯一識別號碼 - {{ targetData.RegKey.UID }}</small
						>
					</div>
					<div class="col-12 d-block d-md-none">
						<small>唯一識別號碼</small><br />
						<input
							type="text"
							class="form-control"
							disabled
							v-model="targetData.RegKey.UID"
						/>
					</div>
					<div class="col-6 col-xl-3">
						<small>金鑰本體</small><br />
						<input
							type="text"
							class="form-control"
							disabled
							v-model="targetData.RegKey.KeyMain"
						/>
					</div>
					<div class="d-none d-lg-block col-xl-5"></div>
					<div class="col-12 col-xl-8">
						<small>備註</small><br />
						<input
							type="text"
							class="form-control"
							v-model="targetData.RegKey.Remark"
						/>
					</div>
					<div class="d-none d-lg-block col-xl-4"></div>
					<div class="col-12 col-xl-12">
						<small>詳細說明</small><br />
						<textarea
							class="form-control"
							rows="3"
							v-model="targetData.RegKey.Description"
						></textarea>
					</div>
					<div class="col-3 col-xl-2 align-self-end">
						<div class="form-check mb-1">
							<input
								class="form-check-input"
								type="checkbox"
								value=""
								id="forRegKeyDisabled"
								v-model="targetData.RegKey.IsDisabled"
							/>
							<label class="form-check-label" for="forRegKeyDisabled">
								停用金鑰
							</label>
						</div>
					</div>
					<div v-if="targetData.RegKey.IsDisabled" class="col-12 col-xl-12">
						<small>停用原因</small><br />
						<textarea
							class="form-control"
							rows="3"
							v-model="targetData.RegKey.DisabledReason"
						></textarea>
					</div>
					<div class="col-12 mt-3 text-black-50">
						<small>啟用時間：{{ targetData.RegKey.ActiveTime | moment }}</small
						><br />
						<template v-if="targetData.RegKey.IsDisabled">
							<small
								>停用時間：{{ targetData.RegKey.DisabledTime | moment }}</small
							><br />
						</template>
						<small>建立時間：{{ targetData.RegKey.CreateTime | moment }}</small
						><br />
						<small>更新時間：{{ targetData.RegKey.UpdateTime | moment }}</small>
					</div>
				</div>
			</ContentSecion>
			<ContentSecion>
				<div class="row">
					<div class="col-12">使用者權限</div>
				</div>
				<hr class="mt-2 mb-2" />
				<div class="row">
					<div class="col-12">
						<ContentListItem
							v-for="item in targetData.Permissions"
							:key="item.UID"
							><div class="row w-100">
								<div
									class="
										col-12
										d-none d-md-block
										col-md-3 col-lg-6
										text-truncate
										align-self-center
									"
								>
									<i class="fa-solid fa-user-lock me-2"></i> {{ item.Name }}
								</div>
								<div
									class="
										col-12
										d-block d-md-none
										text-truncate
										align-self-center
									"
								>
									<strong
										><i class="fa-solid fa-user-lock me-2"></i>
										{{ item.Name }}</strong
									>
								</div>
								<div
									class="
										col-6 col-md-3 col-lg-2
										text-center text-truncate
										pe-1
										border-end
										align-self-center
										ps-0
									"
								>
									{{ item.StartTime | momentDate }}
								</div>
								<div
									class="
										col-6 col-md-3 col-lg-2
										text-center text-truncate
										ps-1
										align-self-center
										pe-0
									"
								>
									{{ item.EndTime | momentDate }}
								</div>
								<div class="col-12 col-md-3 col-lg-2 text-end pe-0">
									<button
										class="btn btn-danger"
										:disabled="targetData.Permissions.length <= 1"
										@click="setRemovePermission(item)"
									>
										<i class="fa-solid fa-trash"></i> 移除
									</button>
								</div>
							</div></ContentListItem
						>
					</div>
					<div class="col-12 mt-3 text-end">
						<button class="btn btn-primary" @click="showModalPermission">
							<i class="fa-solid fa-circle-plus"></i> 加入權限
						</button>
					</div>
				</div>
			</ContentSecion>
			<ContentSecion>
				<div class="row">
					<div class="col-12 text-center">
						<button class="btn btn-lg btn-danger" @click="getUserAccount">
							<i class="fa-solid fa-arrow-rotate-right"></i> 重讀
						</button>
						<button class="btn btn-lg btn-primary ms-2" @click="setUserAccount">
							<i class="fa-solid fa-floppy-disk"></i> 更新使用者
						</button>
					</div>
				</div>
			</ContentSecion>
		</ContentPage>
		<BottomBar>
			<div class="row">
				<div class="col-12">
					<button class="btn btn-light" @click="setBackBtn">
						<i class="fa-solid fa-arrow-left"></i> 返回
					</button>
					<button
						class="d-none d-sm-inline-block btn btn-danger ms-2"
						@click="getUserAccountList"
					>
						<i class="fa-solid fa-arrows-rotate"></i> 重讀列表
					</button>
				</div>
			</div>
		</BottomBar>
		<ModalBox ref="modalPermissionList" width="w6">
			<template v-slot:content>
				<div class="row">
					<div class="col-12">
						<small>選擇權限</small>
						<div class="dropdown">
							<a
								class="btn btn-outline-primary w-50 dropdown-toggle"
								href="#"
								role="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<span v-if="selectPermission == null">請選擇權限</span>
								<span v-if="selectPermission != null">{{
									selectPermission.Name
								}}</span>
							</a>

							<div class="dropdown-menu">
								<div
									v-for="item in permissionFilterList"
									:key="item.UID"
									@click="selectPermission = item"
								>
									<a class="dropdown-item" href="#">{{ item.Name }}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:bar>
				<div class="row">
					<div class="col-6">
						<button class="btn btn-light" @click="hideModalPermission">
							取消
						</button>
					</div>
					<div class="col-6 text-end">
						<button class="btn btn-primary ms-1" @click="setNewPermission">
							加入權限
						</button>
					</div>
				</div>
			</template>
		</ModalBox>
		<ModalBox ref="modalLoading" width="w3" padding="borderpadding">
			<template v-slot:content>
				<div class="row">
					<div class="col-12 text-center">正在處理，請稍後...</div>
					<div class="col-12">
						<LoadSpin></LoadSpin>
					</div>
				</div>
			</template>
		</ModalBox>
	</div>
</template>

<script>
import BottomBar from "@/components/WindowUI/BottomBar.vue";
import MenuList from "@/components/WindowUI/MenuList.vue";
import MenuItem from "@/components/WindowUI/MenuItem.vue";
import ContentPage from "@/components/WindowUI/ContentPage.vue";
import ContentSecion from "@/components/WindowUI/ContentSection.vue";
import ModalBox from "@/components/WindowUI/ModalBox.vue";
import LoadSpin from "@/components/LoadSpin.vue";
import {
	getUserAccountList,
	getUserAccount,
	getUserPermissionList,
	setUserAccount,
} from "@/api/user";
import { showMsg } from "@/utils/msgCtrl";
import ContentListItem from "@/components/WindowUI/ContentListItem.vue";
import moment from "moment";
export default {
	name: "UserAccount",
	props: {},
	data() {
		return {
			windowName: "systemmanager-useraccount",
			targetDataList: [],
			selectItem: null,
			targetData: null,
			contentStatus: "",
			isSelected: false,
			permissionList: [],
			selectPermission: null,
			loadingCount: 0,
		};
	},
	components: {
		BottomBar,
		MenuList,
		MenuItem,
		ContentPage,
		ContentSecion,
		ModalBox,
		LoadSpin,
		ContentListItem,
	},
	computed: {
		permissionFilterList: {
			get() {
				var vm = this;
				let result = [];
				if (vm.targetData == null) return vm.permissionList;
				vm.permissionList.forEach((item) => {
					let isFind = false;
					vm.targetData.Permissions.forEach((target) => {
						if (target.UID == item.UID) isFind = true;
					});
					if (!isFind) result.push(item);
				});
				return result;
			},
		},
	},
	filters: {
		momentDate: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日");
		},
		moment: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日 HH:mm:ss");
		},
		commaFormat: function (value) {
			return value
				.toString()
				.replace(
					/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/,
					function (all, pre, groupOf3Digital) {
						return pre + groupOf3Digital.replace(/\d{3}/g, ",$&");
					}
				);
		},
	},
	watch: {
		selectItem(newValue) {
			var vm = this;
			if (newValue == null) {
				if (window.innerWidth < 576) {
					vm.isSelected = false;
					vm.contentStatus = "mobilehide";
					setTimeout(() => {
						vm.targetData = null;
					}, 650);
				} else {
					vm.targetData = null;
				}
				return;
			} else {
				vm.getUserAccount();
			}
		},
		loadingCount(newValue) {
			var vm = this;
			if (newValue > 0) {
				vm.showModalLoading();
			} else {
				vm.hideModalLoading();
			}
		},
	},
	methods: {
		getUserAccountList() {
			var vm = this;
			vm.loadingCount++;
			vm.selectItem = null;
			vm.targetDataList = [];
			setTimeout(() => {
				getUserAccountList()
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.targetDataList = data;
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.setWindowToMenu();
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得使用者列表時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.setWindowToMenu();
									},
								},
							],
						});
					});
			}, 750);
		},
		getUserPermissionList() {
			var vm = this;
			vm.loadingCount++;
			vm.permissionList = [];
			vm.selectPermission = null;
			setTimeout(() => {
				getUserPermissionList()
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.permissionList = data;
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.setWindowToMenu();
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得使用者權限列表時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.setWindowToMenu();
									},
								},
							],
						});
					});
			}, 750);
		},
		getUserAccount() {
			var vm = this;
			vm.loadingCount++;
			vm.targetData = null;
			setTimeout(() => {
				getUserAccount(vm.selectItem)
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.targetData = data;
							vm.isSelected = true;
							vm.contentStatus = "mobileshow";
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.selectItem = null;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得使用者資料時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.selectItem = null;
									},
								},
							],
						});
					});
			}, 750);
		},
		setUserAccount() {
			var vm = this;
			if (vm.targetData == null) {
				showMsg({
					icon: {
						type: "Error",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "更新使用者",
						message: "無法進行更新使用者，必須先選擇一個使用者之後才能繼續",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {},
						},
					],
				});
				return;
			}
			vm.loadingCount++;
			setTimeout(() => {
				setUserAccount(vm.targetData)
					.then((res) => {
						if (res.Success) {
							vm.getUserAccount();
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "更新使用者",
								message:
									"連線到伺服器更新使用者時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
									},
								},
							],
						});
					});
			}, 750);
		},
		setItemClick(input) {
			var vm = this;
			if (vm.checkIsSelectData(input)) return;
			vm.selectItem = JSON.parse(JSON.stringify(input));
		},
		setItemClear() {
			var vm = this;
			if (window.innerWidth < 576) {
				if (vm.selectItem != null) {
					vm.selectItem = null;
				} else {
					vm.setWindowToMenu();
				}
			} else {
				vm.selectItem = null;
			}
		},
		checkIsSelectData(input) {
			var vm = this;
			let result = false;
			if (vm.selectItem != null) {
				if (vm.selectItem.UID == input.UID) result = true;
			}
			return result;
		},
		setBackBtn() {
			var vm = this;
			if (window.innerWidth < 576) {
				if (vm.selectItem != null) {
					vm.selectItem = null;
				} else {
					vm.setWindowToMenu();
				}
			} else {
				vm.setWindowToMenu();
			}
		},
		setWindowToMenu() {
			var vm = this;
			vm.$parent.$parent.$parent.setWindowToMenu(vm);
			setTimeout(() => {
				vm.selectItem = null;
				vm.targetDataList = [];
			}, 500);
		},
		showModalLoading() {
			var vm = this;
			vm.$refs.modalLoading.showModal();
		},
		hideModalLoading() {
			var vm = this;
			vm.$refs.modalLoading.hideModal();
		},
		showModalPermission() {
			var vm = this;
			vm.selectPermission = null;
			vm.$refs.modalPermissionList.showModal();
		},
		hideModalPermission() {
			var vm = this;
			vm.$refs.modalPermissionList.hideModal();
		},
		setRemovePermission(item) {
			var vm = this;
			if (vm.targetData == null) {
				showMsg({
					icon: {
						type: "Alert",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "移除權限失敗",
						message: "必須選擇要設定的使用者之後才能移除權限",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {},
						},
					],
				});
				return;
			}
			showMsg({
				icon: {
					type: "Custom",
					custom: {
						icon: "fa-solid fa-trash",
						color: "text-danger",
					},
				},
				msg: {
					title: "移除權限",
					message:
						"確定要從使用者 " +
						vm.targetData.UserName +
						" 的權限列表中移除權限 " +
						item.Name +
						" 嗎？",
				},
				btn: [
					{
						text: "確定移除",
						type: "btn-danger",
						method: function () {
							vm.targetData.Permissions.splice(
								vm.targetData.Permissions.indexOf(item),
								1
							);
						},
					},
					{
						text: "取消",
						type: "btn-light",
						method: function () {},
					},
				],
			});
		},
		setNewPermission() {
			var vm = this;
			if (vm.targetData == null) {
				showMsg({
					icon: {
						type: "Alert",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "加入權限失敗",
						message: "必須選擇要設定的使用者之後才能加入新權限",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {
								vm.hideModalPermission();
							},
						},
					],
				});
				return;
			}
			if (vm.selectPermission == null) {
				showMsg({
					icon: {
						type: "Alert",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "加入權限失敗",
						message: "請選擇要加入到此使用者的新權限",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {},
						},
					],
				});
			}

			vm.targetData.Permissions.push({
				UID: vm.selectPermission.UID,
				Name: vm.selectPermission.Name,
				StartTime: "1989/08/07 11:00:00",
				EndTime: null,
			});

			vm.hideModalPermission();
		},
		testModal() {
			var vm = this;
			vm.showModalLoading();
			setTimeout(() => {
				vm.hideModalLoading();
			}, 5000);
		},
		setDataInit() {
			var vm = this;
			setTimeout(() => {
				vm.getUserAccountList();
				vm.getUserPermissionList();
			}, 750);
		},
	},
	mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
