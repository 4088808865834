<template>
	<div class="sectionwrapper">
		<div
			class="section"
			:class="secionStatus"
			@mouseover="mouseMoveIn"
			@mouseout="mouseMoveOut"
		>
			<div class="section-border" :class="secionBorderStatus"></div>
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "ContentSection",
	props: [],
	data() {
		return {
			secionStatus: "",
			secionBorderStatus: "",
		};
	},
	methods: {
		mouseMoveIn() {
			var vm = this;
			vm.secionStatus = "movein";
			vm.secionBorderStatus = "movein";
		},
		mouseMoveOut() {
			var vm = this;
			vm.secionStatus = "moveout";
			vm.secionBorderStatus = "moveout";
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.sectionwrapper {
	position: relative;
	margin-bottom: 1rem;
}
.section {
	position: relative;
	margin-left: 1rem;
	margin-right: 1rem;
	border-radius: 12px;
	padding: 1rem;
	background: rgba(255, 255, 255, 0.45);
	box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05);
	transition: 0.5s;
	transform: scale(1, 1);
}
.section.movein {
	animation: section-movein 0.5s ease-in-out;
	transform: scale(1.01, 1.01);
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
@keyframes section-movein {
	0% {
		transform: scale(1, 1);
	}
	100% {
		transform: scale(1.01, 1.01);
	}
}
.section.moveout {
	animation: section-moveout 0.75s ease-in-out;
	transform: scale(1, 1);
}
@keyframes section-moveout {
	0% {
		transform: scale(1.01, 1.01);
	}
	100% {
		transform: scale(1, 1);
	}
}
.section-border {
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
	border: 1px solid rgba(100, 100, 100, 0.25);
	border-radius: 12px;
	opacity: 0;
	pointer-events: none;
}
</style>
