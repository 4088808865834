<template>
	<div class="barwrapper">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: "TopBar",
	props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.barwrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 8px 12px;
	border-bottom: 1px solid rgba(100, 100, 100, 0.25);
	background: rgba(255, 255, 255, 0.25);
	backdrop-filter: blur(24px) saturate(1.5);
	border-top-right-radius: 16px;
	border-top-left-radius: 16px;
	z-index: 40;
}
</style>
