import store from "@/store";
import { createGUID } from "@/utils/createGUID";

export function showMsg(obj) {
	obj.uid = createGUID();
	obj.status = "";
	store.state.msgList.push(obj);
}

export function removeMsg(alert) {
	store.state.msgList.splice(store.state.msgList.indexOf(alert), 1);
}

export function removeAllMsg() {
	store.state.msgList = [];
}

/*
{
    icon:{
        type:'' //OK,Error,Alert,Quest,Custom
        ,
        custom:{
            icon:''
            ,color:''
        }
    },
    msg:{
        title:''
        ,message:''
    },
    btn:[
        {
            text:''
            ,type:''
            ,method:function(){}
        }
    ]
}
*/
