import client from "@/api/client";

export function getSystemLogDateList(logName) {
	return client({
		url: "api/Log/GetSystemLogDateList?LogName=" + logName,
		method: "post",
	});
}

export function getSystemLogItemList(data, logName) {
	return client({
		url: "api/Log/GetSystemLogItemList?LogName=" + logName,
		method: "post",
		data,
	});
}

export function getSystemLogDetail(data, logName) {
	return client({
		url: "api/Log/GetSystemLogDetail?LogName=" + logName,
		method: "post",
		data,
	});
}
