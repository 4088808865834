var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menuitemframewrapper",class:[_vm.sizeset]},[_c('div',{staticClass:"menuitemframe"},[_c('div',{ref:"itemmain",staticClass:"menuitemwrapper",class:[_vm.itemStatus],style:({
				opacity: _vm.itemOpacity,
				transform:
					'scale(' +
					_vm.itemZoom +
					', ' +
					_vm.itemZoom +
					') translate(' +
					_vm.itemMoveX +
					'px,' +
					_vm.itemMoveY +
					'px)',
				pointerEvents: _vm.itemPointEvent,
				transition: _vm.itemTrans + 's',
				transitionTimingFunction: _vm.itemTiming,
			}),on:{"mouseover":_vm.setMouseOver,"mouseout":_vm.setMouseOut,"click":_vm.setMouseClick}},[_c('div',{staticClass:"menuitemborder"}),_c('div',{staticClass:"contentslot",class:_vm.contentStatus},[_vm._t("content")],2),_c('div',{staticClass:"lightslot",class:_vm.lightStatus},[_vm._t("light")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }