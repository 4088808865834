<template>
	<div class="panelwrapper">
		<div class="panelwrapperbg"></div>
	</div>
</template>
  
<script>
export default {
	name: "NotificationPanel",
	props: {},
	data() {
		return {
			status: "",
		};
	},
	computed: {},
	watch: {},
	methods: {},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.panelwrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;
	justify-content: center;
	align-items: center;
	z-index: 985;
}
.panelwrapperbg {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.25);
	backdrop-filter: blur(24px) saturate(1.5);
	transition: 0.5s;
}
</style>