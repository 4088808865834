<template>
	<div class="itemwrapper" :class="[itemSelfPadding]">
		<div
			class="item"
			:class="[itemMoveStatus, itemSelectStatus]"
			@mouseover="mouseMoveIn"
			@mouseout="mouseMoveOut"
		>
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "ContentListItem",
	props: ["isSelected", "itemSelfPadding"],
	data() {
		return {
			itemMoveStatus: "",
			itemSelectStatus: "",
		};
	},
	watch: {
		isSelected(newValue) {
			var vm = this;
			if (newValue) {
				vm.itemSelectStatus = "selected";
			} else {
				vm.itemSelectStatus = "";
			}
		},
	},
	methods: {
		mouseMoveIn() {
			var vm = this;
			vm.itemMoveStatus = "movein";
		},
		mouseMoveOut() {
			var vm = this;
			vm.itemMoveStatus = "moveout";
			setTimeout(() => {
				if (vm.itemMoveStatus == "moveout") vm.itemMoveStatus = "";
			}, 300);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.itemwrapper {
	position: relative;
	width: 100%;
	min-height: 48px;
	padding: 4px 0 0 0;
}
.itemwrapper.padding {
	padding: 4px 16px 0 16px;
}
.itemwrapper:nth-of-type(1) {
	padding: 8px 0 0 0;
}
.itemwrapper.padding:nth-of-type(1) {
	padding: 8px 16px 0 16px;
}
.item {
	position: relative;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.25);
	border-radius: 12px;
	display: flex;
	align-items: center;
	padding: 8px 12px 8px 12px;
	min-height: 36px;
	transition: 0.5s;
	box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05);
}
.item > .row {
	margin-left: 0 !important;
	margin-right: 0 !important;
	width: 100%;
}
@media (max-width: 575px) {
	.itemwrapper {
		position: relative;
		width: 100%;
		min-height: 48px;
		padding: 4px 0 0 0;
	}
	.itemwrapper:nth-of-type(1) {
		padding: 8px 0 0 0;
	}

	.item {
		padding: 6px 6px 6px 6px;
	}
}
.item::before {
	content: "";
	position: absolute;
	left: 3px;
	top: 9px;
	bottom: 9px;
	border-radius: 5px;
	width: 4px;
	background: rgba(85, 85, 255, 0);
	transition: 0.5s;
}
.item.selected::before {
	content: "";
	position: absolute;
	left: 3px;
	top: 9px;
	bottom: 9px;
	border-radius: 5px;
	width: 4px;
	background: rgba(85, 85, 255, 1);
	transition: 0.5s;
}
.item.movein {
	background: rgba(255, 255, 255, 1);
	animation: item-movein 0.5s ease-in-out;
	box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.25);
	border: 1px solid rgba(85, 85, 255, 0.85);
	box-sizing: border-box;
}
.item.movein::before {
	content: "";
	position: absolute;
	left: 3px;
	top: 9px;
	bottom: 9px;
	border-radius: 5px;
	width: 4px;
	background: rgba(85, 85, 255, 0.85);
}
@keyframes item-movein {
	0% {
		transform: scale(1, 1);
		border: 1px solid rgba(85, 85, 255, 0);
	}
	50% {
		transform: scale(1.02, 1.02);
		border: 1px solid rgba(85, 85, 255, 0.85);
	}
	100% {
		transform: scale(1, 1);
		border: 1px solid rgba(85, 85, 255, 0.85);
	}
}
.item.moveout {
	background: rgba(255, 255, 255, 0.25);
	animation: item-moveout 0.75s ease-in-out;
	color: black;
}
@keyframes item-moveout {
	0% {
		transform: scale(1, 1);
		border: 1px solid rgba(85, 85, 255, 0.85);
	}
	50% {
		transform: scale(0.98, 0.98);
		border: 1px solid rgba(85, 85, 255, 0.85);
	}
	100% {
		transform: scale(1, 1);
		border: 1px solid rgba(85, 85, 255, 0);
	}
}
</style>
