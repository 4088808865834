import client from "@/api/client";

export function getSystemList(data) {
	return client({
		url: "api/System/GetSystemList",
		method: "post",
		data,
	});
}

export function setSystem(data) {
	return client({
		url: "api/System/SetSystem",
		method: "post",
		data,
	});
}
