<template>
	<div class="subwindowwrapper">
		<MenuList width="w3" :isSelected="isSelected" :windowtopbar="true">
			<MenuItem
				v-for="item in targetDataList"
				:key="item.UID"
				:isSelected="checkIsSelectData(item)"
				:targetData="item"
				><div class="row w-100">
					<div class="col-12 text-truncate">
						<i class="fa-solid fa-code-pull-request me-1"></i> {{ item.Name }}
					</div>
				</div>
			</MenuItem>
		</MenuList>
		<ContentPage
			v-if="targetData == null"
			otherclass="hidewhenmobile"
			:windowtopbar="true"
			width="w3"
		>
			<div class="emptywrapper">
				<div class="row">
					<div class="col-12 text-center">
						<i class="fa-solid fa-code-pull-request fa-10x"></i>
					</div>
					<div class="col-12 mt-3 text-center">
						<h4><strong>請選擇任務設定檔</strong></h4>
					</div>
					<div class="col-12 text-center">
						請從選單選擇要管理的任務設定檔資料
					</div>
				</div>
			</div>
		</ContentPage>
		<ContentPage
			v-if="targetData != null"
			:otherclass="contentStatus"
			:windowtopbar="true"
			width="w3"
		>
			<div class="emptywrapper">
				<div class="row">
					<div class="col-12 text-center">
						<i class="fa-solid fa-code-pull-request fa-6x"></i>
					</div>
					<div class="col-12 mt-3 text-center">
						<h4>
							<strong>{{ targetData.Name }}</strong>
						</h4>
					</div>
					<div class="col-12 text-center">
						{{ targetData.Key }}
					</div>
					<div class="col-12 text-center mt-1">
						此任務包含 {{ targetData.WorkerList.length }} 個 Worker
					</div>
					<div class="col-12 text-center mt-2">
						<small>建立於 {{ targetData.CreateTime | moment }}</small>
						<br />
						<small>更新於 {{ targetData.UpdateTime | moment }}</small>
					</div>
					<div class="col-12 text-center mt-3">
						<button class="btn btn-primary btn-lg" @click="createQuest">
							<i class="fa-solid fa-play"></i> 執行此任務
						</button>
					</div>
				</div>
			</div>
		</ContentPage>
		<BottomBar>
			<div class="row">
				<div class="col-12">
					<button class="btn btn-light" @click="setBackBtn">
						<i class="fa-solid fa-arrow-left"></i> 返回
					</button>
					<button
						class="d-none d-sm-inline-block btn btn-danger ms-2"
						@click="getQuestProfileList"
					>
						<i class="fa-solid fa-arrows-rotate"></i> 重讀列表
					</button>
				</div>
			</div>
		</BottomBar>
		<ModalBox ref="modalLoading" width="w3" padding="borderpadding">
			<template v-slot:content>
				<div class="row">
					<div class="col-12 text-center">正在處理，請稍後...</div>
					<div class="col-12">
						<LoadSpin></LoadSpin>
					</div>
				</div>
			</template>
		</ModalBox>
	</div>
</template>

<script>
import BottomBar from "@/components/WindowUI/BottomBar.vue";
import MenuList from "@/components/WindowUI/MenuList.vue";
import MenuItem from "@/components/WindowUI/MenuItem.vue";
import ContentPage from "@/components/WindowUI/ContentPage.vue";
import ModalBox from "@/components/WindowUI/ModalBox.vue";
import LoadSpin from "@/components/LoadSpin.vue";
import { getQuestProfileList, getQuestProfile, createQuest } from "@/api/quest";
import { showMsg } from "@/utils/msgCtrl";
import moment from "moment";
export default {
	name: "QuestManager-QuestProfile",
	props: {},
	data() {
		return {
			targetDataList: [],
			selectItem: null,
			targetData: null,
			contentStatus: "",
			isSelected: false,
			loadingCount: 0,
		};
	},
	components: {
		BottomBar,
		MenuList,
		MenuItem,
		ContentPage,
		ModalBox,
		LoadSpin,
	},
	computed: {},
	filters: {
		momentDate: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日");
		},
		moment: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日 HH:mm:ss");
		},
		commaFormat: function (value) {
			return value
				.toString()
				.replace(
					/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/,
					function (all, pre, groupOf3Digital) {
						return pre + groupOf3Digital.replace(/\d{3}/g, ",$&");
					}
				);
		},
	},
	watch: {
		selectItem(newValue) {
			var vm = this;
			if (newValue == null) {
				if (window.innerWidth < 576) {
					vm.isSelected = false;
					vm.contentStatus = "mobilehide";
					setTimeout(() => {
						vm.targetData = null;
					}, 650);
				} else {
					vm.targetData = null;
				}
				return;
			} else {
				vm.getQuestProfile();
			}
		},
		loadingCount(newValue) {
			var vm = this;
			if (newValue > 0) {
				vm.showModalLoading();
			} else {
				vm.hideModalLoading();
			}
		},
	},
	methods: {
		getQuestProfileList() {
			var vm = this;
			vm.loadingCount++;
			vm.selectItem = null;
			vm.targetDataList = [];
			setTimeout(() => {
				getQuestProfileList()
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.targetDataList = data;
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.setWindowToMenu();
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得任務設定檔列表時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.setWindowToMenu();
									},
								},
							],
						});
					});
			}, 750);
		},
		getQuestProfile() {
			var vm = this;
			vm.loadingCount++;
			vm.targetData = null;
			setTimeout(() => {
				getQuestProfile(vm.selectItem)
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.targetData = data;
							vm.isSelected = true;
							vm.contentStatus = "mobileshow";
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.selectItem = null;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得任務設定檔資料時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.selectItem = null;
									},
								},
							],
						});
					});
			}, 750);
		},
		createQuest() {
			var vm = this;
			if (vm.targetData == null) {
				showMsg({
					icon: {
						type: "Error",
						custom: {
							icon: "",
							color: "",
						},
					},
					msg: {
						title: "建立新任務",
						message: "無法建立新任務，必須先選擇一個任務設定檔之後才能繼續",
					},
					btn: [
						{
							text: "確定",
							type: "btn-primary",
							method: function () {},
						},
					],
				});
				return;
			}
			vm.loadingCount++;
			setTimeout(() => {
				createQuest(vm.selectItem)
					.then((res) => {
						if (res.Success) {
							showMsg({
								icon: {
									type: "OK",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {},
									},
								],
							});
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "建立新任務",
								message:
									"連線到伺服器建立新任務時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
									},
								},
							],
						});
					});
			}, 750);
		},
		setItemClick(input) {
			var vm = this;
			if (vm.checkIsSelectData(input)) return;
			vm.selectItem = JSON.parse(JSON.stringify(input));
		},
		setItemClear() {
			var vm = this;
			if (window.innerWidth < 576) {
				if (vm.selectItem != null) {
					vm.selectItem = null;
				} else {
					vm.setWindowToMenu();
				}
			} else {
				vm.selectItem = null;
			}
		},
		checkIsSelectData(input) {
			var vm = this;
			let result = false;
			if (vm.selectItem != null) {
				if (vm.selectItem.UID == input.UID) result = true;
			}
			return result;
		},
		setBackBtn() {
			var vm = this;
			if (window.innerWidth < 576) {
				if (vm.selectItem != null) {
					vm.selectItem = null;
				} else {
					vm.setWindowToMenu();
				}
			} else {
				vm.setWindowToMenu();
			}
		},
		setWindowToMenu() {
			var vm = this;
			vm.$parent.setWindowToMenu(vm);
		},
		showModalLoading() {
			var vm = this;
			vm.$refs.modalLoading.showModal();
		},
		hideModalLoading() {
			var vm = this;
			vm.$refs.modalLoading.hideModal();
		},
		setDataInit() {
			var vm = this;
			setTimeout(() => {
				vm.getQuestProfileList();
			}, 750);
		},
		setWindowClear() {
			var vm = this;
			vm.selectItem = null;
			vm.targetDataList = [];
		},
	},
	mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
