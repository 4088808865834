<template>
	<div class="windowwrapper">
		<MenuList width="w2" :isSelected="isSelected" :windowtopbar="true">
			<MenuItem
				v-for="item in targetDataList"
				:key="item.TableName"
				:isSelected="checkIsSelectData(item)"
				:targetData="item"
				><div class="row w-100">
					<div class="col-12 text-truncate">
						<i class="fa-solid fa-calendar"></i> {{ item.Date | momentDate }}
					</div>
				</div>
			</MenuItem>
		</MenuList>
		<ContentPage
			v-if="targetData == null"
			otherclass="hidewhenmobile"
			:windowtopbar="true"
			width="w2"
		>
			<div class="emptywrapper">
				<div class="row">
					<div class="col-12 text-center">
						<i class="fa-solid fa-book fa-10x"></i>
					</div>
					<div class="col-12 mt-3 text-center">
						<h4><strong>請選擇日誌日期</strong></h4>
					</div>
					<div class="col-12 text-center">請從選單選擇查看日誌的日期</div>
				</div>
			</div>
		</ContentPage>
		<ContentPage
			v-if="targetData != null"
			:otherclass="contentStatus"
			:topbar="true"
			:bottombar="true"
			:windowtopbar="true"
			width="w2"
		>
			<template v-slot:topbar>
				<div class="row">
					<div class="d-none d-md-block col-md-3 col-xl-4"></div>
					<div class="col-3 col-md-2 col-xl-2 ps-1 pe-0">
						<div class="dropdown">
							<a
								class="btn btn-outline-light btn-topbar w-100 dropdown-toggle"
								href="#"
								role="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<span v-if="filterLevel == null">全部</span>
								<span v-if="filterLevel != null">{{ filterLevel.Name }}</span>
							</a>

							<div class="dropdown-menu">
								<div
									v-for="item in levelList"
									:key="item.ID"
									@click="filterLevel = item"
								>
									<a class="dropdown-item" href="#">{{ item.Name }}</a>
								</div>
							</div>
						</div>
					</div>
					<div class="col-5 col-md-4 col-xl-4 ps-1 pe-0">
						<div class="dropdown">
							<a
								class="btn btn-outline-light btn-topbar w-100 dropdown-toggle text-truncate"
								href="#"
								role="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<span v-if="filterSource == null">全部</span>
								<span class="" v-if="filterSource != null">{{
									filterSource.SourceList[filterSource.SourceList.length - 1]
								}}</span>
							</a>

							<div class="dropdown-menu">
								<div
									v-for="item in filterSourceList"
									:key="item.ID"
									@click="filterSource = item"
								>
									<a class="dropdown-item" href="#">{{ item.Name }}</a>
								</div>
							</div>
						</div>
					</div>
					<div class="col-4 col-md-3 col-xl-2 px-1">
						<div class="input-group">
							<span class="input-group-text" id="systemlog-search"
								><i class="fa-solid fa-magnifying-glass"></i
							></span>
							<input
								type="text"
								class="form-control"
								v-model="searchKey"
								aria-describedby="systemlog-search"
							/>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:bottombar>
				<div class="row">
					<div class="col-4 col-md-2 ps-1 pe-0 align-self-center">
						<div class="dropdown dropup">
							<a
								class="btn btn-outline-light btn-topbar w-100 dropdown-toggle"
								href="#"
								role="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<span>每頁 {{ pageCount }} 筆</span>
							</a>

							<div class="dropdown-menu">
								<div @click="pageCount = 10">
									<a class="dropdown-item" href="#">10 筆</a>
								</div>
								<div @click="pageCount = 20">
									<a class="dropdown-item" href="#">20 筆</a>
								</div>
								<div @click="pageCount = 30">
									<a class="dropdown-item" href="#">30 筆</a>
								</div>
								<div @click="pageCount = 50">
									<a class="dropdown-item" href="#">50 筆</a>
								</div>
							</div>
						</div>
					</div>
					<div class="d-none d-md-block col-2 px-0"></div>
					<div class="col-8 col-md-4 text-center ps-1 pe-0">
						<div class="dropdown dropup">
							<a
								class="btn btn-outline-light btn-topbar w-100 dropdown-toggle"
								href="#"
								role="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<span>第 {{ pageIndex + 1 }} 頁 (共 {{ pageTotal }} 頁)</span>
							</a>

							<div class="dropdown-menu">
								<div
									v-for="item in pageTotal"
									:key="item"
									@click="pageIndex = item - 1"
								>
									<a class="dropdown-item" href="#">第 {{ item }} 頁</a>
								</div>
							</div>
						</div>
					</div>
					<div
						class="d-none d-md-block col-4 col-md-4 ps-1 pe-0 text-end align-self-center"
					>
						篩選 {{ logListFilterResult.length }} 筆 (共
						{{ targetData.length }} 筆)
					</div>
				</div>
			</template>
			<ContentListItem
				v-for="item in logListShow"
				itemSelfPadding="padding"
				:key="item.ID"
				><div class="row">
					<div class="col-12">
						<div class="row">
							<div
								class="col-12 col-md-6 smallfont-10px text-nowrap"
								v-if="item.SourceList != null"
							>
								<span
									v-for="(source, i) in item.SourceList"
									:key="i"
									class="badge folder"
									:class="[i > 0 ? 'arrow' : '', getLevelToBadge(item)]"
									>{{ source }}</span
								>
							</div>
							<div
								class="col-12 col-md-6 text-black-50 text-md-end smallfont-10px"
							>
								{{ item.LogTime | moment }}
							</div>
						</div>
						<div class="row mt-1">
							<div class="col-12" style="word-break: break-all">
								{{ item.Message }}
							</div>
						</div>
						<hr class="mt-1 mb-1" />
						<div class="row">
							<div class="col-12 text-end">
								<button class="btn btn-light" @click="getSystemLogDetail(item)">
									<i class="fa-solid fa-table-list"></i> 顯示詳情
								</button>
							</div>
						</div>
					</div>
				</div>
			</ContentListItem>
		</ContentPage>
		<TopBar>
			<div class="row">
				<div class="col-12 text-center">
					<div class="btn-group" role="group">
						<button
							class="btn btn-outline-primary"
							v-for="item in logNameList"
							:class="{ active: logName == item.ID }"
							@click="logName = item.ID"
							:key="item.ID"
						>
							{{ item.Name }}
						</button>
					</div>
				</div>
			</div>
		</TopBar>
		<BottomBar>
			<div class="row">
				<div class="col-8">
					<button class="btn btn-light" @click="setBackBtn">
						<i class="fa-solid fa-arrow-left"></i> 返回
					</button>
					<button
						class="d-none d-sm-inline-block btn btn-danger ms-2"
						@click="getSystemLogDateList"
					>
						<i class="fa-solid fa-arrows-rotate"></i> 重讀列表
					</button>
				</div>
				<div class="col-4 text-end">
					<button class="btn btn-light" @click="getSystemLogItemList">
						<i class="fa-solid fa-arrows-rotate"></i> 重新整理
					</button>
				</div>
			</div>
		</BottomBar>
		<ModalBox ref="modalSystemLogDetail" width="w9" scroll="usescroll">
			<template v-slot:content>
				<div class="row" v-if="logDetail != null">
					<div class="col-12">
						<div class="row">
							<div class="col-12">
								<span class="badge me-1" :class="[getLevelToBadge(logDetail)]">
									{{ logDetail.ID }}
								</span>
								<span class="badge me-1" :class="[getLevelToBadge(logDetail)]">
									{{ logDetail.Level }}
								</span>
								<span class="badge me-1" :class="[getLevelToBadge(logDetail)]">
									{{ logDetail.LogTime | moment }}
								</span>
							</div>
						</div>
						<hr class="mt-1 mb-1" />
						<div class="row">
							<template v-if="logDetail.IPAddress != ''">
								<div class="col-12 smallfont-10px">來源位址</div>
								<div class="col-12">
									{{ logDetail.IPAddress }}
								</div>
							</template>
							<div class="col-12 smallfont-10px">紀錄來源</div>
							<div class="col-12">
								{{ logDetail.Source }}
							</div>
						</div>
						<hr class="mt-1 mb-1" />
						<div class="row">
							<div class="col-12 smallfont-10px">紀錄訊息</div>
							<div class="col-12" style="word-break: break-all">
								{{ logDetail.Message }}
							</div>
						</div>
						<template v-if="logDetail.Type != ''">
							<hr class="mt-1 mb-1" />
							<div class="row">
								<div class="col-12 smallfont-10px">錯誤型別</div>
								<div class="col-12" style="word-break: break-all">
									{{ logDetail.Type }}
								</div>
							</div>
						</template>
						<template v-if="logDetail.StackTrace != ''">
							<hr class="mt-1 mb-1" />
							<div class="row">
								<div class="col-12 smallfont-10px">StackTrace</div>
								<div
									class="col-12"
									style="word-break: break-all"
									v-html="logDetail.StackTrace"
								></div>
							</div>
						</template>
						<template v-if="logDetail.Detail != ''">
							<hr class="mt-1 mb-1" />
							<div class="row">
								<div class="col-12 smallfont-10px">詳情資料</div>
								<div
									class="col-12"
									style="word-break: break-all"
									v-html="logDetail.Detail"
								></div>
							</div>
						</template>
					</div>
				</div>
			</template>
			<template v-slot:bar>
				<div class="row">
					<div class="col-12 text-end">
						<button class="btn btn-primary" @click="hideModalSystemLogDetail">
							完成
						</button>
					</div>
				</div>
			</template>
		</ModalBox>
		<ModalBox ref="modalLoading" width="w3" padding="borderpadding">
			<template v-slot:content>
				<div class="row">
					<div class="col-12 text-center">正在處理，請稍後...</div>
					<div class="col-12">
						<LoadSpin></LoadSpin>
					</div>
				</div>
			</template>
		</ModalBox>
	</div>
</template>

<script>
import BottomBar from "@/components/WindowUI/BottomBar.vue";
import TopBar from "@/components/WindowUI/TopBar.vue";
import MenuList from "@/components/WindowUI/MenuList.vue";
import MenuItem from "@/components/WindowUI/MenuItem.vue";
import ContentPage from "@/components/WindowUI/ContentPage.vue";
import ModalBox from "@/components/WindowUI/ModalBox.vue";
import LoadSpin from "@/components/LoadSpin.vue";
import {
	getSystemLogDateList,
	getSystemLogDetail,
	getSystemLogItemList,
} from "@/api/log";
import { showMsg } from "@/utils/msgCtrl";
import ContentListItem from "@/components/WindowUI/ContentListItem.vue";
import moment from "moment";
export default {
	name: "SystemLog",
	props: {},
	data() {
		return {
			windowName: "systemmanager-systemlog",
			logName: "Platform",
			logNameList: [
				{ ID: "Platform", Name: "Platform" },
				{ ID: "Shadow", Name: "Shadow" },
				{ ID: "Utopia", Name: "Utopia" },
				{ ID: "Task", Name: "Task" },
			],
			targetDataList: [],
			selectItem: null,
			targetData: null,
			contentStatus: "",
			isSelected: false,
			loadingCount: 0,
			logDetail: null,
			searchKey: "",
			filterLevel: null,
			filterSource: null,
			levelList: [
				{ ID: "All", Name: "全部" },
				{ ID: "Info", Name: "資訊" },
				{ ID: "Warn", Name: "警告" },
				{ ID: "Error", Name: "錯誤" },
			],
			pageCount: 30,
			pageIndexTemp: 0,
		};
	},
	components: {
		TopBar,
		BottomBar,
		MenuList,
		MenuItem,
		ContentPage,
		ModalBox,
		LoadSpin,
		ContentListItem,
	},
	computed: {
		pageTotal: {
			get() {
				var vm = this;
				let result = 1;
				result = Math.ceil(vm.logListFilterResult.length / vm.pageCount);
				return result;
			},
		},
		pageIndex: {
			get() {
				var vm = this;
				if (vm.pageIndexTemp > vm.pageTotal - 1) vm.pageIndexTemp = 0;
				return vm.pageIndexTemp;
			},
			set(newValue) {
				var vm = this;
				let result = newValue;
				if (result > vm.pageTotal - 1) result = vm.pageTotal - 1;
				if (result < 0) result = 0;
				vm.pageIndexTemp = result;
			},
		},
		filterSourceList: {
			get() {
				var vm = this;
				let result = [];
				let count = 0;
				if (vm.targetData == null) return result;
				vm.targetData.forEach((item) => {
					let Source = item.Source;
					if (result.find((r) => r.Source == Source) == null) {
						let Name = "";
						item.SourceList.forEach((s) => {
							if (Name != "") Name += "→" + s;
							else Name = s;
						});
						result.push({
							id: count++,
							Name: Name,
							Source: item.Source,
							SourceList: item.SourceList,
						});
					}
				});
				result.sort((a, b) => (a.Source > b.Source ? 1 : -1));
				result.unshift({
					id: 0,
					Name: "全部",
					Source: "All",
					SourceList: ["全部"],
				});
				return result;
			},
		},
		logListFilterLevel: {
			get() {
				var vm = this;
				let result = [];
				if (vm.targetData == null) return result;
				vm.targetData.forEach((item) => {
					if (
						vm.filterLevel == null ||
						item.Level == vm.filterLevel.ID ||
						vm.filterLevel.ID == "All"
					)
						result.push(item);
				});
				return result;
			},
		},
		logListFilterSource: {
			get() {
				var vm = this;
				let result = [];
				if (vm.logListFilterLevel == null) return result;
				vm.logListFilterLevel.forEach((item) => {
					if (
						vm.filterSource == null ||
						item.Source == vm.filterSource.Source ||
						vm.filterSource.Source == "All"
					)
						result.push(item);
				});
				return result;
			},
		},
		logListFilterSearchKey: {
			get() {
				var vm = this;
				let result = [];
				vm.logListFilterSource.forEach((item) => {
					let find = false;
					if (vm.searchKey == "") find = true;
					if (item.Host.toUpperCase().indexOf(vm.searchKey.toUpperCase()) >= 0)
						find = true;
					if (
						item.Source.toUpperCase().indexOf(vm.searchKey.toUpperCase()) >= 0
					)
						find = true;
					if (
						item.Message.toUpperCase().indexOf(vm.searchKey.toUpperCase()) >= 0
					)
						find = true;
					if (find) result.push(item);
				});
				return result;
			},
		},
		logListFilterResult: {
			get() {
				var vm = this;
				return vm.logListFilterSearchKey;
			},
		},
		logListShow: {
			get() {
				var vm = this;
				let startIndex = 0;
				let endIndex = vm.pageCount;
				startIndex = vm.pageIndex * vm.pageCount;
				endIndex = startIndex + vm.pageCount;
				return vm.logListFilterResult.slice(startIndex, endIndex);
			},
		},
	},
	filters: {
		momentDate: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日");
		},
		moment: function (value) {
			if (value == "" || value == null) return "--";
			return moment(value).format("YYYY年MM月DD日 HH:mm:ss");
		},
		commaFormat: function (value) {
			return value
				.toString()
				.replace(
					/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/,
					function (all, pre, groupOf3Digital) {
						return pre + groupOf3Digital.replace(/\d{3}/g, ",$&");
					}
				);
		},
	},
	watch: {
		selectItem(newValue) {
			var vm = this;
			if (newValue == null) {
				if (window.innerWidth < 576) {
					vm.isSelected = false;
					vm.contentStatus = "mobilehide";
					setTimeout(() => {
						vm.targetData = null;
					}, 650);
				} else {
					vm.targetData = null;
				}
				return;
			} else {
				vm.getSystemLogItemList();
			}
		},
		loadingCount(newValue) {
			var vm = this;
			if (newValue > 0) {
				vm.showModalLoading();
			} else {
				vm.hideModalLoading();
			}
		},
		logName() {
			var vm = this;
			vm.getSystemLogDateList();
		},
	},
	methods: {
		getSystemLogDateList() {
			var vm = this;
			vm.loadingCount++;
			vm.selectItem = null;
			vm.targetData = null;
			vm.logDetail = null;
			vm.pageIndex = 0;
			vm.filterLevel = null;
			vm.filterSource = null;
			vm.searchKey = "";
			vm.targetDataList = [];
			setTimeout(() => {
				getSystemLogDateList(vm.logName)
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.targetDataList = data;
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得系統紀錄日期列表時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.setWindowToMenu();
									},
								},
							],
						});
					});
			}, 750);
		},
		getSystemLogItemList() {
			var vm = this;
			vm.loadingCount++;
			vm.targetData = null;
			vm.logDetail = null;
			vm.pageIndex = 0;
			vm.filterLevel = null;
			vm.filterSource = null;
			vm.searchKey = "";
			setTimeout(() => {
				getSystemLogItemList(vm.selectItem, vm.logName)
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.targetData = data;
							vm.isSelected = true;
							vm.contentStatus = "mobileshow";
							vm.loadingCount--;
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.selectItem = null;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得指定日期的紀錄資料列表時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.selectItem = null;
									},
								},
							],
						});
					});
			}, 750);
		},
		getSystemLogDetail(input) {
			var vm = this;
			vm.loadingCount++;
			vm.logDetail = null;
			setTimeout(() => {
				getSystemLogDetail(input, vm.logName)
					.then((res) => {
						if (res.Success) {
							let data = res.Data[0];
							vm.logDetail = data;
							vm.loadingCount--;
							vm.showModalSystemLogDetail();
						} else {
							showMsg({
								icon: {
									type: "Error",
									custom: {
										icon: "",
										color: "",
									},
								},
								msg: {
									title: res.Title,
									message: res.Message,
								},
								btn: [
									{
										text: "確定",
										type: "btn-primary",
										method: function () {
											vm.loadingCount--;
											vm.logDetail = null;
										},
									},
								],
							});
						}
					})
					.catch((error) => {
						showMsg({
							icon: {
								type: "Error",
								custom: {
									icon: "",
									color: "",
								},
							},
							msg: {
								title: "取得資料失敗",
								message:
									"連線到伺服器取得紀錄詳細資料時發生錯誤:" +
									JSON.stringify(error, null, "\t"),
							},
							btn: [
								{
									text: "確定",
									type: "btn-primary",
									method: function () {
										vm.loadingCount--;
										vm.logDetail = null;
									},
								},
							],
						});
					});
			}, 750);
		},
		setItemClick(input) {
			var vm = this;
			if (vm.checkIsSelectData(input)) return;
			vm.selectItem = JSON.parse(JSON.stringify(input));
		},
		setItemClear() {
			var vm = this;
			if (window.innerWidth < 576) {
				if (vm.selectItem != null) {
					vm.selectItem = null;
				} else {
					vm.setWindowToMenu();
				}
			} else {
				vm.selectItem = null;
			}
		},
		getLevelToBadge(input) {
			let result = "text-bg-light";
			switch (input.Level) {
				case "Info":
					result = "text-bg-primary";
					break;
				case "Warn":
					result = "text-bg-warning";
					break;
				case "Error":
					result = "text-bg-danger";
					break;
			}
			return result;
		},
		checkIsSelectData(input) {
			var vm = this;
			let result = false;
			if (vm.selectItem != null) {
				if (vm.selectItem.TableName == input.TableName) result = true;
			}
			return result;
		},
		setBackBtn() {
			var vm = this;
			if (window.innerWidth < 576) {
				if (vm.selectItem != null) {
					vm.selectItem = null;
				} else {
					vm.setWindowToMenu();
				}
			} else {
				vm.setWindowToMenu();
			}
		},
		setWindowToMenu() {
			var vm = this;
			vm.$parent.$parent.$parent.setWindowToMenu(vm);
			setTimeout(() => {
				vm.selectItem = null;
				vm.targetDataList = [];
			}, 500);
		},
		showModalLoading() {
			var vm = this;
			vm.$refs.modalLoading.showModal();
		},
		hideModalLoading() {
			var vm = this;
			vm.$refs.modalLoading.hideModal();
		},
		showModalSystemLogDetail() {
			var vm = this;
			vm.$refs.modalSystemLogDetail.showModal();
		},
		hideModalSystemLogDetail() {
			var vm = this;
			vm.$refs.modalSystemLogDetail.hideModal();
			setTimeout(() => {
				vm.logDetail = null;
			}, 500);
		},
		setDataInit() {
			var vm = this;
			setTimeout(() => {
				vm.getSystemLogDateList();
			}, 750);
		},
	},
	mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
