var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progressbarwrapper",style:({
		height: _vm.height + 'px',
	})},[_c('div',{staticClass:"progressbar",style:({
			height: _vm.height / 2 + 'px',
		})},[_c('div',{staticClass:"progressbarborder"}),(_vm.useWaitBar)?_c('div',{staticClass:"progressbarwait"},[_c('div',{staticClass:"progressbarwaitbg"})]):_vm._e(),(!_vm.useWaitBar)?_c('div',{staticClass:"progressbarshow",style:({
				width: _vm.value + '%',
			})},[_c('div',{staticClass:"progressbarshowbg"})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }