<template>
	<div>
		<div class="framewrapper" :class="[frameStatus]">
			<div
				class="windowframe"
				ref="frame"
				:style="{
					opacity: frameOpacity,
					transform: 'scale(' + frameZoom + ', ' + frameZoom + ')',
					pointerEvents: framePointEvent,
					transition: frameTrans + 's',
					transitionTimingFunction: frameTiming,
				}"
			>
				<div class="windowbg"></div>
				<div
					class="windowbase"
					:style="{
						width: baseWidth + 'px',
						height: baseHeight + 'px',
						transition: baseTrans + 's',
						transitionTimingFunction: baseTiming,
					}"
				></div>
				<div class="windowborder"></div>
			</div>
		</div>
		<div
			class="contentwrapper"
			ref="content"
			:class="[contentStatus]"
			:style="{
				opacity: contentOpacity,
				transform: 'scale(' + contentZoom + ', ' + contentZoom + ')',
				pointerEvents: contentPointEvent,
				transition: contentTrans + 's',
				transitionTimingFunction: contentTiming,
			}"
		>
			<slot></slot>
		</div>
	</div>
</template>
  
<script>
import store from "@/store";
export default {
	name: "WindowFrame",
	props: {},
	data() {
		return {
			isBGZoom: true,
			baseWidth: 640,
			baseHeight: 400,
			baseTrans: 0.5,
			baseTiming: "",
			frameStatus: "",
			frameZoom: 0.9,
			framePointEvent: "none",
			frameOpacity: 0,
			frameTrans: 0.5,
			frameTiming: "",
			contentStatus: "",
			contentZoom: 0.9,
			contentPointEvent: "none",
			contentOpacity: 0,
			contentTrans: 0.5,
			contentTiming: "",
			targetEl: null,
		};
	},
	computed: {},
	watch: {},
	methods: {
		setWindowShow() {
			let delay = 0;
			if (this.isBGZoom) delay += 250;
			setTimeout(() => {
				this.frameStatus = "open";
				this.frameZoom = 1.05;
				this.frameOpacity = 1;
				this.frameTrans = 0.25;
				this.frameTiming = "ease-in";
				setTimeout(() => {
					this.frameZoom = 1.0;
					this.frameOpacity = 1;
					this.frameTrans = 0.25;
					this.frameTiming = "ease-out";
				}, 250);
				setTimeout(() => {
					this.setContentShow();
				}, 150);
			}, delay);
			if (this.isBGZoom) {
				store.dispatch("windowFadeIn");
			}
		},
		setWindowShowFromMenu() {
			this.frameStatus = "open";
			this.frameTrans = 0;
			this.frameOpacity = 0;
			this.frameZoom = 1.0;
			this.frameTiming = "ease-in";
			setTimeout(() => {
				if (this.isBGZoom) {
					store.dispatch("windowFadeIn");
				}
			}, 350);
		},
		setWindowShowForMenuAfter() {
			this.frameOpacity = 1;
			this.frameTrans = 0;
			this.frameZoom = 1.0;
		},
		setWindowHiddenFromMenu() {
			this.frameTrans = 0;
			this.frameStatus = "";
			this.frameOpacity = 0;
			this.frameZoom = 0.9;
			this.frameTiming = "ease-in";
		},
		setContentShow(useBounce) {
			if (useBounce == null) useBounce = true;
			if (useBounce) {
				this.contentStatus = "open";
				this.contentZoom = 1.05;
				this.contentOpacity = 1;
				this.contentTrans = 0.25;
				this.contentTiming = "ease-in";
			} else {
				this.contentTrans = 0;
				this.contentZoom = 1;
			}
			setTimeout(
				() => {
					this.contentZoom = 1.0;
					this.contentOpacity = 1;
					this.contentTrans = useBounce ? 0.25 : 0.45;
					this.contentTiming = "ease-out";
				},
				useBounce ? 250 : 10
			);
		},
		setWindowHidden() {
			this.setContentHidden();
			setTimeout(() => {
				this.frameZoom = 1.05;
				this.frameOpacity = 1;
				this.frameTrans = 0.15;
				this.frameTiming = "ease-in";
				setTimeout(() => {
					this.frameZoom = 0.9;
					this.frameOpacity = 0;
					this.frameTrans = 0.15;
					this.frameTiming = "ease-out";
				}, 150);
				if (this.isBGZoom) {
					setTimeout(() => {
						store.dispatch("windowFadeOut");
					}, 150);
				}
			}, 150);
			setTimeout(() => {
				this.contentStatus = "close";
			}, 300);
		},
		setContentHidden(useBounce) {
			if (useBounce == null) useBounce = true;
			if (useBounce) {
				this.contentZoom = 1.05;
				this.contentOpacity = 1;
				this.contentTrans = 0.15;
				this.contentTiming = "ease-in";
			}
			setTimeout(
				() => {
					this.contentZoom = useBounce ? 0.9 : 1;
					this.contentOpacity = 0;
					this.contentTrans = useBounce ? 0.15 : 0.25;
					this.contentTiming = "ease-out";
				},
				useBounce ? 150 : 0
			);
			this.contentStatus = "close";
		},
		setWindowAuto() {
			var vm = this;
			if (vm.targetEl == null) {
				return;
			}
			vm.setWindowSize(vm.targetEl, false, false);
		},
		setWindowSize(input, useAnime, useBounce) {
			this.targetEl = input;
			let targetHeight = input.$el.clientHeight;
			let targetWidth = input.$el.clientWidth;
			if (useBounce == null) useBounce = true;
			this.setWindowHeight(targetHeight, useAnime, useBounce);
			this.setWindowWidth(targetWidth, useAnime, useBounce);
		},
		setWindowSizeByNum(targetWidth, targetHeight, useAnime, useBounce) {
			if (useBounce == null) useBounce = true;
			this.setWindowHeight(targetHeight, useAnime);
			this.setWindowWidth(targetWidth, useAnime);
		},
		setWindowHeight(target, useAnime, useBounce) {
			let nowHeight = this.baseHeight;
			let value = target - nowHeight;
			if (useBounce) {
				let newtarget = target + (value / 5) * 1;
				if (newtarget <= 0) newtarget = (target / 5) * 4;
				if (newtarget == nowHeight) newtarget = target * 1.05;
				this.baseHeight = newtarget;
				if (useAnime) this.baseTrans = 0.35;
				else this.baseTrans = 0;
				this.baseTiming = "ease-in";
			}
			setTimeout(
				() => {
					this.baseHeight = target;
					if (useAnime) this.baseTrans = 0.25;
					else this.baseTrans = 0;
					this.baseTiming = "ease-out";
				},
				useAnime && useBounce ? 350 : 0
			);
		},
		setWindowWidth(target, useAnime, useBounce) {
			let nowWidth = this.baseWidth;
			let value = target - nowWidth;
			if (useBounce) {
				let newtarget = target + (value / 5) * 1;
				if (newtarget <= 0) newtarget = (target / 5) * 4;
				if (newtarget == nowWidth) newtarget = target * 1.05;
				this.baseWidth = newtarget;
				if (useAnime) this.baseTrans = 0.35;
				else this.baseTrans = 0;
				this.baseTiming = "ease-in";
			}
			setTimeout(
				() => {
					this.baseWidth = target;
					if (useAnime) this.baseTrans = 0.25;
					else this.baseTrans = 0;
					this.baseTiming = "ease-out";
				},
				useAnime && useBounce ? 350 : 0
			);
		},
	},
	created() {
		window.addEventListener("resize", this.setWindowAuto);
	},
	destroyed() {
		window.removeEventListener("resize", this.setWindowAuto);
	},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@property --rotate {
	syntax: "<angle>";
	initial-value: 132deg;
	inherits: false;
}
.contentwrapper {
	position: fixed;
	top: 32px;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	opacity: 0;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	z-index: 600;
}
.contentwrapper.open {
	opacity: 1;
}
.contentwrapper.close {
	opacity: 0;
}

.framewrapper {
	position: fixed;
	top: 32px;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	display: none;
	justify-content: center;
	align-items: center;
	pointer-events: none;
}
.framewrapper.open {
	display: flex;
	opacity: 1;
}
.framewrapper.close {
	display: none;
	opacity: 0;
}

.windowframe {
	position: relative;
	pointer-events: all;
	background: rgba(255, 255, 255, 0.25);
	backdrop-filter: blur(24px) saturate(1.5);
	border-radius: 16px;
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.25);
	transition: 0.35s;
}
.windowbg {
	width: 100%;
	height: 100%;
	border-radius: 16px;
	background-image: linear-gradient(
		var(--rotate),
		rgba(255, 255, 255, 0.45),
		rgba(255, 255, 255, 0.25) 55%,
		rgba(255, 255, 255, 0)
	);
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	animation: windowbg-spin1 19.5s linear infinite,
		windowbg-spin2 13.5s linear infinite;
}
@keyframes windowbg-spin1 {
	0% {
		--rotate: 0deg;
	}
	100% {
		--rotate: 360deg;
	}
}

@keyframes windowbg-spin2 {
	0% {
		opacity: 0;
	}
	15% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	65% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.windowborder {
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
	border: 1px solid rgba(100, 100, 100, 0.25);
	border-radius: 16px;
}
.windowbase {
	width: 600px;
	height: 400px;
	transition: 0.5s;
	transition-timing-function: ease-in;
}
</style>